import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
// import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import {
  DevicesFilter,
  DropdownList,
  SiteSpinner,
} from '../../components/common';
import { constants, Utils } from '../../helpers';

import BaseChartConfig from '../analytics/shared/BaseChartConfig';
import '../analytics/CameraAvailability.scss';
import { Section, SingleColumnLayout } from '../analytics/components';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useOrganizations } from '../../store/OrganizationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { useEffect } from 'react';

// itemValue is set in number of days, except
// the one for the Custom Range

const DeviceUptime = ({ custOrgData, devices }) => {
  const [searchParams] = useSearchParams();
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const navigate = useNavigate();
  const orgId = searchParams.get('orgId');
  const frequencyGetter = {
    hourly: Utils.getHourFromEpoch,
    daily: Utils.getDateFromEpoch,
    weekly: Utils.getWeekFromEpoch,
    monthly: Utils.getMonthFromEpoch,
  };
  const [hasData, setHasData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [options, setOptions] = useState({
    ...BaseChartConfig,
    xaxis: {
      categories: ['12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4'],
    },
    yaxis: {
      opposite: true,
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (value) => {
          let retVal =
            parseInt(value) === 0 ? value.toFixed(2) : `${value.toFixed(2)}%`;
          return retVal;
        },
      },
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primary_56'
      ),
    ]
  });
  const [series, setSeries] = useState([]);
  const [pastRange, setPastRange] = useState(-7);
  const [duration, setDuration] = useState('daily');
  // const { setSelectedDeviceFromFillter, getSelectedDeviceFromFillter } =
  //   useCustomerOrgDevices();

  const defaultValue = (
    <div className="d-flex justify-content-around align-content-center no-content">
      {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
    </div>
  );

  const chartContent = (
    <>
      <Chart
        options={options}
        series={series}
        type="line"
        height={200}
        width={'100%'}
      />
    </>
  );

  const topRightLinks = (
    <div className="filter-group camera-uptime">
      <div
          onClick={() => navigate(`/reports.html?orgId=${orgId}`)}
          className="view-all-analytics"
        >
          {constants.ORG_VIEW_ALL_ANALYTICS}
        </div>     
    </div>
  );

  const getDeviceIdsString = (devicList) => {
    const parentDevices = devicList
    let deviceIdsString = '';
    parentDevices?.forEach((device, index) => {
      deviceIdsString += `${device?.deviceId},`;
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };

  const fetchData = useCallback(
    async (newPastRange, newDuration, devicesList, startTime, endTime) => {
      if (devicesList?.length === 0){
         setSeries([]);
         setHasData(false);
         setOptions({
          ...options,
          xaxis: {
            categories: [],
          },
        });
         return;
      }
      else{
        setHasData(true);  
      }
      try {
        setShowLoader(true);
        const baseUrl = `metrics/orgs/${orgId}/trend/device-connection-status`;
        const requestId = uuidv4();
        let date = new Date();
        date.setDate(date.getDate() + newPastRange);
        const deviceIdsList = await getDeviceIdsString(devicesList);
        const fetchResult = await axios.get(baseUrl, {
          params: {
            orgId: orgId,
            startTime: newPastRange === 0 ? startTime : date.getTime(),
            endTime: newPastRange === 0 ? endTime : new Date().getTime(),
            rangeBy: newDuration,
            deviceIds: deviceIdsList,
            requestTime: Date.now(),
            requestId: requestId,
          },
          ...Utils.requestHeader(requestId),
          timeout: 60000,
          credentials: 'include',
          withCredentials: true,
        });

        const xAxis = [];
        const yValues = [];
        let respData,
          timestampGetter,
          averagePercentageOnline,
          totalPercentageOnline,
          totalDevicesOnlinePercentages,
          timeSeriesData,
          timestamp;

        if (fetchResult?.data?.meta?.code === 200) {
          setShowLoader(false);
          respData = fetchResult.data.data?.timeSeries;
          if(respData?.length === 0){
            setHasData(false);
            return;
          }

          if (Array.isArray(respData) && respData?.length > 0) {
            // Get x axis values for past 7 days
            switch (newPastRange) {
              case -1:
                timeSeriesData = respData.slice(-24);
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -2:
                timeSeriesData = respData.slice(-48);
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -365:
                timeSeriesData = respData.slice(-12);
                timestampGetter = frequencyGetter['monthly'];
                break;

              case -730:
                timeSeriesData = respData.slice(-24);
                timestampGetter = frequencyGetter['monthly'];
                break;

              default:
                timeSeriesData = respData.slice(newPastRange);
                timestampGetter = frequencyGetter[newDuration];
            }

            totalDevicesOnlinePercentages = 0;
            timeSeriesData?.sort((a, b) => a.timestamp - b.timestamp);
            timeSeriesData.forEach((time) => {
              averagePercentageOnline = 0;
              totalPercentageOnline = 0;
              timestamp = timestampGetter(time.timestamp);

              xAxis.push(timestamp);

              if (Array.isArray(time.devices) && time.devices.length > 0) {
                time?.devices.forEach((device, index) => {
                  totalPercentageOnline += device.percentageOnline;
                });
                averagePercentageOnline =
                  totalPercentageOnline / time.devices.length;
              }
              yValues.push(averagePercentageOnline);

              totalDevicesOnlinePercentages += averagePercentageOnline;
            });
            // Set the y value of each tick on
            // x axis.
            setSeries([
              {
                name: '',
                data: yValues,
              },
            ]);
            // Update the chart options with new data
            setOptions({
              ...options,
              xaxis: {
                categories: [...xAxis],
              },
            });

            setHasData(true);
          }
          else{
            setSeries([]);
            setHasData(false);
            setOptions({
             ...options,
             xaxis: {
               categories: [],
             },
           });
            return;
          }
        } else {
          setHasData(false);
          setShowLoader(false);
        }
      } catch (err) {
        setHasData(false);
        setShowLoader(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchData(-7, 'daily', devices);
  }, [pastRange, duration, devices]);

  return (
    <Section
      analyticsTitle={constants.ORG_CAMERA_AVABILITY}
      analyticsDescription={constants.ORG_CAMERA_AVABILITY_DETAILS}
      isFromOrgDetail={true}
      topRightLinks={topRightLinks}
    >
      {showLoader ? (
        <div className="no-data-available">
          <SiteSpinner height="50px" width="50px"></SiteSpinner>
        </div>
      ) : (
        <div>
          {hasData && getCustomerOrgPolicies()?.view_camera_availability_metrics ? (
            <SingleColumnLayout isFromOrg={true} chart={chartContent} />
          ) : (
            defaultValue
          )}
        </div>
      )}
    </Section>
  );
};

export default DeviceUptime;
