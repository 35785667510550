import { Col, Row, Container, Dropdown } from 'react-bootstrap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageWrapper from '../../../pages/PageWrapper';
import {
  Header,
  BreadcrumbList,
  PrimaryButton,
  TextBlock,
  SiteModal,
  SiteSpinner,
} from '../../../components/common/';
import {
  AppDefaults,
  constants,
  DeviceTypeEnum,
  LicenseStatuses,
  roles,
  Utils,
} from '../../../helpers/';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountId,
  getAllMqttDataFromResponse,
  getLocationsData,
  setAllMqttDataFromResponse,
  setDeviceInformation,
  setLocationsData,
} from '../../../store/reducers/AccountReducer';
import {
  getIsFetchLicensesDataAgain,
  setOfferrecived,
} from '../../../store/reducers/StreamingReducer';
import AddDevice from '../addDevice/AddDevice';
import '../../../assets/css/loader.scss';
import {
  getMQTTConnection,
  getPlatformInfo,
  setLiveStream,
  setRemoteStream,
} from '../../../store/reducers/StreamingReducer';
import { usePoliciesStore } from '../../../store/policiesStore';
import {
  disconnectWithMQTT,
  mqttSubscribe,
} from '../../../utils/connection/mqttConnection';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import useLicensesStore from '../../../store/LicensesStore';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { IoIosSearch } from 'react-icons/io';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { RiSettings5Fill } from 'react-icons/ri';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import { HiExclamationCircle, HiOutlineVideoCamera } from 'react-icons/hi2';
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import LatestSnapshot from './LatestSnapshot';
import { v4 as uuidv4 } from 'uuid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/Info-circle.svg';
import { RxCross1 } from 'react-icons/rx';
import {
  setNVRDeviceData,
  // setDevicesListOfCurrOrg,
  setManufacturerSKUList,
  getManufacturerSKUList,
} from '../../../store/reducers/NVRDeviceReducer';
import { getCDNInfo } from '../../../store/reducers/StreamingReducer';
import { useCustomerOrgLocations } from '../../../store/CustomerOrgLocationsStore';
import { CiCircleCheck } from 'react-icons/ci';
import { DefaultDevice } from '../../../assets/images';
import {
  getAllDevicesData,
  // getAllMqttDataFromResponse,
  getSelectedOrganization,
  setAllDevicesData,
} from '../../../store/AccountStoreIDB';
import {
  getDevicesListOfCurrOrg,
  setAllScannedDevices,
  setDevicesListOfCurrOrg,
  setNVRScannedDevices,
} from '../../../store/NVRDeviceStoreIDB';
import {
  getCustomerOrgData,
  setCustomerOrgData,
} from '../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../store/indexDB/observer';
import _ from 'lodash';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../store/reducers/OrganizationsReducer';
import { VariableSizeList } from 'react-window';

const DeviceList = () => {
  const orgInfo = useSelector(getOrgInfo)
  const [isDeviceAvailable,setIsDeviceAvailable] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [licensesData, setLicensesData] = useState([]);
  const cdnInfo = useSelector(getCDNInfo);
  // const deviceListData = useSelector(getAllDevicesData);
  const [deviceListData, setDeviceListData] = useState([]);
  const skuList = useSelector(getManufacturerSKUList);
  let [deviceData, setDeviceData] = useState([]);
  const [filteredDeviceData, setFilteredDeviceData] = useState([]);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const locationDetails = useSelector(getLocationsData);
  const [enableAttachAllLicenses, setEnableAttachAllLicenses] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const platformDetails = useSelector(getPlatformInfo);
  const isFetchLicensesDataAgain = useSelector(getIsFetchLicensesDataAgain);
  const { getLicenses, setLicenses } = useLicensesStore();
  const [showLoader, setShowLoader] = useState(false);
  const [showListScrollLoader, setShowListScrollLoader] = useState(false);
  const navigate = useNavigate();
  const [showClaimDeviceModal, setShowClaimDeviceModal] = useState(false);
  const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
  const [deviceIdToBeRemoved, setDeviceIdToBeRemoved] = useState(null);
  const [userMsg, setUserMsg] = useState('');
  const [isDirectToScannedPopup, setIsDirectToScannedPopup] = useState(false);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [discovredDevciesCount, setDiscovredDevciesCount] = useState(0);
  const { getSelectedLocation } = useCustomerOrgLocations();
  // const [allMqttData, setAllMqttData] = useState([]);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const [devicesListOfCurrOrgList, setDevicesListOfCurrOrgList] = useState([]);
  const [claimDeviceId, setClaimDeviceId] = useState(null);
  const [isDiscoverPopupLoading, setIsDiscoverPopupLoading] = useState(null);
  const deviceListRef = useRef();
  const sizeMap = useRef({});
  const filterOptions = [
    { value: 'Online', label: 'Online' },
    { value: 'Offline', label: 'Offline' },
    { value: 'FirmwareUpdate', label: 'Firmware Update' },
  ];
  let [filterList, setFilterList] = useState([]);
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  const { getState } = devicesMQTTStore;
  const mqttStoreState = getState();

  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const [isScrolled, setIsScrolled] = useState(false);
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const setCustomerOrgData = useOrganizations(
  //   (state) => state.setCustomerOrgData
  // );
  const dispatch = useDispatch();

  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const breadList = [
    {
      url: loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER ? '/customers/manage.html' : `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
      title: `${orgInfo?.orgName}`,
      disable: getCustomerOrgPolicies()?.view_cust_org_dashboard ? false : true,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
  ];

  const loadAllDevicesData = useCallback(async () => {
    const allDevices = await getAllDevicesData();
    setDeviceListData(allDevices);
  }, []);

  const loadDevicesListOfCurrOrgList = useCallback(async () => {
    const allDevices = await getDevicesListOfCurrOrg();
    setDevicesListOfCurrOrgList(allDevices || []);
  }, []);

  const debouncedLoadAllDevicesData = useDebouncedCallback(
    loadAllDevicesData,
    1000
  );

  const debouncedLoadDevicesListOfCurrOrgList = useDebouncedCallback(
    loadDevicesListOfCurrOrgList,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'allDevices') {
        await debouncedLoadAllDevicesData();
      }
      // if (data.key === 'allMqttDataFromResponse') {
      //   await loadAllMqttData();
      // }
      if (data.key === 'devicesListOfCurrOrg') {
        await debouncedLoadDevicesListOfCurrOrgList();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadAllDevicesData();
    // loadAllMqttData();
    debouncedLoadDevicesListOfCurrOrgList();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [
    debouncedLoadAllDevicesData,
    // loadAllMqttData,
    debouncedLoadDevicesListOfCurrOrgList,
  ]);

  useEffect(() => {
    const resetNVRScanData = async () => {
      await setNVRScannedDevices([]);
      await setAllScannedDevices([]);
    };
    resetNVRScanData();
  }, []);

  useEffect(() => {
    dispatch(setOfferrecived(false));
    setShowLoader(true);
    localStorage.setItem('modalOpen', 0);
    fetchManufacturerData();
    getLocations();
    fetchData();
    fetchDiscoveredDevicesCount();
    dispatch(setLiveStream(null));
    dispatch(setRemoteStream(null));
    dispatch(setDeviceInformation(null));
    if (mqttConnection) {
      disconnectWithMQTT();
    }
  }, []);

  useEffect(() => {
    fetchLicensesData();
  }, [isFetchLicensesDataAgain]);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(deviceData));
    if (data?.length) {
      addDeviceProperties(data);
    }
  }, [licensesData]);

  useEffect(() => {
    const updateDetails = async (newlyAddedChannel) => {
      const updatedDeviceListData = JSON.parse(JSON.stringify(deviceListData));
      updatedDeviceListData.push(newlyAddedChannel);
      await setAllDevicesData(updatedDeviceListData); // add await

      const updatedDevicesListOfCurrOrg = JSON.parse(
        JSON.stringify(devicesListOfCurrOrgList)
      );
      updatedDevicesListOfCurrOrg.push(newlyAddedChannel);
      setAddedDeviceData(updatedDevicesListOfCurrOrg);
      await setDevicesListOfCurrOrg(updatedDevicesListOfCurrOrg); //add await
    };
    if (
      allMqttData?.msg?.action === 'add' &&
      allMqttData?.msg?.properties?.data?.channels?.length
    ) {
      const parentDeviceId = allMqttData?.msg?.resource
        ?.toString()
        ?.split('/')?.[1];
      let newlyAddedChannel;
      const rawFilteredDeviceData = JSON.parse(
        JSON.stringify(filteredDeviceData)
      );
      rawFilteredDeviceData.forEach(async (fDevice) => {
        if (fDevice.deviceId === parentDeviceId) {
          newlyAddedChannel = JSON.parse(
            JSON.stringify(allMqttData?.msg?.properties?.data?.channels?.[0])
          );
          if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
            const deviceSubscription = {
              topic: Utils.replaceStringValues(
                subscribeTopic[0],
                '${deviceId}',
                `${newlyAddedChannel?.deviceId}`
              ),
              qos: 0,
            };
            mqttSubscribe(deviceSubscription);
          }
          if (newlyAddedChannel?.capability) {
            let response = await fetch(newlyAddedChannel.capability.url);
            if (response.status === 200) {
              let responseJson = await response.json();
              newlyAddedChannel.capDetails = responseJson;
            }
          }
          fDevice.childDevices = [newlyAddedChannel];
        }
      });
      addDeviceProperties(rawFilteredDeviceData);
      updateDetails(newlyAddedChannel);
    }
  }, [allMqttData]);

  const addDeviceProperties = (groupedDeviceData) => {
    groupedDeviceData?.length &&
      groupedDeviceData?.forEach((device) => {
        const avlbleDeviceVersion = device?.properties?.['avlbl-device-ver'];
        const firmwareVersion = device?.properties?.['firmware-version'];
        const checkStatus =
          avlbleDeviceVersion && firmwareVersion
            ? avlbleDeviceVersion?.toLowerCase() !==
              firmwareVersion?.toLowerCase()
            : false;

        device['displayDeviceStatus'] = Utils.getDeviceStatus(
          device.deviceStatus,
          device.connectionStatus
        );
        device['isFirmwareUpgradeAvailable'] =
          device?.properties && device?.properties?.['avlbl-duclo-ver']
            ? device?.properties?.['avlbl-duclo-ver'] !==
              device?.properties?.['edge-app-version']
            : false;
        if (!device['isFirmwareUpgradeAvailable'] && checkStatus) {
          device['isFirmwareUpgradeAvailable'] = checkStatus;
        }
        device['displayLicensesStatus'] = getLicensesStatus(device);
        device['displayArea'] = locationDetails?.map((location) => {
          if (location.locationId === device.locationId) {
            return location?.areas?.map((area) => {
              if (area.areaId === device.areaId) {
                return area.areaName;
              }
            });
          }
        });
        device['displayLocation'] = locationDetails?.map((location) => {
          if (location.locationId === device.locationId) {
            return location.locationName;
          }
        });
        device.childDevices &&
          device.childDevices.forEach((child) => {
            const childAvlbleDeviceVersion =
              child?.properties?.['avlbl-device-ver'];
            const childFirmwareVersion =
              child?.properties?.['firmware-version'];
            const checkStatusChild =
              childAvlbleDeviceVersion &&
              childFirmwareVersion?.toLowerCase() !==
                childFirmwareVersion?.toLowerCase();
            child['displayDeviceStatus'] = Utils.getDeviceStatus(
              child.deviceStatus,
              child.connectionStatus
            );
            child['isFirmwareUpgradeAvailable'] =
              child?.properties && child?.properties?.['avlbl-duclo-ver']
                ? child?.properties?.['avlbl-duclo-ver'] !==
                  child?.properties?.['edge-app-version']
                : false;
            if (!child['isFirmwareUpgradeAvailable'] && checkStatusChild) {
              child['isFirmwareUpgradeAvailable'] = checkStatusChild;
            }
            child['displayLicensesStatus'] = getLicensesStatus(child);
            child['displayArea'] = locationDetails?.map((location) => {
              if (location.locationId === child.locationId) {
                return location?.areas?.map((area) => {
                  if (area.areaId === child.areaId) {
                    return area.areaName;
                  }
                });
              }
            });
            child['displayLocation'] = locationDetails?.map((location) => {
              if (location.locationId === child.locationId) {
                return location.locationName;
              }
            });
          });
      });

    setDeviceData(groupedDeviceData);
    applyfilters(groupedDeviceData);
  };

  const applyLocationFilter = (groupedDeviceData) => {
    let filteredDevices = [];
    const currentSelectedLocation = getSelectedLocation(accountId);
    if (
      currentSelectedLocation &&
      currentSelectedLocation?.locationId &&
      currentSelectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
    ) {
      filteredDevices = groupedDeviceData.filter(
        (device) => device?.locationId === currentSelectedLocation?.locationId
      );
    } else {
      filteredDevices = groupedDeviceData;
    }
    filteredDevices?.length <= 0 ? setIsDeviceAvailable(true) : setIsDeviceAvailable(false)
    setFilteredDeviceData(filteredDevices);
    setShowLoader(false);
  };

  const updateScrollValue = () => {
    setIsScrolled(!isScrolled);
  };
  const debounceOnScroll = _.debounce(updateScrollValue, 1000);
  window.onscroll = function () {
    debounceOnScroll();
  };

  useEffect(() => {
    setIsScrolled(!isScrolled);
    if (deviceData?.length) {
      applyfilters();
    }
  }, [JSON.stringify(filterList), searchText, getSelectedLocation(accountId)]);

  useEffect(() => {
    updateConnectionStatus();
  }, [deviceListData]);

  const updateConnectionStatus = () => {
    const oldDeviceData = JSON.parse(JSON.stringify(deviceData));
    let isUpdated = false;
    oldDeviceData.forEach((deviceD) => {
      const statusDevice = deviceListData.find(
        (deviceL) => deviceL.deviceId === deviceD.deviceId
      );
      if (
        statusDevice &&
        statusDevice.deviceStatus &&
        statusDevice.deviceStatus !== deviceD.deviceStatus
      ) {
        isUpdated = true;
        deviceD['deviceStatus'] = statusDevice.deviceStatus;
        deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
          statusDevice.deviceStatus,
          deviceD.connectionStatus
        );
      }
      if (
        statusDevice &&
        statusDevice.connectionStatus &&
        statusDevice.connectionStatus !== deviceD.connectionStatus
      ) {
        isUpdated = true;
        deviceD['connectionStatus'] = statusDevice.connectionStatus;
        deviceD['displayDeviceStatus'] = Utils.getDeviceStatus(
          deviceD.deviceStatus,
          statusDevice.connectionStatus
        );
      }
      deviceD.childDevices &&
        deviceD.childDevices.forEach((child) => {
          const childStatusDevice = deviceListData.find(
            (deviceL) => deviceL.deviceId === child.deviceId
          );

          if (
            childStatusDevice &&
            childStatusDevice.deviceStatus &&
            childStatusDevice.deviceStatus !== child.deviceStatus
          ) {
            isUpdated = true;
            child['deviceStatus'] = childStatusDevice.deviceStatus;
            child['displayDeviceStatus'] = Utils.getDeviceStatus(
              childStatusDevice.deviceStatus,
              child.connectionStatus
            );
          }
          if (
            childStatusDevice &&
            childStatusDevice.connectionStatus &&
            childStatusDevice.connectionStatus !== child.connectionStatus
          ) {
            isUpdated = true;
            child['connectionStatus'] = childStatusDevice.connectionStatus;
            child['displayDeviceStatus'] = Utils.getDeviceStatus(
              child.deviceStatus,
              childStatusDevice.connectionStatus
            );
          }
        });
    });
    if (isUpdated) {
      setDeviceData(oldDeviceData);
      applyfilters(oldDeviceData);
    }
  };
  const applyfilters = (data) => {
    const dataToBeUpdated = data ? data : deviceData;
    if (!filterList.length && !searchText) {
      applyLocationFilter(dataToBeUpdated);
    } else if (!filterList.length && searchText) {
      applySearchFilters(dataToBeUpdated);
    } else {
      let list = [];
      filterList.forEach((option) => {
        if (option === 'Online' || option === 'Offline') {
          let subList = [];
          subList = dataToBeUpdated.filter(
            (device) => device.displayDeviceStatus === option
          );
          const subDeviceList = removeDuplicateFilterData(list, subList);
          list = [...list, ...subDeviceList];
        }
        if (option === 'FirmwareUpdate') {
          let subList = [];
          subList = dataToBeUpdated.filter(
            (device) =>
              device?.deviceStatus ===
                constants.DEVICES_CLAIMED_DEVICE_STATUS &&
              device.isFirmwareUpgradeAvailable
          );
          const subDeviceList = removeDuplicateFilterData(list, subList);
          list = [...list, ...subDeviceList];
        }
      });
      if (searchText) {
        applySearchFilters(list);
      } else {
        applyLocationFilter(list);
      }
    }
  };

  const applySearchFilters = (dataList) => {
    dataList = JSON.parse(JSON.stringify(dataList));
    const serchedDevices = [];
    dataList.forEach((dataDevice) => {
      if (
        dataDevice?.deviceName
          ?.toUpperCase()
          .includes(searchText.toUpperCase()) ||
        dataDevice?.serialNo?.toUpperCase().includes(searchText.toUpperCase())
      ) {
        serchedDevices.push(dataDevice);
      } else {
        const filteredChild = dataDevice.childDevices.filter(
          (child) =>
            child?.deviceName
              ?.toUpperCase()
              .includes(searchText.toUpperCase()) ||
            child?.serialNo?.toUpperCase().includes(searchText.toUpperCase())
        );
        if (filteredChild.length) {
          dataDevice.childDevices = filteredChild;
          serchedDevices.push(dataDevice);
        }
      }
    });
    applyLocationFilter(serchedDevices);
  };

  const removeDuplicateFilterData = (deviceList, deviceSubList) => {
    const arr =
      deviceSubList?.filter((ds) => {
        return !deviceList?.find((dl) => {
          return dl.deviceId == ds.deviceId;
        });
      }) ?? [];

    return arr;
  };

  const onClickFilter = (option) => {
    let newFilterList = [];
    if (option === 'selectAll') {
      filterOptions.forEach((item) => {
        newFilterList.push(item.value);
      });
    } else if (filterList.includes(option)) {
      newFilterList = filterList.filter((item) => item !== option);
    } else {
      newFilterList = JSON.parse(JSON.stringify(filterList));
      newFilterList.push(option);
    }
    setFilterList(newFilterList);
  };

  const onChangeSearchText = (e) => {
    setSearchText(e?.target?.value);
  };

  const setAddedDeviceData = async (responseData) => {
    let custOrg = await getCustomerOrgData();
    const deviceInfo = custOrg?.find((custOrg) => custOrg?.orgId === orgId);
    let allDevice = { ...deviceInfo, devices: responseData };
    let list = custOrg?.map((org, i) => {
      if (org?.orgId === orgId) {
        return allDevice;
      } else {
        return { ...org };
      }
    });
    let customerOrg = await Promise.all(list);
    await setCustomerOrgData(customerOrg);
  };

  const getCapabiltiesFromDeviceList = async (data) => {
    // let deviceList = [...data];
    // let newDeviceList = deviceList?.map(async (device, index) => {
    //   if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
    //     const deviceSubscription = {
    //       topic: Utils.replaceStringValues(
    //         subscribeTopic[0],
    //         '${deviceId}',
    //         `${device?.deviceId}`
    //       ),
    //       qos: 0,
    //     };
    //     mqttSubscribe(deviceSubscription);
    //   }
    //   let deviceInfo;
    //   if (device?.capability) {
    //     deviceInfo = deviceListData?.find(
    //       (parents) => parents?.deviceId === device?.deviceId
    //     );
    //   }
    //   let isCapabilityCallRequired =
    //     deviceInfo?.capDetails === undefined ||
    //     deviceInfo?.capability?.version !== device?.capability?.version;

    //   if (device?.capability && isCapabilityCallRequired) {
    //     let response = await fetch(device.capability.url);
    //     if (response.status === 200) {
    //       let responseJson = await response.json();
    //       return { ...device, capDetails: responseJson };
    //     } else {
    //       return { ...device };
    //     }
    //   } else if (
    //     device?.capability &&
    //     deviceInfo?.capability?.version === device?.capability?.version &&
    //     deviceInfo.capDetails
    //   ) {
    //     return { ...device, capDetails: deviceInfo?.capDetails };
    //   } else {
    //     return { ...device };
    //   }
    // });
    // let deviceCapability = await Promise.all(newDeviceList);
    const deviceList = [...data];
    const groupedDeviceData = Utils.getGroupedDevices(deviceList);
    // const fillterBySkuModal = groupedDeviceData?.filter(
    //   (device) =>
    //     skuList?.find((skus) => skus?.model === device?.modelNumber)?.vms
    // );
    addDeviceProperties(groupedDeviceData);
    deviceList?.forEach((device) => {
      if (Array.isArray(subscribeTopic) && subscribeTopic.length > 0) {
        const deviceSubscription = {
          topic: Utils.replaceStringValues(
            subscribeTopic[0],
            '${deviceId}',
            `${device?.deviceId}`
          ),
          qos: 0,
        };
        mqttSubscribe(deviceSubscription);
      }
    });
    deviceList?.length <= 0 ? setIsDeviceAvailable(true) : setIsDeviceAvailable(false)
    await setAllDevicesData(deviceList);
    // await setAllDevicesData(JSON.parse(JSON.stringify(deviceCapability)));
  };

  const fetchData = async () => {
    setIsDirectToScannedPopup(false);
    await axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then(async (response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            if (!responseData?.length) {
              setIsDeviceAvailable(true)
              setShowLoader(false);
              return;
            }
            setDeviceList(responseData);
            getCapabiltiesFromDeviceList(responseData);
            const devicePropertyErrorSubscribe = {
              topic: `d/notify/${accountId}/${mqttStoreState?.getSessionId()}`,
              qos: 0,
            };
            mqttSubscribe(devicePropertyErrorSubscribe);
            const updatedData = responseData.filter(
              (device) => device.deviceStatus !== 'DEACTIVATED'
            );
            setAddedDeviceData(updatedData);
            await setDevicesListOfCurrOrg(updatedData);
          } else {
            setShowLoader(false);
            setIsDeviceAvailable(true)
          }
        }
        else{
          setIsDeviceAvailable(true)
        }
      });
  };

  const fetchDiscoveredDevicesCount = async () => {
    await axios
      .get(
        `/device/orgs/${orgId}/devices/discover/count`,
        Utils.requestHeader()
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setDiscovredDevciesCount(responseData.count);
          }
        }
      });
  };
  const getLocations = () => {
    axios
      .get(`partner/orgs/${orgId}/locations`, Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            const data = [...responseData];
            const defaultLocation = data?.filter(
              (location) => location.isDefault === 'true'
            );
            const finalData = data.sort(function (x, y) {
              return x === defaultLocation[0]
                ? -1
                : y === defaultLocation[0]
                ? 1
                : 0;
            });
            dispatch(setLocationsData(finalData));
          }
        }
      });
  };

  const fetchManufacturerData = async () => {
    await axios
      .get('/device/manufacturerSkuBanks', Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData?.meta?.code === 200) {
          const responseData = resultData.data;
          setManufacturerData(responseData);
          dispatch(setManufacturerSKUList(responseData));
        } else {
          setManufacturerData([]);
          dispatch(setManufacturerSKUList([]));
        }
      });
  };

  const fetchLicensesData = async () => {
    let licensesLoadSuccess = await setLicenses(`/user/orgs/${orgId}/licenses`);

    if (licensesLoadSuccess?.status === 'success') {
      setLicensesData(getLicenses());
    }
  };

  const getLicensesStatus = (device) => {
    const deviceLicense = licensesData.filter(
      (license) => license.deviceId === device.deviceId
    )[0];
    if (
      deviceLicense?.expiryDate &&
      Utils.getDateDifferenceInDays(
        deviceLicense?.expiryDate,
        moment().valueOf()
      ) <= 0
    ) {
      return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED;
    } else {
      if (
        deviceLicense?.licenseStatus ===
        LicenseStatuses.ASSIGNED_ACTIVATION_PENDING
      ) {
        return (
          constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
          ' ' +
          moment(deviceLicense?.activeDate).format('MMM Do YYYY')
        );
      } else if (
        deviceLicense?.licenseStatus !== LicenseStatuses.UNASSIGNED &&
        deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense.expiryDate,
          moment().valueOf()
        ) <= deviceLicense?.expiringSoonInDays &&
        Utils.getDateDifferenceInDays(
          deviceLicense.expiryDate,
          moment().valueOf()
        ) >= 0
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON;
      } else if (
        deviceLicense?.licenseStatus === LicenseStatuses.ACTIVE_UNASSIGNED ||
        deviceLicense?.licenseStatus === LicenseStatuses.UNASSIGNED
      ) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE;
      } else if (deviceLicense?.licenseStatus === LicenseStatuses.ACTIVE) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE;
      } else if (deviceLicense?.licenseStatus === LicenseStatuses.UNASSIGNED) {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      } else {
        return constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE;
      }
    }
  };

  const getDeviceStatus = (device) => {
    if (
      device.deviceStatus?.toUpperCase() ===
      constants.DEVICES_DEACTIVATED_DEVICE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />
          {constants.DEVICES_RETURN_DEACTIVATED_STATUS}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-online" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus ===
      constants.DEVICES_RETURN_READY_TO_CLAIM_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_ENTERED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-entered" />{' '}
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_CLAIMING_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-claiming" />
          {device.displayDeviceStatus}
        </div>
      );
    } else if (
      device.displayDeviceStatus === constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />
          {device.displayDeviceStatus}
        </div>
      );
    }
  };

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      try {
        const response = await fetch(deviceData?.capability?.url);
        if (response.status === 200) {
          const responseJson = await response.json();
          return responseJson;
        } else {
          return {};
        }
      } catch (error) {
        return {};
      }
    } else {
      return {};
    }
  };

  const showDeviceDashboard = async (data) => {
    if (!getCustomerOrgPolicies()?.view_device_settings) {
      return;
    }
    let updateProperty = data?.properties;
    let openDeviceDetails = data;
    const capDetails = await getCapabiltiesForDevice(data);
    // const DeviceProperty = deviceList?.find(
    //   (item) => item?.gatewayId === data?.gatewayId
    // )?.properties;
    // updateProperty = { ...updateProperty, ...DeviceProperty };
    openDeviceDetails = {
      ...openDeviceDetails,
      properties: updateProperty,
    };
    localStorage.setItem(
      'localStoreProperty',
      JSON.stringify(openDeviceDetails.properties)
    );
    dispatch(
      setDeviceInformation({ ...openDeviceDetails, capDetails: capDetails })
    );
    data?.deviceId &&
      navigate(
        `/devices/dashboard.html?deviceId=${openDeviceDetails?.deviceId}`
      );
  };

  const showChannelDetails = async (data) => {
    if (!getCustomerOrgPolicies()?.view_device_settings) {
      return;
    }
    const capDetails = await getCapabiltiesForDevice(data);
    localStorage.setItem('localStoreProperty', JSON.stringify(data.properties));
    dispatch(setDeviceInformation({ ...data, capDetails: capDetails }));
    data?.deviceId &&
      navigate(`/devices/channel-details.html?deviceId=${data?.deviceId}`);
  };

  // ToDo: Not Claimed related code
  const onClaimDevices = (device) => {
    setShowClaimDeviceModal(true);
    //dispatch(setPassedDeviceInfoFromDeviceList(device));
  };

  const updateDeviceOnClaim = (claimedDevice) => {
    const rowFilteredDeviceData = JSON.parse(
      JSON.stringify(filteredDeviceData)
    );
    rowFilteredDeviceData?.forEach((parentDevice) => {
      if (parentDevice.deviceId === claimedDevice?.deviceId) {
        parentDevice.apps = {
          ...parentDevice.apps,
          vms: true,
        };
      } else if (parentDevice.deviceId === claimedDevice?.gatewayId) {
        parentDevice.childDevices.forEach((child) => {
          if (child.deviceId === claimedDevice?.deviceId) {
            child.apps = {
              ...child.apps,
              vms: true,
            };
          }
        });
      }
    });
    rowFilteredDeviceData?.length <= 0 ? setIsDeviceAvailable(true) : setIsDeviceAvailable(false)
    setFilteredDeviceData(rowFilteredDeviceData);
    setClaimDeviceId(null);
  };
  const addAppLink = async (device) => {
    // setShowLoader(true);
    setClaimDeviceId(device.deviceId);
    try {
      const reqBody = {
        apps: ['vms'],
      };
      const res = await axios.post(
        `/device/orgs/${orgId}/devices/${device?.deviceId}/claim/app`,
        reqBody,
        Utils.requestHeader()
      );
      if (parseInt(res?.status) === 200) {
        // setShowActiveModal(false);
        updateDeviceOnClaim(device);
        fetchData();
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setClaimDeviceId(null);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
      setShowLoader(false);
      setClaimDeviceId(null);
    }
    // );
  };

  const deleteDeviceCall = async () => {
    try {
      const res = await axios.delete(
        `/device/orgs/${orgInfo?.orgId}/devices/${deviceIdToBeRemoved}/v2`,
        Utils.requestHeader()
      );
      if (
        parseInt(res?.status) === 202 ||
        parseInt(res?.data?.meta?.code) === 200
      ) {
        setShowLoader(false);
        clearRemoveDeviceData();
        fetchData();
      } else {
        setUserMsg(res?.meta?.userMsg || res?.data?.meta?.userMsg);
      }
    } catch (error) {
      // TODO : to print the error will update this later
      Utils.vmsLogger().error(error);
    }
  };

  const onDeleteDevice = (deviceId) => {
    setDeviceIdToBeRemoved(deviceId);
    setShowDeviceRemoveModal(true);
  };

  const clearRemoveDeviceData = () => {
    setShowDeviceRemoveModal(false);
    setDeviceIdToBeRemoved(null);
    setUserMsg('');
  };

  const handleScanNetworkForDeviceClick = async (device) => {
    setIsDiscoverPopupLoading(device?.deviceId);
    try {
      axios
        .get(
          `device/orgs/${orgInfo?.orgId}/devices/${device?.deviceId}/v2`,
          Utils.requestHeader()
        )
        .then(async (response) => {
          setIsDiscoverPopupLoading(null);
          const resultData = response.data;
          if (resultData) {
            const responseMeta = resultData.meta;
            const responseData = resultData.data;
            if (
              responseMeta &&
              (responseMeta.code === 200 || responseMeta.code === '200')
            ) {
              dispatch(setAllMqttDataFromResponse(null));
              setIsDirectToScannedPopup(true);
              dispatch(
                setNVRDeviceData({
                  ...responseData,
                  channelCount: skuList?.find(
                    (item) => item.model === responseData.modelNumber
                  )?.channelCount,
                  childDevices: device.childDevices,
                })
              );
              await setNVRScannedDevices([]);
              await setAllScannedDevices([]);
            } else {
              Utils.vmsLogger().log('Error - ', responseMeta.userMsg);
            }
          }
        });
    } catch (error) {
      setIsDiscoverPopupLoading(null);
    }
  };

  const getDevicesLiscensAttachStatus = (device) => {
    const licenses = getLicenses();
    if (device) {
      const liscensList = licenses.filter(
        (license) => license?.deviceId === device?.deviceId
      );
      let isLiscensActive = false;
      if (liscensList?.length > 0) {
        for (let i = 0; i < liscensList?.length; i++) {
          if (
            liscensList[i]?.licenseStatus === LicenseStatuses.ACTIVE &&
            !liscensList[i]?.trial
          ) {
            isLiscensActive = liscensList[i];
            break;
          }
        }
      }
      if (isLiscensActive) {
        return isLiscensActive?.productDisplayName;
      } else {
        let isFree = false;
        for (let i = 0; i < liscensList?.length; i++) {
          if (
            liscensList[i]?.licenseStatus === LicenseStatuses.ACTIVE &&
            liscensList[i]?.trial
          ) {
            isFree = liscensList[i];
            break;
          }
        }
        if (isFree) {
          return isFree?.productDisplayName;
        } else {
          return constants.DEVICES_LIST_ATTACH_LICENSE;
        }
      }
    } else {
      return constants.DEVICES_LIST_ATTACH_LICENSE;
    }
  };

  const getSize = (index) => {
    if (sizeMap?.current?.[index]) {
      return sizeMap?.current?.[index] + 12;
    } else {
      if (
        filteredDeviceData?.[index]?.childDevices &&
        filteredDeviceData?.[index]?.childDevices.length > 1
      ) {
        return 105;
      } else {
        return 217;
      }
    }
  };
  
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    if (deviceListRef.current) {
      // Remove resetAfterIndex(i) would remain the component's state.
      deviceListRef.current.resetAfterIndex(index);
    }
  }, []);

  const toggleItem = (index) => {
    // Reset size after toggling expansion
    setTimeout(() => {
      const ele = document.getElementById(`div${index}`);
      if (ele) {
        const newSize = ele?.offsetHeight; // Expanded size is based on the number of items expanded in the list
        setSize(index, newSize);
      }
    }, 0);
  };
 
  const ShowOrgSingletoneDevices = ({ index, style, isScrolling }) => {
    // DON'T REMOVE ANY CODE OR COMMENT HERE
    const device = filteredDeviceData?.[index];
    const rowRef = useRef({});

    if (isScrolling) {
      setShowListScrollLoader(true)
    } else {
      setShowListScrollLoader(false)
    }

    useEffect(() => {
      if (rowRef.current) {
        setSize(index, rowRef.current.clientHeight);
      }
      // eslint-disable-next-line
    }, [rowRef]);

    return (filteredDeviceData?.length && !isDeviceAvailable) ? (
      <div style={style} className='device-list-ref'>
        {(device.childDevices && device.childDevices.length > 1) ||
          device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR ? (
          <Accordion.Item
            ref={rowRef}
            id={`div${index}`}
            key={device.deviceId}
            eventKey={index.toString()}
            className="accordion-item"
          >
            <Accordion.Header>
              <div className="header me-2">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div className="image-wrapper">
                    <img
                      src={device.imageURL}
                      onError={(e) => (e.target.src = `${DefaultDevice}`)}
                      alt=""
                      className="device-icon"
                    />
                  </div>
                  {device.deviceName}
                  {device?.deviceStatus ===
                    constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                    device.isFirmwareUpgradeAvailable ? (
                    <div className="firmware-update">
                      <div className="update-text">
                        {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  {device?.deviceStatus ===
                    constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                    skuList?.find((item) => item?.model === device?.modelNumber)
                      ?.vms !== device?.apps?.vms && (
                      <PrimaryButton
                        className="device-btn-list"
                        type="button"
                        width={
                          device.deviceId === claimDeviceId ? '153px' : '128px'
                        }
                        backgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        fontSize="14px"
                        height="44px"
                        lineHeight="16px"
                        onClick={(e) => {
                          e.stopPropagation();
                          addAppLink(device);
                        }}
                      >
                        {constants.CLAIM_DEVICE}
                        {device.deviceId === claimDeviceId ? (
                          <SiteSpinner
                            height={20}
                            width={20}
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                          />
                        ) : null}
                      </PrimaryButton>
                    )}
                  {device?.deviceType?.toUpperCase() === DeviceTypeEnum.NVR && (
                    <PrimaryButton
                      className="device-btn-list"
                      type="button"
                      width="200px"
                      backgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      fontSize="14px"
                      height="44px"
                      lineHeight="16px"
                      disabled={
                        device?.deviceStatus ===
                        constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
                        device?.deviceStatus ===
                        constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS ||
                        device?.connectionStatus ===
                        constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
                        !getCustomerOrgPolicies()?.install_device
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleScanNetworkForDeviceClick(device);
                      }}
                      loader={isDiscoverPopupLoading === device.deviceId}
                    >
                      {constants.SCAN_NETWORK_FOR_DEVICE_BUTTON}
                    </PrimaryButton>
                  )}
                  <RiSettings5Fill
                    size={24}
                    className={
                      getCustomerOrgPolicies()?.view_device_settings
                        ? 'setting-icon'
                        : 'setting-icon-disabled'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      showChannelDetails(device);
                    }}
                  />
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body
              onEntered={() => toggleItem(index)}
              onExited={() => toggleItem(index)}
            >
              {device.childDevices.map((childDevice, childIndex) => (
                <div
                  key={childDevice.deviceId}
                  className={`camera`}
                  role={
                    getCustomerOrgPolicies()?.view_device_settings
                      ? 'button'
                      : ''
                  }
                  onClick={() => {
                    showDeviceDashboard(childDevice);
                  }}
                  id={childDevice.deviceId}
                >
                  <LatestSnapshot
                    key={childDevice.deviceId}
                    deviceElemId={`camera${index}${childIndex}`}
                    orgId={orgInfo?.orgId}
                    cdnInfo={cdnInfo}
                    locationAreaName={childDevice.locationAreaNames}
                    isHighlighted={false}
                    showZoomOptions={false}
                    conStatus={childDevice.connectionStatus || 'offline'}
                    hubId={device.deviceId}
                    isScrolled={isScrolled}
                    displayDeviceStatus={Utils.getDeviceStatus(
                      childDevice?.deviceStatus,
                      childDevice?.connectionStatus
                    )}
                    {...childDevice}
                  />
                  <div className="camera-details">
                    <div className="camera-name d-flex align-items-center">
                      {childDevice.deviceName}
                      {childDevice?.deviceStatus ===
                        constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                        !childDevice?.apps?.vms && (
                          <PrimaryButton
                            className="channel-claimed-btn device-btn-list"
                            type="button"
                            width={
                              device.deviceId === claimDeviceId
                                ? '125px'
                                : '100px'
                            }
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--primary_40')}
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                            fontSize="12px"
                            borderRadius="8px"
                            height="34px"
                            lineHeight="16px"
                            onClick={(e) => {
                              e.stopPropagation();
                              addAppLink(childDevice);
                            }}
                          >
                            {constants.CLAIM_DEVICE}
                            {childDevice?.deviceId === claimDeviceId ? (
                              <SiteSpinner
                                height={20}
                                width={20}
                                backgroundColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--brand_white')}
                              />
                            ) : null}
                          </PrimaryButton>
                        )}
                    </div>
                    <div className="detail-table">
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_LOCATION}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>{childDevice.displayLocation}</Tooltip>
                          }
                        >
                          <div className="table-info width-120">
                            {childDevice.displayLocation}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_AREA}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{childDevice.displayArea}</Tooltip>}
                        >
                          <div className="table-info width-120">
                            {childDevice.displayArea}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_SERIAL_NUMBER}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{childDevice.serialNo}</Tooltip>}
                        >
                          <div className="table-info width-150">
                            {childDevice.serialNo}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_LICENSE}
                        </div>
                        {
                          <div className="attach-license width-180">
                            {getDevicesLiscensAttachStatus(childDevice) !==
                              constants.DEVICES_LIST_ATTACH_LICENSE && (
                                <CiCircleCheck
                                  size={25}
                                  style={{ marginRight: 2 }}
                                  color={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--error_64')}
                                />
                              )}
                            {getDevicesLiscensAttachStatus(childDevice)}
                          </div>
                        }
                      </div>
                      <div className="table-column">
                        <div className="table-header">
                          {constants.DEVICES_LIST_STATUS}
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>{childDevice.displayDeviceStatus}</Tooltip>
                          }
                        >
                          <div className="table-info width-120">
                            {getDeviceStatus(childDevice)}
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ) : (
          <div ref={rowRef} className={`accordion-item`} key={index}>
            <div className="w-100">
              <div className="header">
                <div className="header-section-1">
                  <div className="image-wrapper">
                    <img
                      src={
                        device?.childDevices?.[0]?.imageURL || device?.imageURL
                      }
                      onError={(e) => (e.target.src = `${DefaultDevice}`)}
                      alt=""
                      className="device-icon"
                    />
                  </div>
                  {device?.deviceName}
                  {device?.deviceStatus ===
                    constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                    device?.isFirmwareUpgradeAvailable ? (
                    <div className="firmware-update">
                      <div className="update-text">
                        {constants.DEVICES_FIRMWARE_UPGRADE_AVAILABLE}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="header-section-2">
                  {device?.deviceStatus ===
                    constants.DEVICES_CLAIMED_DEVICE_STATUS &&
                    skuList?.find((item) => item?.model === device?.modelNumber)
                      ?.vms !== device?.apps?.vms && (
                      <PrimaryButton
                        className="device-btn-list"
                        type="button"
                        width={
                          device.deviceId === claimDeviceId ? '153px' : '128px'
                        }
                        backgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        fontSize="14px"
                        height="44px"
                        lineHeight="16px"
                        onClick={() => addAppLink(device)}
                      >
                        {constants.CLAIM_DEVICE}
                        {device.deviceId === claimDeviceId ? (
                          <SiteSpinner
                            height={20}
                            width={20}
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                          />
                        ) : null}
                      </PrimaryButton>
                    )}
                  <RiSettings5Fill
                    size={24}
                    className={
                      getCustomerOrgPolicies()?.view_device_settings
                        ? 'setting-icon'
                        : 'setting-icon-disabled'
                    }
                    onClick={() => {
                      showChannelDetails(device);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`w-100`}
              role={
                getCustomerOrgPolicies()?.view_device_settings ? 'button' : ''
              }
              onClick={() =>
                showDeviceDashboard(device?.childDevices?.[0] || device)
              }
              id={device?.childDevices?.[0]?.deviceId}
            >
              <div className="camera pt-2 pb-2">
                <LatestSnapshot
                  key={device?.childDevices?.[0]?.deviceId || device?.deviceId}
                  deviceElemId={`camera${index}`}
                  orgId={orgInfo?.orgId}
                  cdnInfo={cdnInfo}
                  locationAreaName={
                    device?.childDevices?.[0]?.locationAreaNames ||
                    device?.locationAreaNames
                  }
                  isHighlighted={false}
                  showZoomOptions={false}
                  conStatus={
                    device?.childDevices?.[0]?.connectionStatus ||
                    device?.connectionStatus ||
                    'offline'
                  }
                  hubId={device?.deviceId}
                  isScrolled={isScrolled}
                  displayDeviceStatus={Utils.getDeviceStatus(
                    device?.childDevices?.[0]?.deviceStatus,
                    device?.childDevices?.[0]?.connectionStatus
                  )}
                  {...device?.childDevices?.[0]}
                />
                <div className="camera-details">
                  <div className="detail-table">
                    <div className="table-column">
                      <div className="table-header">
                        {constants.DEVICES_LIST_LOCATION}
                      </div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {device?.childDevices?.[0]?.displayLocation ||
                              device?.displayLocation}
                          </Tooltip>
                        }
                      >
                        <div className="table-info width-120">
                          {device?.childDevices?.[0]?.displayLocation ||
                            device?.displayLocation}
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="table-column">
                      <div className="table-header">
                        {constants.DEVICES_LIST_AREA}
                      </div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {device?.childDevices?.[0]?.displayArea ||
                              device?.displayArea}
                          </Tooltip>
                        }
                      >
                        <div className="table-info width-120">
                          {device?.childDevices?.[0]?.displayArea ||
                            device?.displayArea}
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="table-column">
                      <div className="table-header">
                        {constants.DEVICES_LIST_SERIAL_NUMBER}
                      </div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {device?.childDevices?.[0]?.serialNo ||
                              device?.serialNo}
                          </Tooltip>
                        }
                      >
                        <div className="table-info width-150">
                          {device?.childDevices?.[0]?.serialNo ||
                            device?.serialNo}
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="table-column">
                      <div className="table-header">
                        {constants.DEVICES_LIST_LICENSE}
                      </div>
                      {
                        <div className="attach-license width-180">
                          {getDevicesLiscensAttachStatus(
                            device?.childDevices?.[0] || device
                          ) !== constants.DEVICES_LIST_ATTACH_LICENSE && (
                              <CiCircleCheck
                                size={25}
                                style={{ marginRight: 2 }}
                                color={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--error_64')}
                              />
                            )}
                          {getDevicesLiscensAttachStatus(
                            device?.childDevices?.[0] || device
                          )}
                        </div>
                      }
                    </div>
                    <div className="table-column">
                      <div className="table-header">
                        {constants.DEVICES_LIST_STATUS}
                      </div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {device?.childDevices?.[0]?.displayDeviceStatus ||
                              device?.displayDeviceStatus}
                          </Tooltip>
                        }
                      >
                        <div className="table-info width-120">
                          {getDeviceStatus(device?.childDevices?.[0] || device)}
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ) :  isDeviceAvailable && (
      <div className="no-devices-container">
        <div className="image-wrapper">
          <HiOutlineVideoCamera size={32} />
        </div>
        <div>{constants.DEVICE_NOT_ADDED}</div>
      </div>
    );
  };

  const handleDeviceClaimModel = (value) => {
    setIsDirectToScannedPopup(false);
    setShowClaimDeviceModal(value);
  };

  return (
    <div className="App">
      <Header breadcrumbData={breadList} />

      <div className="App">
        <PageWrapper className="mw-100">
          <div className="page-header mb-3">
            <Container className="container-top-offset mw-100" lg={8}>
              <Col md={6} lg={6} xl={6} xs={12}>
                <BreadcrumbList list={breadList} />
              </Col>
              <Row>
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="page-title text-start"
                >
                  {constants.DEVICES_PAGE_TITLE}
                </Col>

                <Col md={6} lg={6} xl={6} xs={12} className="end-align">
                  <PrimaryButton
                    className={`device-btn-list${
                      !enableAttachAllLicenses && ' d-none'
                    }`}
                    type="button"
                    width="150px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_black')}
                    fontSize="0.875rem"
                    height="44px"
                  >
                    {constants.DEVICES_ATTACH_ALL_LICENSES}
                  </PrimaryButton>
                  {getCustomerOrgPolicies()?.install_device && (
                    <AddDevice
                      reloadData={fetchData}
                      showClaimDeviceModal={showClaimDeviceModal}
                      setShowClaimDeviceModal={handleDeviceClaimModel}
                      isDirectToScannedPopup={isDirectToScannedPopup}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>

          {showLoader ? (
            <div className="spinner-container-device-list">
              <div className="spinner-center-device-list">
                <SiteSpinner className="spinner-size-device-list" />
                <div className="mt-2">{constants.LOADING}</div>
              </div>
            </div>
          ) : (
            <>
              {discovredDevciesCount ? (
                <div className="discovred-devices-container">
                  <div className="d-flex">
                    <HiOutlineInformationCircle size={20} />
                    <div className="deiscovered-device-message">
                      {constants.DISCOVERED_DEVICES_MESSAGE}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="pending-count">
                      {discovredDevciesCount}{' '}
                      {constants.DISCOVERED_DEVICES_PENDING_COUNT}
                    </div>
                    <PrimaryButton
                      className="device-btn-list"
                      type="button"
                      width="132px"
                      backgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      fontSize="12px"
                      height="32px"
                      lineHeight="16px"
                      borderRadius="8px"
                      onClick={() => {
                        navigate(
                          `/devices/discovered-devices.html?orgId=${orgInfo?.orgId}`
                        );
                      }}
                    >
                      {constants.DISCOVERED_DEVICES_SETUP_BUTTON}
                    </PrimaryButton>
                  </div>
                </div>
              ) : null}
              <div className="list-block">
                <Container className="h-100 mw-100">
                  {getCustomerOrgPolicies()?.view_device && (
                    <div className="list-wrapper">
                      <div className="filter-wrapper">
                        <div className="search-container">
                          <IoIosSearch className="search-icon" />
                          <input
                            type={'text'}
                            placeholder={'Search Devices'}
                            className="search-input"
                            onChange={(e) => onChangeSearchText(e)}
                          />
                        </div>
                        <Dropdown
                          className="device-status-selector"
                          autoClose="outside"
                        >
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                            className="device-status-selector-toggle"
                          >
                            <div className="device-status-selected-wrapper">
                              <span className="device-status-selected-name">
                                Filter By
                              </span>
                            </div>
                            <HiOutlineChevronDown size={16} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="device-status-selector-menu">
                            <Dropdown.Item
                              className="device-status-selector-item"
                              onClick={(e) => onClickFilter('selectAll')}
                            >
                              Select All
                            </Dropdown.Item>
                            {filterOptions.map((option) => (
                              <Dropdown.Item
                                key={option.value}
                                className="device-status-selector-item"
                                onClick={(e) => onClickFilter(option.value)}
                              >
                                <input
                                  type="checkbox"
                                  checked={filterList.includes(option.value)}
                                  className="checkbox"
                                />
                                {option.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {!isDeviceAvailable && filteredDeviceData?.length ? (
                        <div>
                          <Accordion className="accordian" alwaysOpen>
                            {
                              <VariableSizeList
                                useIsScrolling
                                height={window.innerHeight - 300}
                                itemCount={filteredDeviceData?.length}
                                itemData={filteredDeviceData}
                                itemSize={getSize}
                                className="react-window-list"
                                ref={deviceListRef}
                              >
                                {ShowOrgSingletoneDevices}
                              </VariableSizeList>
                            }
                          </Accordion>
                        </div>
                      ) : (
                        isDeviceAvailable && (
                          <div className="no-devices-container">
                            <div className="image-wrapper">
                              <HiOutlineVideoCamera size={32} />
                            </div>
                            <div>{constants.DEVICE_NOT_ADDED}</div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {showListScrollLoader ? (
                    <div className="show-variable-list-loader">
                      <SiteSpinner height="50px" width="50px"></SiteSpinner>
                    </div>
                  ) : null}
                </Container>
              </div>
            </>
          )}

          <SiteModal
            modalTitle={
              constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE
            }
            showModal={showDeviceRemoveModal}
            hideModal={clearRemoveDeviceData}
            classes="device-settings-restart-required"
          >
            {userMsg && userMsg.length > 0 && (
              <div className="error-container">
                <div className="info-icon-container">
                  <InfoCircleIcon />
                </div>
                <div className="error-body">
                  <div className="error-content">{userMsg}</div>
                  <div className="cross-icon-container">
                    <RxCross1 onClick={() => setUserMsg('')} />
                  </div>
                </div>
              </div>
            )}
            <TextBlock
              className="mb-3"
              fontWeight="400"
              fontSize="1rem"
              lineHeight="24px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_56')}
            >
              {
                constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT
              }
            </TextBlock>
            {
              <>
                <div className="text-center">
                  <HiExclamationCircle
                    className="mb-4 icon-warning-color"
                    size={96}
                  />
                </div>
                <PrimaryButton
                  className="btn btn-primary-outline mb-4"
                  type="button"
                  width="100%"
                  borderWidth="1.5px"
                  hoverBorderWidth="1.5px"
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  backgroundColor="transparent"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  onClick={clearRemoveDeviceData}
                >
                  {
                    constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
                  }
                </PrimaryButton>
                <PrimaryButton
                  className="btn"
                  type="button"
                  width="100%"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor="transparent"
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  borderWidth="1.5px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_64')}
                  hoverColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                  onClick={deleteDeviceCall}
                >
                  {
                    constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE
                  }
                </PrimaryButton>
              </>
            }
          </SiteModal>
        </PageWrapper>
      </div>
    </div>
  );
};

export default DeviceList;
