import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsRemoteStreamPlay } from '../../../store/reducers/StreamingReducer';
import { Utils } from '../../../helpers';

const ImageFetchLoading = ({
  time,
  imageURL = null,
  cdnValue,
  deviceId,
  selectedEventTime,
}) => {
  const [selectedSnapSrc, setSelectedSnapSrc] = useState('');
  const dispatch = useDispatch();
  const imageRef = useRef();

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(selectedEventTime / 1000));
    if (
      deviceId &&
      newTime &&
      !imageURL &&
      cdnValue?.protocol &&
      cdnValue?.host
    ) {
      const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
      const date = Utils.fetchDateInUnix(newTime);

      fetch(
        `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${newTime}.jpg`,
        {
          credentials: 'include',
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          setSelectedSnapSrc(URL.createObjectURL(blob));
        });
    }
  }, [selectedEventTime, deviceId]);

  return (
    <div id="image_wrapper" className="image-playback">
      {selectedSnapSrc ? (
        <img
          ref={imageRef}
          id="img-snapshot"
          src={selectedSnapSrc}
          alt=""
          onLoad={(event) => {
            event.target.style.display = 'inline-block';
            dispatch(setIsRemoteStreamPlay(true));
          }}
          onError={(event) => (event.target.style.display = 'none')}
        />
      ) : null}
    </div>
  );
};

export default ImageFetchLoading;
