import React, { useEffect, useState } from 'react';
import { TbCircleMinus, TbCirclePlus } from 'react-icons/tb';
import { constants, Utils } from '../../helpers';
import { PrimaryButton, SiteModal, TextBlock } from '../../components/common';
// import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { usePoliciesStore } from '../../store/policiesStore';
import { getAllDevicesData } from '../../store/AccountStoreIDB';

const ManageAreas = ({
  disabledState,
  areasData,
  updateAreaData,
  childFunc,
  removeAreaName,
  setRemoveAreaName,
  showRemoveAreaModal,
  setShowRemoveAreaModal,
  removeAreaIndex,
  setRemoveAreaIndex,
  ...props
}) => {
  const [defaultAreaValue, setDefaultAreaValue] = useState('');
  const [deviceExists, setDeviceExists] = useState(false);
  // const deviceListData = useSelector(getAllDevicesData);
  const [deviceListData, setDeviceListData] = useState([]);
  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDeviceListData(devices);
    };

    fetchDevices();
  }, []);

  React.useEffect(() => {
    childFunc.current = handleCreateDefaultAreaLoc;
  }, [defaultAreaValue, removeAreaName, removeAreaIndex]);

  const manageAreaList = () => {
    if (areasData?.length > 0) {
      let areaHtmlList = areasData?.map((area, index) => {
        return (
          <div key={index}>
            <div
              className="d-flex align-items-start create-area-list mb-2"
              key={index}
            >
              <input
                className="form-control require shadow-none"
                placeholder={constants.ENTER_AREA_NAME_FIELD}
                autoComplete="off"
                type="text"
                name={area?.areaName}
                value={area?.areaName}
                disabled={disabledState && getCustomerOrgPolicies().update_area}
                onChange={(event) => handleChangeArea(event, index)}
              />
              {getCustomerOrgPolicies().delete_area && (
                <TbCircleMinus
                  style={{cursor : area?.isDefault?.toString() === 'true'  ? 'auto' : 'pointer' }}
                  size={20}
                  className={`${
                    area?.isDefault?.toString() === 'true'
                      ? 'remove-default-area-icon'
                      : 'remove-area-icon ml-1'
                  } align-self-center`}
                  onClick={
                    area?.isDefault?.toString() !== 'true'
                      ? (e) =>
                          handleRemoveArea(
                            e,
                            area?.areaName,
                            index,
                            area?.areaId
                          )
                      : undefined
                  }
                />
              )}
            </div>
            {area?.areaName?.length === 0 && (
              <div className="error mb-2">
                {constants.AREA_NAME_REQUIRED_ERROR_MESSAGE}
              </div>
            )}
          </div>
        );
      });
      return areaHtmlList;
    }
  };

  const handleChangeArea = (event, areaIndex) => {
    const updatedAreaText = event?.target?.value;
    let newAreaData = areasData?.filter((area, index) => {
      if (index === areaIndex) {
        area.areaName = updatedAreaText;
      }
      return area;
    });
    updateAreaData(newAreaData);
  };

  //=== Handle input change for updating area
  const handleChangeDefaultArea = (event) => {
    const defaultAreaText = event?.target?.value;
    setDefaultAreaValue(defaultAreaText);
  };

  //=== Handle space input
  const handleSpaces = (event) => {
    if (event.which === 32 && !defaultAreaValue) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  //=== Handle button click for creating area
  const handleCreateDefaultArea = () => {
    if (defaultAreaValue?.length > 0) {
      if (areasData?.length === 0) {
        updateAreaData([
          ...areasData,
          {
            areaName: defaultAreaValue,
            isDefault: areasData?.length > 0 ? false : true,
          },
        ]);
      } else {
        updateAreaData([
          ...areasData,
          {
            areaName: defaultAreaValue,
          },
        ]);
      }
      setDefaultAreaValue('');
    }
  };

  const handleCreateDefaultAreaLoc = () => {
    if (defaultAreaValue?.length > 0) {
      if (areasData?.length === 0) {
        return [
          ...areasData,
          {
            areaName: defaultAreaValue,
            isDefault: areasData?.length > 0 ? false : true,
          },
        ];
      } else if (removeAreaIndex !== null && removeAreaName !== null) {
        let newAreaData = areasData?.filter(
          (area, index) =>
            area.areaName !== removeAreaName && index !== removeAreaIndex
        );
        return newAreaData;
      } else {
        return [
          ...areasData,
          {
            areaName: defaultAreaValue,
          },
        ];
      }
    } else if (areasData?.length > 0) {
      if (removeAreaIndex !== null && removeAreaName !== null) {
        let newAreaData = areasData?.filter(
          (area, index) =>
            area.areaName !== removeAreaName && index !== removeAreaIndex && area?.areaId
        );
        let newAreaValues = areasData?.filter(area => !area?.areaId);
        return [
          ...newAreaData,
          ...newAreaValues
        ];
      } else {
        return [...areasData];
      }
    }
  };

  //=== Handle button click for removing area
  const handleRemoveArea = (e, areaName, areaIndex, areaId = null) => {
    if (areaId) {
      const deviceExists = deviceListData?.filter(
        (device) => device.areaId === areaId
      );
      deviceExists?.length > 0 && setDeviceExists(true);
    }
    setRemoveAreaIndex(areaIndex);
    setRemoveAreaName(areaName);
    setShowRemoveAreaModal(true);
  };


  return (
    <div className="manage-areas-wrap">
      <div className={`${showRemoveAreaModal ? 'd-none' : ''}`}>
        <label className="pt-2 pb-2">
          <strong className="location-modal-heading">
            {constants.NEW_LOCATIONS_AREA_LABEL_TEXT}
          </strong>
          <TextBlock
            className="mb-3"
            fontWeight="400"
            fontSize="0.875rem"
            lineHeight="20px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
          >
            {constants.NEW_LOCATIONS_AREA_LABEL_INFO}
          </TextBlock>
        </label>

        {getCustomerOrgPolicies().view_area && manageAreaList()}

        {getCustomerOrgPolicies().create_area && (
          <div className="d-flex align-items-start create-area-list">
            <input
              className="form-control require shadow-none"
              placeholder={`${
                areasData?.length > 0
                  ? constants.CREATE_NEW_AREA_FIELD
                  : constants.CREATE_DEFAULT_AREA_FIELD
              }`}
              autoComplete="off"
              name="defaultArea"
              type="text"
              value={defaultAreaValue}
              disabled={disabledState}
              onChange={(event) => handleChangeDefaultArea(event)}
              onKeyDown={(event) => handleSpaces(event)}
            />
            {defaultAreaValue.length > 0 && (
              <TbCirclePlus
                size={20}
                className="create-area-icon align-self-center"
                onClick={() => handleCreateDefaultArea()}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageAreas;
