import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';

import { constants } from '../../helpers/';
import './SharedWith.scss';

// See SharedWith.scss for the widths associated with  these classes
const avatarWrapperWidthMap = {
  1: 'one-subscriber',
  2: 'two-subscribers',
  3: 'three-subscribers',
  4: 'large-group-subscribers',
};

const SharedWith = ({ sharedWithId, orgId, subscribers, showLabel = true }) => {
  const [users, setUsers] = useState([]);

  const avatarsWrapperRef = useRef();
  const getCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsers
  );
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );

  let customerOrgUsers = getCustomerOrgUsersData();

  useEffect(() => {
    if (!Array.isArray(customerOrgUsers) || customerOrgUsers.length < 1) {
      getOrgUsers();
    }
    if (Array.isArray(subscribers) && subscribers.length > 0) {
      setUsers([...subscribers]);
    }
  }, []);

  const getOrgUsers = async () => {
    await getCustomerOrgUsers(`partner/orgs/${orgId}/accounts`);
    customerOrgUsers = getCustomerOrgUsersData();
  };

  const getUserDetailsByAccountId = (accountId) => {
    let userDetails;

    if (!accountId) return;

    userDetails = customerOrgUsers.find(
      (orgUser) => orgUser.accountId === accountId
    );

    return userDetails;
  };

  const getCustomClassByNumOfSubscribers = (numOfSubscribers) => {
    let customClass = '';

    if (!isNaN(numOfSubscribers) && avatarWrapperWidthMap) {
      if (numOfSubscribers <= 3) {
        customClass = avatarWrapperWidthMap[numOfSubscribers];
      } else {
        customClass = avatarWrapperWidthMap[4];
      }
    }

    return customClass;
  };

  if (!Array.isArray(subscribers) || subscribers.length < 1) {
    return;
  }

  return (
    <div key={`shared-with-${sharedWithId}`} className="shared-list-wrapper">
      {showLabel && (
        <span className="shared-label">{constants.SHARED_WITH_LABEL}</span>
      )}
      <div
        key={`shared-with-avatar-${sharedWithId}`}
        ref={avatarsWrapperRef}
        className={`avatars-wrapper ${
          subscribers.length > 0
            ? getCustomClassByNumOfSubscribers(subscribers.length)
            : ''
        }`}
      >
        <div className="avatars">
          {Array.isArray(subscribers) &&
            subscribers.map((subscriber, subscriberIndex) => {
              if (subscriberIndex > 3) {
                return;
              } else if (subscriberIndex === 3) {
                return (
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        key={`tooltip-${subscribers?.accountId}-${sharedWithId}`}
                        className="subscriber-tooltip"
                      >
                        {subscribers.map((subscriber) => (
                          <div
                            key={`subscriber-${subscriber?.emailId}`}
                            className="subscriber-tooltip-text"
                          >
                            {getUserDetailsByAccountId(subscriber?.accountId)
                              ?.name || subscriber?.emailId}
                          </div>
                        ))}
                      </Tooltip>
                    }
                    placement="auto"
                  >
                    <div className="avatar avatar-rest">{`+${
                      subscribers.length - subscriberIndex
                    }`}</div>
                  </OverlayTrigger>
                );
              } else {
                return (
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        key={`tooltip-${subscribers.accountId}-${sharedWithId}`}
                      >
                        {getUserDetailsByAccountId(subscriber?.accountId)
                          ?.name || subscriber?.emailId}
                      </Tooltip>
                    }
                  >
                    <div className={`avatar avatar-${subscriberIndex + 1}`}>{`${
                      getUserDetailsByAccountId(
                        subscriber?.accountId
                      )?.firstName?.charAt(0) || subscriber?.emailId?.charAt(0)
                    }${
                      getUserDetailsByAccountId(
                        subscriber?.accountId
                      )?.lastName?.charAt(0) || ''
                    }`}</div>
                  </OverlayTrigger>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default SharedWith;
