import { useCallback, useEffect, useState } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
// import { getSelectedOrganization } from '../../../../store/reducers/AccountReducer';

import { PrimaryButton, SiteToast } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';

import './DetachLicenseForm.scss';
import { getSelectedOrganization } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../../store/reducers/OrganizationsReducer';

const DetachLicenseForm = ({ deviceId, licenseKey, orgId, callBack }) => {
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const orgInfo = useSelector(getOrgInfo)

  const { setLicenses } = useLicensesStore();
  // const orgDetails = useSelector(getSelectedOrganization);
  const [orgDetails, setOrgDetails] = useState();

  const loadSelectedOrgData = useCallback(async () => {
    const org = await getSelectedOrganization();
    setOrgDetails(org || {});
  }, []);

  const debouncedLoadSelectedOrgData = useDebouncedCallback(
    loadSelectedOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'selectedOrganization') {
        await debouncedLoadSelectedOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadSelectedOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadSelectedOrgData]);

  const detachLicenseFromDevice = async () => {
    try {
      if (!deviceId || !licenseKey) {
        return;
      }

      const reqBody = {
        deviceId: deviceId,
        licenseKey: licenseKey,
      };

      const res = await axios.put(
        `/user/orgs/${orgDetails?.orgId ? orgDetails?.orgId : orgInfo?.orgId}/licenses/detach`,
        reqBody,
        Utils.requestHeader()
      );

      if (res?.data?.meta?.code === 200) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
        callBack();
      } else {
        setShowToast(true);
        setUserMsg(res?.data?.data.msg);
      }
    } catch (error) {
      setShowToast(true);
      setUserMsg(error.msg);
    }
  };

  return (
    <Container>
      <Row>{constants.ORG_DASHBOARD_LICENSES_DETACH_LICENSE_DESCRIPTION}</Row>
      <Row>
        <Col>
          <div className="detach-license-button-wrapper">
            <Button
              variant="outline-danger"
              className="detach-license-button"
              onClick={() => detachLicenseFromDevice()}
            >
              {constants.ORG_DASHBOARD_LICENSES_DETACH_LICENSE_LABEL}
            </Button>
            <PrimaryButton
              className="cancel-button"
              width="78px"
              onClick={() => {
                callBack && callBack();
              }}
            >
              {constants.ORG_DASHBOARD_LICENSES_CANCEL_LABEL}
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DetachLicenseForm;
