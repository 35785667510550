import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip as TooltipMUI,
  Fade,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { debounce } from 'lodash';

import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import useLicensesStore from '../../store/LicensesStore';
import ImageFetch from '../cameras/components/ImageFetch';
import NewIncident from '../incidents/modalForms/NewIncident';
import ManageTags from '../incidents/modalForms/ManageTags';
import { AppDefaults, constants, Utils } from '../../helpers';
import {
  ContextMenu,
  Header,
  LocationSelector,
  PrimaryButton,
  SharedWith,
  SiteModal,
  SiteSpinner,
  SiteToast,
  Spinner,
  TextBlock,
} from '../../components/common';
import Share from './Share';

import { HiOutlineFolderAdd } from 'react-icons/hi';
import { IoIosArrowDown } from 'react-icons/io';
import { RxMagnifyingGlass } from 'react-icons/rx';
import { ReactComponent as ClipContextIcon } from '../../assets/images/icons/ClipsThreeDots.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/Search.svg';

import { error, success } from '../../assets/images';

import './ClipsList.scss';
import {
  StorageDashboard,
  storageLabel,
} from '../../components/common/StorageCommon';
import {
  checkStorageExceed,
  filterLiceneseData,
} from '../../helpers/commonUtils';
import ActiveLicenses from '../../components/common/ActiveLicenses';
import { checkAvailableLicenses } from '../../components/common/ActiveLicenseCommonData';
import { getCustomerOrgDevices } from '../../store/CustomerOrgDevicesStoreIDB';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <TooltipMUI
    TransitionComponent={Fade}
    {...props}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 376,
    marginTop: '-8px',
    marginLeft: '-3px',
    backgroundColor: '#000',
  },
});

const ClipsList = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState('');
  const [toastMessage, setToastMessage] = useState(null);
  const [toastVariant, setToastVariant] = useState('info');
  const [toastDelay, setToastDelay] = useState(8000);
  const [hasData, setHasData] = useState(false);
  const [clips, setClips] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [filteredClips, setFilteredClips] = useState([]);
  const [currentClip, setCurrentClip] = useState(null);
  const [clipDevice, setClipDevice] = useState(null);
  const [clipLocalizedStartTime, setClipLocalizedStartTime] = useState(null);
  const [clipsTags, setClipsTags] = useState([]);
  const [clipsUsers, setClipsUsers] = useState([]);
  const [clipsRemovedUsers, setClipsRemovedUsers] = useState([]);
  const [clipLocationName, setClipLocationName] = useState(null);
  const [clipAreaName, setClipAreaName] = useState(null);
  const [selectedIncidentId, setSelectedIncidentId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([
    constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID,
  ]);
  const [incidentSelections, setIncidentSelections] = useState([]);
  const [readOnlyIncidents, setReadOnlyIncidents] = useState([]);
  const [areTagsModified, setAreTagsModified] = useState(false);
  const [selectedTagFilter, setSelectedTagFilter] = useState(null);
  const [showManageTagsModal, setShowManageTagsModal] = useState(false);
  const [shouldSaveTags, setShouldSaveTags] = useState(false);
  const [showShareVideoClipModal, setShowShareVideoClipModal] = useState(false);
  const [showAddToIncidentModal, setShowAddToIncidentModal] = useState(false);
  const [showAddToIncidentError, setShowAddToIncidentError] = useState(false);
  const [activeLicense, setActiveLicense] = useState(false);
  const [licenseList, setLicenseList] = useState([]);
  const [
    showAddToIncidentConfirmationModal,
    setShowAddToIncidentConfirmationModal,
  ] = useState(false);
  const [showCreateNewIncidentModal, setShowCreateNewIncidentModal] =
    useState(false);
  const [showDeleteClipModal, setShowDeleteClipModal] = useState(false);
  const [isClipAttachedToIncident, setIsClipAttachedToIncident] =
    useState(false);
  const [isClipShared, setIsClipShared] = useState(false);
  const [isClipDeleted, setIsClipDeleted] = useState(false);
  const [submitNewIncidentForm, setSubmitNewIncidentForm] = useState(false);
  const [isNewIncidentFormComplete, setIsNewIncidentFormComplete] =
    useState(false);
  const [storageData, setStorageData] = useState(null);

  const navigate = useNavigate();
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const { getCustomerOrgLocationsData } = useCustomerOrgLocations();
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const customerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.customerOrgLocationsData
  );

  const [customerOrgDevices, setCustomerOrgDevices] = useState([]);
  const currentUserPolicies = getLoggedInUserPolicies();
  const customerOrgUsers = getCustomerOrgUsersData();
  const [orgDetails, setOrgDetails] = useState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const clipsRef = useRef([]);
  const searchTagRef = useRef(null);

  const clipContextMenu = [
    {
      id: 'shareClip',
      icon: null,
      text: constants.CLIPS_LIST_CONTEXT_MENU_SHARE_LABEL,
      clickHandler: (e, data) => {
        if (!data) return;

        setCurrentClip(data);
        setShowShareVideoClipModal(true);
      },
    },
    {
      id: 'downloadClip',
      icon: null,
      text: constants.CLIPS_LIST_CONTEXT_MENU_DOWNLOAD_LABEL,
      clickHandler: (e, data) => {
        if (!data) return;

        handleDownload(data);
      },
    },
    {
      id: 'addToIncident',
      icon: null,
      text: constants.CLIPS_LIST_CONTEXT_MENU_ADD_TO_INCIDENT_LABEL,
      clickHandler: (e, data) => {
        if (!data) return;

        setCurrentClip(data);
        fetchClipById(data?.clipId);
        setShowAddToIncidentModal(true);
      },
    },
    {
      id: 'deleteClip',
      icon: null,
      text: constants.CLIPS_LIST_CONTEXT_MENU_DELETE_LABEL,
      clickHandler: async (e, data) => {
        if (!data) return;

        setCurrentClip(data);
        await fetchClipById(data?.clipId);
        setShowDeleteClipModal(true);
      },
    },
  ];

  const disabledOptions = {
    field: 'deleteClip',
    shouldDisable: (optionIndex) => {
      if (
        isNaN(optionIndex) ||
        optionIndex === null ||
        optionIndex === undefined
      )
        return false;
      if (optionIndex === 3) return true;
    },
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const fetchDevices = async () => {
      const custOrgDevices = await getCustomerOrgDevices();
      setCustomerOrgDevices(custOrgDevices);
    };
    fetchDevices();
  }, []);

  useEffect(() => {
    if (orgDetails?.orgId) {
      fetchData();
    }
  }, [orgDetails?.orgId]);

  // GETTERS
  const getDeviceTimezoneByLocationId = (locationId) => {
    const orgLocations = getCustomerOrgLocationsData();
    const deviceLocation = orgLocations.find(
      (orgLocation) => orgLocation.locationId === locationId
    );
    let timezone;

    if (deviceLocation) {
      timezone = deviceLocation.timezone;
    } else {
      timezone = moment.tz.guess();
    }

    const getLocationSelected = Utils.getTimezone(timezone);

    const zone = getLocationSelected?.location || moment.tz.guess();

    return zone;
  };

  const getClipOwner = (clip) => {
    if (!clip) return;

    return clip?.subscribers[0]?.sharedByAccountId;
  };

  const getUserDetailsByAccountId = (accountId) => {
    let userDetails = {};
    let userAccountId =
      typeof accountId === 'object' ? accountId?.accountId : accountId;

    if (!userAccountId) return;

    if (userAccountId === 'ALL_USERS') {
      if (Array.isArray(clipsUsers) && clipsUsers.length > 0) {
        userDetails = clipsUsers.find(
          (clipUser) => clipUser.accountId === userAccountId
        );
      }
    } else {
      if (Array.isArray(customerOrgUsers) && customerOrgUsers.length > 0) {
        userDetails = customerOrgUsers.find(
          (orgUser) => orgUser.accountId === userAccountId
        );
      }
    }

    return userDetails;
  };

  const getLocationArea = (locationId, areaId) => {
    if (!locationId || !areaId) return;

    const location = customerOrgLocationsData.find(
      (orgLocation) => orgLocation.locationId === locationId
    );

    if (location) {
      setClipLocationName(location.locationName);
      const area = location.areas.find((area) => area.areaId === areaId);
      if (area) {
        setClipAreaName(area.areaName);
      }
    }
  };

  const searchClipByTagName = (e) => {
    const tagName = e?.target.value;

    if (!tagName) {
      setSelectedTagFilter(null);
      setFilteredClips([...clips]);
      return;
    }

    setSelectedTagFilter(tagName);
    const filteredTags = clipsTags.filter((tag) => tag.name === tagName);

    if (filteredTags.length < 1) {
      // return nothing
      setFilteredClips([]);
    } else {
      fetchClipsByTagId(filteredTags[0].tagId);
    }
  };

  // FETCHERS
  const fetchData = async () => {
    try {
      if (customerOrgUsers.length < 1) {
      }
      const accountIds = await fetchUsers();

      const usersPromises = accountIds.map(async (accountId) => {
        if (!accountId) return;

        const userDetails = getUserDetailsByAccountId(accountId);

        return {
          accountId: accountId,
          ...userDetails,
        };
      });

      const orgUsers = await Promise.all(usersPromises);

      if (Array.isArray(orgUsers)) {
        const knownUsers = orgUsers.filter(
          (user) => user.firstName && user.lastName && user.name
        );

        const allUsers = [
          {
            accountId: constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID,
            firstName: constants.CLIPS_LIST_FILTER_DEFAULT_FIRST_NAME,
            lastName: constants.CLIPS_LIST_FILTER_DEFAULT_LAST_NAME,
            name: constants.CLIPS_LIST_FILTER_DEFAULT_NAME,
          },
        ];

        // Setup known users
        if (knownUsers.length === 1) {
          setClipsUsers([...knownUsers]);
        } else if (knownUsers.length > 1) {
          setClipsUsers([...allUsers, ...knownUsers]);
        }

        // Setup removed users
        setClipsRemovedUsers(
          orgUsers.filter(
            (user) => !user.firstName && !user.lastName && !user.name
          )
        );
      }

      await fetchIncidents();
      await fetchTags();
      const fetchClipsData = await fetchClips();

      if (fetchClipsData) {
        setHasData(true);
      }

      await fetchStorageDetail();
      await fetchAvailableLicenses();
    } catch (error) {
      Utils.vmsLogger().error(error);
      setToastMessage(error);
      setToastVariant('error');
      setToastDelay(5000);
      setShowToast(true);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchStorageDetail = async () => {
    try {
      const res = await axios.get(
        `partner/orgs/${orgDetails?.orgId}/storageData`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setStorageData(responseData?.data);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const fetchUsers = async () => {
    let users;

    try {
      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/users`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        users = responseData.data?.accountIds;
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return users;
    }
  };

  const fetchTags = async () => {
    try {
      const res = await axios.get(
        `/partner/orgs/${orgDetails?.orgId}/tags?category=CLIP`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const categorizedTags = [...responseData.data?.tags];

        categorizedTags.sort((tag_a, tag_b) => {
          if (tag_a.name.toUpperCase() < tag_b.name.toUpperCase()) {
            return -1;
          }

          if (tag_a.name.toUpperCase() > tag_b.name.toUpperCase()) {
            return 1;
          }

          return 0;
        });

        setClipsTags([...categorizedTags]);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const fetchClips = async () => {
    let response = false;

    try {
      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/clips`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const clips = responseData.data;
        if (Array.isArray(clips) && clips.length > 0) {
          // Sort the data by created date
          clips.sort((clipA, clipB) => {
            if (clipA?.createdDate < clipB?.createdDate) {
              return 1;
            } else if (clipA?.createdDate > clipB?.createdDate) {
              return -1;
            }
            return 0;
          });

          clipsRef.current = clipsRef.current.slice(0, clips.length);

          setClips([...clips]);
          setFilteredClips([...clips]);
          response = true;

          Utils.vmsLogger()?.log(
            'Clips have been retrieved successfully: ',
            clips
          );
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      return response;
    }
  };

  const filterClipsByAccountIds = (accountIds) => {
    let clipsByAccountIds, subscribers;
    const updatedClips = [];

    if (!Array.isArray(accountIds)) {
      return;
    }

    if (accountIds.includes('ALL_USERS')) {
      clipsByAccountIds = [...clips];
    } else {
      clipsByAccountIds = clips.filter((clip) => {
        const ownerId = clip.subscribers[0].sharedByAccountId;
        const accountMatchingOwner = (accountId) => accountId === ownerId;

        if (accountIds.some(accountMatchingOwner)) {
          return clip;
        } else {
          return null;
        }
      });
    }

    if (Array.isArray(clipsByAccountIds) && clipsByAccountIds.length > 0) {
      setFilteredClips([...clipsByAccountIds]);
    } else {
      setFilteredClips([]);
    }
  };

  const fetchClipById = async (clipId) => {
    try {
      let clipData, subscribers;

      if (!clipId) return;

      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/clips/${clipId}`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        clipData = responseData.data;
        fetchCameraName(clipData?.deviceId);

        // Determine if the clip is currently shared with others
        setIsClipShared(clipData?.subscribers?.length > 1);

        // Remove current user from subscribers list
        subscribers = clipData?.subscribers.filter(
          (subscriber) => subscriber.accountId !== loggedInUserData.accountId
        );

        if (subscribers.length > 0) {
          clipData.subscribers = [...subscribers];
        } else {
          clipData.subscribers = [];
        }

        // Get the clip's timezone using the device ID
        const clipDevice = customerOrgDevices.find(
          (device) => device?.deviceId === clipData?.deviceId
        );
        let clipTimezone;

        // Get local start time for clip based on device's timezone
        if (clipDevice) {
          if (clipDevice?.properties?.timezone) {
            clipTimezone = clipDevice?.properties?.timezone;
          } else {
            const clipLocation = customerOrgLocationsData.find(
              (orgLocation) => orgLocation.locationId === clipData?.locationId
            );

            const getLocationSelected = Utils.getTimezone(
              clipLocation.timezone
            );

            clipTimezone = getLocationSelected?.location || moment.tz.guess();
          }

          setClipLocalizedStartTime(
            moment(clipData?.startTime * 1000)
              .tz(clipTimezone)
              .format('LTS z')
          );

          if (clipData?.incidentIds?.length > 0) {
            setIncidentSelections([...clipData?.incidentIds]);
          }
        }

        if (
          Array.isArray(clipData?.incidentIds) &&
          clipData.incidentIds.length > 0
        ) {
          setReadOnlyIncidents([...clipData.incidentIds]);
          setSelectedOption(clipData.incidentIds[0]);
          setIncidentSelections([...clipData.incidentIds]);
          setIsClipAttachedToIncident(true);
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const filterClipsByLocationId = (locationId) => {
    let clipsByLocationId;

    if (!locationId) return;

    if (locationId === AppDefaults.ALL_LOCATIONS_ID) {
      clipsByLocationId = [...clips];
    } else {
      clipsByLocationId = clips.filter(
        (clip) => clip.locationId === locationId
      );
    }

    if (Array.isArray(clipsByLocationId) && clipsByLocationId.length > 0) {
      setFilteredClips([...clipsByLocationId]);
    } else {
      setFilteredClips([]);
    }
  };

  const fetchClipsByTagId = async (tagId) => {
    try {
      if (!tagId) {
        throw new Error('Tag ID missing');
      }

      setShowLoader(true);
      setFilteredClips([]);
      setHasData(false);

      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/clips?tagId=${tagId}`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        if (Array.isArray(responseData.data) && responseData.data.length > 0) {
          setFilteredClips([...responseData.data]);
          setHasData(true);
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchCameraName = async (deviceId) => {
    try {
      const targetDevice = customerOrgDevices.find(
        (orgDevice) => orgDevice.deviceId === deviceId
      );

      if (targetDevice) {
        getLocationArea(targetDevice.locationId, targetDevice.areaId);
        setClipDevice(targetDevice);
      } else {
        throw new Error('ERROR: could not retrieve device name');
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  const fetchIncidents = async () => {
    try {
      let filteredIncidents;

      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents`,
        Utils.requestHeader()
      );
      const responseData = res?.data;

      // Set incidents if request is successful
      if (responseData?.meta?.code === 200) {
        filteredIncidents = responseData?.data?.incidents.filter(
          (incident) =>
            incident.status === 'OPEN' || incident.status === 'REOPENED'
        );
        if (Array.isArray(filteredIncidents) && filteredIncidents.length > 0) {
          // Sort incidents by summary in descending order
          const sortedFilteredIncidents = filteredIncidents.sort(
            (incidentA, incidentB) => {
              if (
                incidentA?.summary?.toLowerCase() >
                incidentB?.summary?.toLowerCase()
              ) {
                return 1;
              }

              if (
                incidentA?.summary?.toLowerCase() <
                incidentB?.summary?.toLowerCase()
              ) {
                return -1;
              }

              return 0;
            }
          );

          setIncidents([...sortedFilteredIncidents]);
        }
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  /***
   * GET LICENSES LIST
   *
   * TODO: this should be moved to the License Store, or use the
   * License Store's getLicenses() method.  It should be encapsulated
   * in a try-catch block and the spelling mistake corrected.
   */
  const fetchAvailableLicenses = async () => {
    try {
      const res = await axios.get(
        `/user/orgs/${orgDetails?.orgId}/licenses`,
        Utils?.requestHeader()
      );
      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const licenseData = filterLiceneseData(responseData.data);
        const licenseDataWithSelection = licenseData.map((license) => {
          return { ...license, isSelected: false };
        });
        setLicenseList(licenseDataWithSelection);
      } else {
        setLicenseList([]);
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    }
  };

  // HANDLERS
  const handleDownload = async (clipData) => {
    let targetClip, filename;

    // Ensure there are no other toast on display
    setShowToast(false);

    if (!clipData) {
      Utils.vmsLogger().error('Clip data is missing');
    }

    if (!clipData.clip?.url) {
      Utils.vmsLogger().error('Clip ID is missing');
    }

    if (!clipData.clipStartTime) {
      Utils.vmsLogger().error('Clip start time is missing');
    }

    if (!clipData.clipTimezone) {
      Utils.vmsLogger().error('Clip timezone is missing');
    }

    const res = await axios.get(
      `/clip/orgs/${orgDetails?.orgId}/clips/${clipData?.clipId}`,
      Utils.requestHeader()
    );

    const responseData = res?.data;

    if (responseData?.meta?.code === 200) {
      targetClip = responseData.data;

      filename = `video-clip_${moment(clipData?.startTime * 1000)
        .tz(clipData?.clipTimezone)
        .format('YYYY-MM-DD_HH-mm-ss_A_z')}.mp4`;

      // Notify user that file download is about
      // to start
      setToastTitle(constants.CLIPS_LIST_TOAST_TITLE_DOWNLOAD_IN_PROGRESS);
      setToastMessage(
        Utils.replaceStringWithKeyValueArray(
          constants.CLIPS_LIST_TOAST_BODY_DOWNLOAD_IN_PROGRESS_MSG,
          [
            {
              key: '$clipFile',
              value: filename,
            },
          ]
        )
      );
      setToastVariant('info');
      setToastDelay(5000);
      setShowToast(true);

      // Retrieve the video clip from remote server
      fetch(targetClip?.clip?.url)
        .then((response) => response.blob())
        .then((blob) => {
          // First close any existing toast
          // Create a URL based on the binary data of the video clip
          const url = URL.createObjectURL(blob);
          // Create a temporary anchor element with the download URL
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          // Simulate a click event to trigger the browser's download prompt
          document.body.appendChild(link);
          link.click();
          // Remove the temporary element
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          setToastTitle(constants.CLIPS_LIST_TOAST_TITLE_DOWNLOAD_SUCCESS);
          setToastMessage(
            Utils.replaceStringWithKeyValueArray(
              constants.CLIPS_LIST_TOAST_BODY_DOWNLOAD_SUCCESS_MSG,
              [
                {
                  key: '$clipFile',
                  value: filename,
                },
              ]
            )
          );
          setToastVariant('success');
          setToastDelay(8000);
          setShowToast(true);
        })
        .catch((error) => {
          Utils.vmsLogger().error(error.message || error);
          setToastTitle(constants.CLIPS_LIST_TOAST_TITLE_DOWNLOAD_ERROR);
          setToastMessage(
            Utils.replaceStringWithKeyValueArray(
              constants.CLIPS_LIST_TOAST_BODY_DOWNLOAD_FAILURE_MSG,
              [
                {
                  key: '$clipFile',
                  value: filename,
                },
              ]
            )
          );
          setToastVariant('error');
          setToastDelay(5000);
          setShowToast(true);
        })
        .finally(() => {
          // TODO: perform additional user feedback
        });
    } else {
      setToastTitle(constants.CLIPS_LIST_TOAST_TITLE_DOWNLOAD_ERROR);
      setToastMessage(
        Utils.replaceStringWithKeyValueArray(
          constants.CLIPS_LIST_TOAST_BODY_DOWNLOAD_FAILURE_MSG,
          [
            {
              key: '$clipFile',
              value: filename,
            },
          ]
        )
      );
      setToastVariant('error');
      setToastDelay(5000);
      setShowToast(true);
    }
  };

  const handleAddToIncident = async () => {
    let isClipAddedToIncident = false;

    try {
      setShowButtonLoader(true);

      let isSuccessful = false;

      if (!currentClip || incidentSelections.length < 1) {
        throw new Error('ERROR: missing parameters');
      }

      // Required fields.
      const reqBody = {
        deviceId: currentClip?.deviceId,
        clipStartTime: currentClip?.startTime,
        clipEndTime: currentClip?.endTime,
        clipId: currentClip.clipId,
      };

      if (incidentSelections.length > 0) {
        let incidentId;

        for (let i = 0; i < incidentSelections.length; i++) {
          incidentId = incidentSelections[i];

          const res = await axios.post(
            `/incident/orgs/${orgDetails?.orgId}/incidents/${incidentId}/clips`,
            reqBody,
            {
              ...Utils.requestHeader(),
              credentials: 'include',
              withCredentials: true,
            }
          );

          const responseData = res?.data;
          if (responseData.meta?.code === 200) {
            setReadOnlyIncidents((currentIncidents) => [
              ...currentIncidents,
              incidentId,
            ]);
            isClipAddedToIncident = true;
            isSuccessful = true;
          } else {
            isClipAddedToIncident = false;
            isSuccessful = false;
            Utils.vmsLogger().error(res?.meta?.userMsg);
          }
        }

        if (isSuccessful && isClipAddedToIncident) {
          setShowAddToIncidentModal(false);
          setShowAddToIncidentConfirmationModal(true);
        }
      } else {
        throw new Error('ERROR: no incidents specified');
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      setShowButtonLoader(false);
      return isClipAddedToIncident;
    }
  };

  const handleDeleteClip = async (clip) => {
    try {
      if (!clip || !clip.clipId) {
        return;
      }
      setShowLoader(true);
      const res = await axios.delete(
        `/clip/orgs/${orgDetails?.orgId}/clips/${clip.clipId}`,
        Utils.requestHeader()
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setIsClipDeleted(true);
        setClipLocationName(null);
        setClipAreaName(null);
        setIsClipShared(false);
        setIsClipAttachedToIncident(false);
        setCurrentClip(null);
        // TODO: refresh clips
        const fetchClipsData = await fetchClips();

        if (fetchClipsData) {
          setHasData(true);
        }
        setShowDeleteClipModal(false);
      } else {
        if (res?.code) {
          Utils.vmsLogger().error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          Utils.vmsLogger().error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      Utils.vmsLogger().error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleModalCallback = () => {
    setActiveLicense(!activeLicense);
    fetchStorageDetail();
    fetchAvailableLicenses();
  };

  // RENDERERS
  const renderUsersList = () => {
    return (
      <FormControl className="clips-list-form-control" fullWidth>
        <InputLabel
          className="users-select-label"
          shrink={selectedOptions && selectedOptions?.length > 0 ? true : false}
        >
          <span>{constants.CLIPS_LIST_FILTER_USER_SELECT_LABEL}</span>
        </InputLabel>
        <Select
          className="users-select"
          value={selectedOptions}
          onChange={(event) => {
            let accountIds = event?.target?.value;

            if (Array.isArray(accountIds)) {
              if (accountIds.length > 0) {
                if (
                  accountIds[0] ===
                    constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID &&
                  accountIds.length > 1
                ) {
                  accountIds = Utils.removeArrayElement(
                    accountIds,
                    constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID
                  );
                } else if (
                  accountIds.includes(
                    constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID
                  )
                ) {
                  accountIds = [constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID];
                }
              }

              setSelectedOptions([...accountIds]);
              filterClipsByAccountIds([...accountIds]);
            }
          }}
          endAdornment={
            <InputAdornment className="users-select-arrow-icon" position="end">
              <IoIosArrowDown
                size={'1.15rem'}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
              />
            </InputAdornment>
          }
          renderValue={() => (
            <div className="selected-user">
              {selectedOptions.length > 1
                ? constants.CLIPS_LIST_FILTER_USER_MULTIPLE_SELECTED
                : selectedOptions.length === 1 &&
                  selectedOptions.includes(
                    constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID
                  )
                ? constants.CLIPS_LIST_FILTER_DEFAULT_NAME
                : constants.CLIPS_LIST_FILTER_USER_SELECT_LABEL}
            </div>
          )}
          MenuProps={MenuProps}
          multiple={true}
          autoWidth={true}
        >
          {Array.isArray(clipsUsers) &&
            clipsUsers.length > 0 &&
            clipsUsers.map((clipUser) => {
              return (
                <MenuItem
                  key={`user-${clipUser?.accountId}`}
                  className="users-item-wrapper"
                  value={clipUser?.accountId}
                >
                  <Checkbox
                    className="users-item-checkbox"
                    // defaultChecked={
                    //   clipUser?.accountId ===
                    //   constants.CLIPS_LIST_FILTER_DEFAULT_ACCOUNT_ID
                    // }
                    checked={selectedOptions.includes(clipUser?.accountId)}
                    value={clipUser?.accountId}
                  />
                  <Typography variant="inherit" noWrap>
                    <TooltipMUI
                      title={
                        clipUser?.name
                          ? clipUser.name
                          : constants.CLIPS_LIST_FILTER_UNKNOWN_USER_NAME
                      }
                      placement="top-start"
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 5],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      {clipUser?.name
                        ? clipUser.name
                        : constants.CLIPS_LIST_FILTER_UNKNOWN_USER_NAME}
                    </TooltipMUI>
                  </Typography>
                </MenuItem>
              );
            })}
          {Array.isArray(clipsRemovedUsers) && clipsRemovedUsers.length > 0 && (
            <ListSubheader>
              {
                constants.CLIPS_LIST_FILTER_USER_SELECT_REMOVED_USERS_SEGMENT_TITLE
              }
            </ListSubheader>
          )}
          {Array.isArray(clipsRemovedUsers) &&
            clipsRemovedUsers.length > 0 &&
            clipsRemovedUsers.map((clipRemovedUser) => {
              return (
                <MenuItem
                  key={`user-${clipRemovedUser.accountId}`}
                  className="users-item-wrapper"
                  value={clipRemovedUser.accountId}
                >
                  <Checkbox
                    className="users-item-checkbox"
                    checked={selectedOptions.includes(
                      clipRemovedUser.accountId
                    )}
                  />
                  <Typography variant="inherit" noWrap>
                    <TooltipMUI
                      title={constants.CLIPS_LIST_FILTER_UNKNOWN_USER_NAME}
                      placement="top-start"
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 5],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      {constants.CLIPS_LIST_FILTER_UNKNOWN_USER_NAME}
                    </TooltipMUI>
                  </Typography>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  };

  const renderNoClipsContent = (resultType = 'no-clips') => (
    <div className="no-clips">
      <div className="no-matching-tags">
        <SearchIcon
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_64'
          )}
          size={'2.5rem'}
        />
        <div className="no-match-title">
          {resultType?.toLowerCase() === 'no-results'
            ? constants.CLIPS_LIST_SEARCH_NO_RESULTS_FOUND_TEXT
            : constants.CLIPS_LIST_NO_CLIPS_AVAILABLE_TEXT}
        </div>
        <div className="no-match-message">
          <span>
            {resultType?.toLowerCase() === 'no-results'
              ? constants.CLIPS_LIST_SEARCH_NO_RESULTS_MESSAGE
              : constants.CLIPS_LIST_NO_CLIPS_AVAILABLE_SUBTEXT}
          </span>
          <br />
          {resultType?.toLowerCase() === 'no-results' && (
            <>
              <span>
                {constants.CLIPS_LIST_SEARCH_NO_RESULTS_CLIPS_SEE_ALL}
              </span>
              <span
                className="clips-link"
                onClick={() => {
                  setSelectedTagFilter(null);
                  setFilteredClips([...clips]);
                  setHasData(true);

                  if (searchTagRef) {
                    searchTagRef.current.value = null;
                  }
                }}
              >
                {constants.CLIPS_LIST_SEARCH_NO_RESULTS_CLIPS_LINK}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const updateSubscribers = async (subscribers, mode = 'GRANT') => {
    let currentSubscribers = [],
      mergedSubscribers,
      subscriberIndex;

    if (!Array.isArray(subscribers)) return;

    currentSubscribers = [...currentClip?.subscribers];

    if (mode.toUpperCase() === 'GRANT') {
      mergedSubscribers = currentSubscribers.concat(subscribers);
    } else {
      // Find the subscriber with the revoked access
      subscriberIndex = currentSubscribers.findIndex(
        (subscriber) => subscriber.email === subscribers[0].email
      );

      if (subscriberIndex !== -1) {
        // Update the subscriber with revoked access
        currentSubscribers[subscriberIndex] = subscribers[0];
      }

      mergedSubscribers = [...currentSubscribers];
    }

    const updatedCurrentClip = {
      ...currentClip,
      subscribers: mergedSubscribers,
    };

    await new Promise((resolve) => {
      setCurrentClip(updatedCurrentClip);
      resolve();
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        width: 200,
        marginTop: 5,
        borderRadius: 10,
        boxShadow: 'none',
        border: `1px solid rgba(var(--greyscale-custom1), 0.15)`,
      },
    },
    MenuListProps: {
      style: {
        paddingTop: 0,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const debouncedOnChange = debounce(searchClipByTagName, 350);

  const showActiveLicenseListModal = () => {
    setActiveLicense(!activeLicense);
  };

  return (
    <div className="App clips-list">
      <Header />
      <Container className="clips-list-container mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className="clips-main-container mw-100">
              <div className="page-header mt-4 mb-5">
                <Row xs={1} md={2} className="g-4">
                  <Col md={4} lg={4} xl={4} xs={12} className="left-align">
                    <div className="page-title">
                      <div className="clips-list-title">
                        {constants.CLIPS_LIST_PAGE_TITLE}{' '}
                        <span className="clips-total">
                          {Utils.replaceStringValues(
                            constants.CLIPS_LIST_NUM_OF_CLIPS_RESULTS,
                            '$numOfClips',
                            `${filteredClips?.length}`
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col md={8} lg={8} xl={8} xs={12} className="text-end">
                    {/* Manage Tags */}
                    <div className="clips-list-top-cta-wrapper">
                      {currentUserPolicies?.create_update_delete_tag && (
                        <Button
                          className="manage-tags-button"
                          variant="outline-primary"
                          size="md"
                          onClick={() => {
                            setShowManageTagsModal(true);
                          }}
                        >
                          <span className="manage-tags-button-label">
                            {constants.CLIPS_LIST_MANAGE_TAGS_BUTTON_LABEL}
                          </span>
                        </Button>
                      )}
                      <Autocomplete
                        disablePortal
                        id="search-tags-combo-box"
                        className="clips-filter"
                        options={clipsTags.map((tag) => tag.name)}
                        sx={{
                          m: 1,
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                            {
                              border: `1.5px solid var(--primary_40)`,
                            },
                          '& + .MuiAutocomplete-popper': {
                            '& .MuiAutocomplete-paper': {
                              color: `var(--brand_white)`,
                              backgroundColor: `var(--grayscale_24)`,
                              marginTop: '5px',
                              boxShadow: 'none !important',
                              border: `1px solid rgba(var(--greyscale-custom1), 0.15)`,
                              borderRadius: '10px',
                              '& .MuiAutocomplete-listbox': {
                                paddingTop: 0,
                                paddingBottom: 0,
                                '& .MuiAutocomplete-option': {
                                  '&:hover': {
                                    backgroundColor: `var(--hGreyscale_40)`,
                                  },
                                  '&[aria-selected="true"]': {
                                    backgroundColor: `var(--hGreyscale_40) !important`,
                                    '&.Mui-focused:': {
                                      backgroundColor: `var(--hGreyscale_40)`,
                                    },
                                  },
                                  '&.Mui-focused': {
                                    backgroundColor: `var(--hGreyscale_40)`,
                                  },
                                },
                              },
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={searchTagRef}
                            id="outlined-adornment-amount"
                            className="clips-filter"
                            sx={{
                              borderRadius: 2,
                              '&:hover .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline':
                                {
                                  borderColor: `var(--primary_40)`,
                                },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <RxMagnifyingGlass
                                  className="clip-search-icon"
                                  size={'1.15rem'}
                                />
                              ),
                              endAdornment: (
                                // No End Adornment
                                <></>
                              ),
                            }}
                            placeholder={
                              constants.CLIPS_LIST_SEARCH_BY_TAGS_PLACEHOLDER
                            }
                            onChange={debouncedOnChange}
                            onSelect={debouncedOnChange}
                            value={selectedTagFilter}
                          />
                        )}
                        autoSelect={false}
                        noOptionsText={
                          constants.CLIPS_LIST_SEARCH_BY_TAGS_NO_NATCH
                        }
                      />
                      {/* Filter by Sharer */}
                      <div
                        className={
                          clipsUsers?.length === 0 &&
                          clipsRemovedUsers?.length === 0
                            ? 'users-list-wrapper users-list-wrapper-disabled'
                            : 'users-list-wrapper'
                        }
                      >
                        {renderUsersList()}
                      </div>
                      {/* Locations Filter */}
                      <div className="locations-wrapper">
                        <LocationSelector
                          variant="dark"
                          minWidth={'200px'}
                          persist={false}
                          callback={(locationId) => {
                            if (!locationId) return;

                            filterClipsByLocationId(locationId);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="toast-wrapper">
                    <SiteToast
                      customCss="clips-list-toast"
                      position="top-end"
                      show={showToast}
                      title={toastTitle}
                      body={toastMessage}
                      variant={toastVariant}
                      delay={toastDelay}
                      onCloseCallback={() => {
                        setShowToast(false);
                      }}
                    />
                  </div>
                </Row>
                <div className="m-l-15 clips-storage-wrapper">
                  {storageData !== null &&
                    storageLabel(
                      storageData?.usedStorage,
                      storageData?.allocatedStorage
                    )}
                </div>
                {storageData !== null &&
                  StorageDashboard(
                    storageData?.usedStorage,
                    storageData?.allocatedStorage,
                    constants.FROM_CLIP_DASHBOARD
                  )}
                {licenseList.length > 0 &&
                  storageData !== null &&
                  checkStorageExceed(
                    storageData?.usedStorage,
                    storageData?.allocatedStorage
                  ) &&
                  checkAvailableLicenses((result) => {
                    showActiveLicenseListModal();
                  })}
                {currentUserPolicies.view_license_key &&
                  currentUserPolicies.assign_license &&
                  activeLicense && (
                    <ActiveLicenses
                      data={licenseList}
                      callBackModal={handleModalCallback}
                      from={constants.FROM_CLIP}
                    />
                  )}
                <Row>
                  {showLoader ? (
                    <div
                      className="spinner-container"
                      style={{ height: `calc(100vh - 208px)` }}
                    >
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <SiteSpinner height="50px" width="50px" />
                        <div className="mt-2 loading-text">
                          {constants.LOADING}
                        </div>
                      </div>
                    </div>
                  ) : hasData ? (
                    <div
                      className={`clips-container ${
                        filteredClips.length < 6 ? 'justify-left' : ''
                      }`}
                    >
                      {Array.isArray(filteredClips) && filteredClips.length > 0
                        ? filteredClips.map((clip, clipIndex) => (
                            <div
                              key={clip.clipId}
                              className="clip-card"
                              onClick={(e) => {
                                navigate(
                                  `/clips/details.html?o=clips&clipId=${clip.clipId}`
                                );
                              }}
                            >
                              <div className="clip-snapshot">
                                {!clip.clip || !clip.clip.url ? (
                                  <>No snapshot</>
                                ) : clip.status?.toUpperCase() ===
                                  'VIDEO_PROCESSING' ? (
                                  <div className="clip-video-processing">
                                    <div>
                                      <Spinner
                                        height="24"
                                        width="24"
                                        strokeColor={getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue('--primary_40')}
                                        strokeWidth="7"
                                        fill="none"
                                      />
                                      <ImageFetch
                                        customClass="clip-video-processing-snapshot"
                                        time={clip?.startTime}
                                        imageURL={clip?.clip?.url}
                                        deviceId={clip?.deviceId}
                                      />
                                    </div>
                                    <div className="clip-video-processing-status">
                                      <div className="clip-video-processing-status-title">
                                        {
                                          constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_TITLE
                                        }
                                      </div>
                                      <div className="clip-video-processing-status-message">
                                        {
                                          constants.CLIPS_LIST_PLEASE_CHECK_BACK_LATER_TEXT
                                        }
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <ImageFetch
                                    customClass="clip-snapshot-image"
                                    time={clip?.startTime}
                                    imageURL={clip?.clip?.url}
                                    deviceId={clip?.deviceId}
                                  />
                                )}
                                {/* Contextual Menu */}
                                <div
                                  ref={(el) =>
                                    (clipsRef.current[clipIndex] = el)
                                  }
                                  className="clip-snapshot-menu"
                                >
                                  <div className="clip-snapshot-menu-contextual-container">
                                    <ContextMenu
                                      className="clip-snapshot-menu-contextual-menu"
                                      iconButton={
                                        <ClipContextIcon className="clip-snapshot-menu-dots" />
                                      }
                                      iconButtonOnClickCallback={() => {
                                        clipsRef.current[
                                          clipIndex
                                        ].classList.add('active');
                                      }}
                                      menuId={'clip-menu'}
                                      menuItems={clipContextMenu}
                                      menuMinWidth="160px"
                                      menuOnCloseCallback={() => {
                                        clipsRef.current[
                                          clipIndex
                                        ].classList.remove('active');
                                      }}
                                      customItemTextClass="clip-menu-item-option"
                                      customData={{
                                        ...clip,
                                        clipStartTime: clip.startTime,
                                        clipTimezone:
                                          getDeviceTimezoneByLocationId(
                                            clip.locationId
                                          ),
                                      }}
                                      customAnchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      customTransformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                      }}
                                      disabledOptions={disabledOptions}
                                      externalDisableCondition={
                                        !currentUserPolicies?.manage_all_clips &&
                                        (!currentUserPolicies?.delete_video_clip ||
                                          !clip.subscribers.find(
                                            (subscriber) =>
                                              subscriber?.sharedByAccountId ===
                                              loggedInUserData.accountId
                                          ))
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="clip-comment-details">
                                <div className="clip-comment">
                                  <OverlayTrigger
                                    key={`triggerHeader${clip?.clipId}`}
                                    // placement="top-left"
                                    overlay={
                                      <Tooltip
                                        key={`tooltipHeader${clip?.clipId}`}
                                      >
                                        {clip?.comment}
                                      </Tooltip>
                                    }
                                  >
                                    <div>{clip?.comment}</div>
                                  </OverlayTrigger>
                                </div>
                                <div className="clip-subscribers">
                                  <div className="clip-creator-wrapper">
                                    <div className="clip-creator-name">
                                      <OverlayTrigger
                                        key={`triggerHeader${clip?.clipId}${clip?.deviceId}`}
                                        overlay={
                                          <Tooltip
                                            key={`tooltipHeader${clip?.clipId}${clip?.deviceId}`}
                                          >
                                            {
                                              getUserDetailsByAccountId(
                                                getClipOwner(clip)
                                              )?.name
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <div>
                                          {
                                            getUserDetailsByAccountId(
                                              getClipOwner(clip)
                                            )?.name
                                          }
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                    <div className="clip-create-date">
                                      {moment(clip?.startTime * 1000)
                                        .tz(
                                          getDeviceTimezoneByLocationId(
                                            clip?.locationId
                                          )
                                        )
                                        .format('ll LTS z')}
                                    </div>
                                  </div>
                                  <div className="clip-shared-wrapper">
                                    <SharedWith
                                      sharedWithId={clip.clipId}
                                      orgId={orgDetails?.orgId}
                                      subscribers={clip.subscribers}
                                      showLabel={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : renderNoClipsContent('no-results')}
                    </div>
                  ) : (
                    <div className="clips-container">
                      {renderNoClipsContent('no-clips')}
                    </div>
                  )}
                </Row>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
      {/* Modals */}
      {/* Manage Tags */}
      <SiteModal
        key={'clips-manage-tags-modal'}
        classes="manage-tags-modal"
        wrapperClass="manage-tags-modal-wrapper"
        modalTitle={constants.INCIDENTS_BUTTON_MANAGE_TAGS_LABEL}
        showModal={showManageTagsModal}
        hideModal={() => {
          setAreTagsModified(false);
          setShowManageTagsModal(false);
        }}
        modalFooter={
          <div className="px-0 mx-0 manage-tags-button-group">
            <div className="mb-3 px-0 manage-tags-button-wrapper">
              <PrimaryButton
                className={`manage-tags-save-btn${
                  !areTagsModified ? ' disabled' : ''
                }`}
                loader={showButtonLoader}
                type="submit"
                disabled={!areTagsModified}
                onClick={(e) => {
                  e?.preventDefault();
                  setShouldSaveTags(true);
                }}
              >
                {constants.INCIDENTS_MANAGE_TAGS_MODAL_SAVE_BUTTON_LABEL}
              </PrimaryButton>
            </div>
            <div className="px-0 manage-tags-button-wrapper">
              <Button
                className="manage-tags-cancel-btn"
                variant="outline-secondary"
                onClick={() => {
                  setShowManageTagsModal(false);
                }}
              >
                {constants.INCIDENTS_MANAGE_TAGS_MODAL_CANCEL_BUTTON_LABEL}
              </Button>
            </div>
          </div>
        }
      >
        <ManageTags
          openModal={(showModal) => {
            setShowManageTagsModal(showModal);
          }}
          clipCategory="CLIP"
          setAreTagsModified={setAreTagsModified}
          shouldSaveTags={shouldSaveTags}
          setShowButtonLoader={setShowButtonLoader}
          callback={setShouldSaveTags}
        />
      </SiteModal>
      {/* Share Video Clip */}
      <SiteModal
        key={'share-video-clip-modal'}
        classes="share-video-clip-modal"
        wrapperClass="share-video-clip-modal-wrapper"
        modalTitle={constants.CLIP_DETAILS_SHARE_VIDEO_CLIP_MODAL_TITLE}
        showModal={showShareVideoClipModal}
        hideModal={() => {
          setCurrentClip(null);
          setShowShareVideoClipModal(false);
        }}
      >
        <Share
          clipId={currentClip?.clipId}
          grantCallback={async (subscribers) => {
            await updateSubscribers(subscribers);
            setShowShareVideoClipModal(false);
          }}
          revokeCallback={async (updatedSubscriber) => {
            await updateSubscribers([{ ...updatedSubscriber }], 'REVOKE');
          }}
          extendCallback={async (updatedSubscriber) => {
            await updateSubscribers([{ ...updatedSubscriber }], 'EXTEND');
          }}
        />
      </SiteModal>
      {/* Add to Incident */}
      <SiteModal
        key={'clips-add-to-incident-modal'}
        classes="clips-add-to-incident-modal"
        wrapperClass="clips-add-to-incident-modal-wrapper"
        modalTitle={
          showCreateNewIncidentModal
            ? constants.INCIDENTS_MODAL_NEW_INCIDENT_TITLE
            : constants.CAMERAS_LIVE_PLAYBACK_ADD_TO_INCIDENT_TEXT
        }
        showModal={showAddToIncidentModal}
        hideModal={() => {
          setShowAddToIncidentModal(false);
          setSelectedIncidentId(null);
          setIncidentSelections([]);
          setShowCreateNewIncidentModal(false);
        }}
        modalFooter={
          showCreateNewIncidentModal ? (
            <>
              <div className="add-to-incident-button-wrapper">
                <PrimaryButton
                  className="save-btn"
                  loader={showButtonLoader}
                  type="submit"
                  disabled={!isNewIncidentFormComplete}
                  fontSize={'18px'}
                  fontWeight={'600'}
                  onClick={(e) => {
                    e?.preventDefault();
                    setSubmitNewIncidentForm(true);
                    setShowButtonLoader(true);
                  }}
                >
                  {constants.INCIDENTS_MODAL_NEW_INCIDENT_CREATE_BUTTON_LABEL}
                </PrimaryButton>
              </div>
              <div className="add-to-incident-button-wrapper">
                <Button
                  className="add-to-incident-create-new-btn"
                  variant="outline-secondary"
                  onClick={(e) => {
                    setShowCreateNewIncidentModal(false);
                  }}
                >
                  {constants.INCIDENTS_MODAL_NEW_INCIDENT_CANCEL_BUTTON_LABEL}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="mb-1 add-to-incident-button-wrapper">
                <PrimaryButton
                  className="add-to-incident-save-btn"
                  type="button"
                  disabled={incidentSelections.length < 1}
                  loader={showButtonLoader}
                  onClick={(e) => {
                    e?.preventDefault();
                    handleAddToIncident();
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CONFIRM_BUTTON_LABEL
                  }
                </PrimaryButton>
              </div>
              <div className="add-to-incident-button-wrapper">
                <Button
                  className="add-to-incident-create-new-btn"
                  variant="outline-secondary"
                  onClick={(e) => {
                    setShowCreateNewIncidentModal(true);
                  }}
                >
                  {
                    constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CREATE_NEW_INCIDENT_BUTTON_LABEL
                  }
                </Button>
              </div>
            </>
          )
        }
      >
        <div
          className={`add-to-incident-error ${
            !showAddToIncidentError ? 'hidden' : ''
          }`}
        >
          <span className="add-to-incident-error-message">
            {constants.CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_ERROR}
          </span>
        </div>
        {showCreateNewIncidentModal ? (
          <>
            <NewIncident
              shouldSubmitForm={submitNewIncidentForm}
              openModal={(showModal) => {
                setShowCreateNewIncidentModal(showModal);
              }}
              enableButtonLoader={(enable) => setShowButtonLoader(enable)}
              setFormComplete={setIsNewIncidentFormComplete}
              submitCallback={async (data) => {
                if (data) {
                  setSelectedIncidentId(data?.incidentId);
                }
                await fetchIncidents();
              }}
            />
          </>
        ) : (
          Array.isArray(incidents) &&
          incidents.map((incident) => (
            <TextBlock
              key={incident?.incidentId}
              className="d-flex justify-content-between align-items-center add-to-incident-radio-row"
            >
              <div className="add-to-incident-summary">
                <HiOutlineFolderAdd className="add-to-incident-folder" />
                {incident?.summary.length > 42 ? (
                  <CustomWidthTooltip
                    title={incident?.summary}
                    placement="bottom-start"
                  >
                    <span className="add-to-incident-summary-title">
                      {incident?.summary}
                    </span>
                  </CustomWidthTooltip>
                ) : (
                  <span className="add-to-incident-summary-title">
                    {incident?.summary}
                  </span>
                )}
              </div>
              <Checkbox
                checked={
                  incidentSelections?.indexOf(incident?.incidentId) > -1 ||
                  readOnlyIncidents?.indexOf(incident?.incidentId) > -1
                }
                value={incident?.incidentId}
                disabled={readOnlyIncidents?.indexOf(incident?.incidentId) > -1}
                size="medium"
                sx={{
                  color:
                    readOnlyIncidents?.indexOf(incident?.incidentId) > -1
                      ? getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_16')
                      : getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_primary'),
                  '&.Mui-checked': {
                    color:
                      readOnlyIncidents?.indexOf(incident?.incidentId) > -1
                        ? getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--primary_16')
                        : getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_primary'),
                  },
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  const currentIncidentSelections = [...incidentSelections];
                  let incidentIndex = -1;

                  if (!value) return;

                  if (currentIncidentSelections.length > 0) {
                    incidentIndex = currentIncidentSelections.indexOf(value);
                  }

                  if (incidentIndex !== -1) {
                    currentIncidentSelections.splice(incidentIndex, 1);
                    setIncidentSelections([...currentIncidentSelections]);
                    setSelectedIncidentId(null);
                  } else {
                    setIncidentSelections((currentSelections) => [
                      ...currentSelections,
                      value,
                    ]);
                    setSelectedIncidentId(value);
                  }
                }}
              />
            </TextBlock>
          ))
        )}
      </SiteModal>
      {/* Add to Incident Confirmation */}
      <SiteModal
        key={'add-to-incident-confirmation-modal'}
        classes="add-to-incident-confirmation-modal"
        wrapperClass="add-to-incident-confirmation-modal-wrapper"
        modalTitle={
          constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_TITLE
        }
        showModal={showAddToIncidentConfirmationModal}
        hideModal={() => {
          setShowAddToIncidentConfirmationModal(false);
        }}
        modalFooter={
          <>
            <div className="mb-1 add-to-incident-confirmation-button-wrapper">
              <PrimaryButton
                className="add-to-incident-confirmation-go-to-incident-btn"
                type="button"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowAddToIncidentConfirmationModal(false);
                  navigate(
                    `/incidents/details.html?incidentId=${selectedIncidentId}&activeTab=0`
                  );
                }}
              >
                {
                  constants.CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_GO_TO_INCIDENT_BUTTON_LABEL
                }
              </PrimaryButton>
            </div>
            <div className="add-to-incident-confirmation-button-wrapper">
              <Button
                className="add-to-incident-confirmation-back-to-video-btn"
                variant="outline-secondary"
                onClick={(e) => {
                  setShowAddToIncidentConfirmationModal(false);
                }}
              >
                {
                  constants.CLIPS_LIST_ADD_TO_INCIDENT_CONFIRMATION_MODAL_BACK_TO_CLIPS_LABEL
                }
              </Button>
            </div>
          </>
        }
      >
        <div className="add-to-incident-confirmation-description">
          {constants.CLIPS_LIST_ADD_TO_INCIDENT_CONFIRMATION_MODAL_DESCRIPTION}
        </div>
        <div className="add-to-incident-confirmation-success-icon">
          <img src={success} alt="green circled check mark" />
        </div>
      </SiteModal>
      {/* Delete Clip  */}
      <SiteModal
        key={'delete-clip-modal'}
        classes="delete-clip-modal"
        wrapperClass="delete-clip-modal-wrapper"
        modalTitle={constants.CLIPS_LIST_DELETE_CLIP_MODAL_TITLE}
        showModal={showDeleteClipModal}
        hideModal={() => {
          setClipLocationName(null);
          setClipAreaName(null);
          setIsClipDeleted(false);
          setIsClipShared(false);
          setIsClipAttachedToIncident(false);
          setCurrentClip(null);
          setShowDeleteClipModal(false);
        }}
      >
        {isClipAttachedToIncident ? (
          <div className="delete-clip-confirmed-container">
            <div className="my-4 delete-clip-confirmed-message">
              {
                constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_CONFIRMED_MESSAGE
              }
            </div>
            <div className="delete-clip-confirmed-button-group">
              <PrimaryButton
                onClick={() => {
                  setClipLocationName(null);
                  setClipAreaName(null);
                  setIsClipDeleted(false);
                  setIsClipShared(false);
                  setCurrentClip(null);
                  setIsClipAttachedToIncident(false);
                  setShowDeleteClipModal(false);
                }}
              >
                {constants.CLIP_DETAILS_DELETE_CONFIRMATION_CLOSE_BUTTON_LABEL}
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <div className="delete-clip-confirmation-container">
            <div className="delete-clip-confirmation-message">
              <>
                {
                  constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_DELETE_MESSAGE_1A
                }{' '}
                <span className="delete-clip-confirmation-clip-identifier">{`${clipDevice?.deviceName} - ${clipLocalizedStartTime}`}</span>{' '}
                {
                  constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_DELETE_MESSAGE_1B
                }{' '}
                {isClipShared ? (
                  <>
                    <span>
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_OWNER_MESSAGE_1A
                      }
                    </span>{' '}
                    <span className="delete-clip-confirmation-message-emphasis">
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_OWNER_MESSAGE_1B
                      }
                    </span>
                    .{' '}
                  </>
                ) : (
                  <>
                    <span>
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_SUBSCRIBER_MESSAGE_1A
                      }
                    </span>{' '}
                    <span className="delete-clip-confirmation-message-emphasis">
                      {
                        constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_SUBSCRIBER_MESSAGE_1B
                      }
                    </span>
                    .{' '}
                  </>
                )}
                {
                  constants.CLIP_DETAILS_DELETE_CONFIRMATION_MODAL_DELETE_MESSAGE_2
                }
              </>
            </div>
            <div className="delete-clip-confirmation-button-group">
              <PrimaryButton
                className="delete-clip-confirmation-delete-btn"
                type="button"
                loader={showLoader}
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                onClick={(e) => {
                  e?.preventDefault();
                  handleDeleteClip(currentClip);
                }}
              >
                {constants.CLIP_DETAILS_DELETE_CONFIRMATION_DELETE_BUTTON_LABEL}
              </PrimaryButton>
              <PrimaryButton
                variant="outline-primary"
                className="delete-clip-confirmation-cancel-btn"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                onClick={() => {
                  setClipLocationName(null);
                  setClipAreaName(null);
                  setIsClipDeleted(false);
                  setIsClipShared(false);
                  setIsClipAttachedToIncident(false);
                  setCurrentClip(null);
                  setShowDeleteClipModal(false);
                }}
              >
                {constants.CLIP_DETAILS_DELETE_CONFIRMATION_CANCEL_BUTTON_LABEL}
              </PrimaryButton>
            </div>
          </div>
        )}
      </SiteModal>
    </div>
  );
};

export default ClipsList;
