import React, { useEffect, useRef, useState } from 'react';
import 'moment-timezone';
import '../../assets/css/timeline.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLiveStream,
  getRemoteStreamAudio,
  getStreamBitmapData,
  setIsRemoteStreamPlay,
  setStreamLoader,
} from '../../store/reducers/StreamingReducer';
// import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import useEventsStore from '../../store/EventsStore';
import { XCircleFill } from 'react-bootstrap-icons';
import RegionSelect from 'react-region-select';
import store from '../../store/Store';
import { getAllDevicesData } from '../../store/AccountStoreIDB';
import { Utils } from '../../helpers';

const PlaybackControlsView = ({ muteAudio, showFetchImageGrid, hideFetchImageGrid, callCountAPI, deviceId }) => {
  const remoteStream = useSelector(getLiveStream);
  const remoteStreamAudio = useSelector(getRemoteStreamAudio);
  const dispatch = useDispatch();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  // Grid related state
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegions] = useState();
  const [finalCoordinates, setFinalCoordinates] = useState({});
  const [isRegionChanging, setIsRegionChanging] = useState(true);
  const [videoWidth, setVideoWidth] = useState();
  const [refResolution, setRefResolution] = useState();
  const {
    setSnapshotCoordinate,
    setSelectedRegion,
    getSelectedRegion,
    setRegion,
    getRegion,
  } = useEventsStore();
  const bitmapObject = store.getState(getStreamBitmapData)?.streaming?.bitmapObject;
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);

  useEffect(() => {
    dispatch(setIsRemoteStreamPlay(false));
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };
    fetchDevices();
  }, []);

  useEffect(() => {
    const setStreamPlay = () => {
      dispatch(setIsRemoteStreamPlay(true));
    };
    const handleFirstFrame = () => {
      dispatch(setStreamLoader(false));
    }
    if (remoteStream?.active) {
      const remoteVideo = remoteVideoRef.current;
      remoteVideo.srcObject = remoteStream;
      remoteVideoRef?.current?.addEventListener('play', setStreamPlay);
      remoteVideoRef?.current?.addEventListener('canplay', handleFirstFrame);
    }
    return () => {
      remoteVideoRef?.current?.removeEventListener('play', setStreamPlay);
      remoteVideoRef?.current?.removeEventListener('canplay', handleFirstFrame);
    };
  }, [remoteStream]);

  useEffect(() => {
    try {
      if (remoteStreamAudio && remoteStreamAudio !== null) {
        const remoteAudio = remoteAudioRef.current;
        if (remoteAudio) {
          remoteAudio.srcObject = remoteStreamAudio;
        }
      }
    } catch (error) {
      Utils.vmsLogger().log("Playback: Remote stream audio error", error);
    }
  }, [remoteStreamAudio]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (selectedRegion && refResolution) {
      const divResolutionWidth =
        document.getElementById('canvas2').offsetWidth;
      const divResolutionHeight =
        document.getElementById('canvas2').offsetHeight;
      const proportionWidth = parseInt(refResolution[0]) / divResolutionWidth; // guess ref resolution is 2592 x 1520
      const proportionHeight = parseInt(refResolution[1]) / divResolutionHeight; // guess ref resolution is 2592 x 1520

      const bottomLeftX =
        proportionWidth * ((divResolutionWidth / 100) * selectedRegion[0].x);
      const bottomLeftY =
        proportionHeight *
        ((divResolutionHeight / 100) *
          (selectedRegion[0].y + selectedRegion[0].height));
      const topRightX =
        proportionWidth *
        ((divResolutionWidth / 100) *
          (selectedRegion[0].x + selectedRegion[0].width));
      const topRightY =
        proportionHeight * ((divResolutionHeight / 100) * selectedRegion[0].y);

      const finalCoordinateObj = {
        bottomLeftX,
        bottomLeftY,
        topRightX,
        topRightY,
      };
      setFinalCoordinates(finalCoordinateObj);
    }
  }, [selectedRegion, videoWidth]);

  useEffect(() => {
    if (!isRegionChanging && Object.keys(finalCoordinates).length > 0) {
      setSnapshotCoordinate(finalCoordinates);
      callCountAPI();
    } else {
      if (!isRegionChanging) {
        callCountAPI();
      }
    }
  }, [isRegionChanging, finalCoordinates]);

  useEffect(() => {
    if (deviceId && devicesDetails.length) {
      const selectedChildDevice = devicesDetails.find(
        (device) => device?.deviceId === deviceId
      );
      if (selectedChildDevice) {
        const referenceResolution =
          selectedChildDevice?.properties?.['reference-resolution'];
        if (referenceResolution) {
          setRefResolution(referenceResolution.split('x'));
        }
      }
    }
  }, [devicesDetails]);

  useEffect(() => {
    if (showFetchImageGrid === false) {
      setSelectedRegions(null);
      setRegions([]);
      setFinalCoordinates({});
    } else {
      const ele = document.getElementById('canvas2');
      if (ele || remoteStream?.active) {
        const divResolutionWidth = ele.offsetWidth;
        setVideoWidth(divResolutionWidth);
      }
    }
  }, [showFetchImageGrid, remoteStream]);

  const handleWindowSizeChange = () => {
    try {
      const divElement = document.getElementById('canvas2');
      if (divElement) {
        const divResolutionWidth = divElement.offsetWidth;
        setVideoWidth(divResolutionWidth);
      }
    } catch (err) {
      Utils.vmsLogger().log(err)
    }
  };

  const resetObjects = () => {
    hideFetchImageGrid();
    setSelectedRegions(null);
    setSelectedRegion(null);
    setRegions([]);
    setRegion([]);
    setFinalCoordinates({});
    setSnapshotCoordinate({});
  };

  const onChange = (regions) => {
    setRegions(regions);
    setSelectedRegions(regions);
    setSelectedRegion(regions);
    setRegion(regions);
    handleRemoveSelected();
  };

  const handleRemoveSelected = () => {
    if (selectedRegion) {
      const updatedRegions = regions?.filter(
        (region) => region !== selectedRegion
      );
      setRegions(updatedRegions);
      setSelectedRegions(null);
    }
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      if (isRegionChanging) {
        setIsRegionChanging(false);
      }
      return (
        <div class="iconset">
          <XCircleFill
            size={24}
            onClick={resetObjects}
            style={{ cursor: 'pointer' }}
          />
        </div>
      );
    } else {
      setIsRegionChanging(true);
    }
  };

  return (
    remoteStream?.active && (
      <div className="remote-view-wrapper">
        <video
          id="video2"
          // width="764"
          // height="450"
          ref={remoteVideoRef}
          autoPlay={true}
          playsInline={true}
          muted={muteAudio}
        />
        <audio
          id="audio"
          ref={remoteAudioRef}
          autoPlay={true}
          playsInline={true}
          controls={false}
          muted={muteAudio}
        ></audio>
        <canvas 
          id="canvas2" 
          width={bitmapObjectWidth}
          height={bitmapObjectHeight}
        ></canvas>

        {showFetchImageGrid && (
          <div className="region-select-main">
            <RegionSelect
              id="region-select"
              maxRegions={1}
              regions={getRegion()}
              selectedRegion={getSelectedRegion()}
              regionStyle={{
                background: '#ffffff70',
                zIndex: 70,
              }}
              onChange={onChange}
              regionRenderer={regionRenderer}
              style={{
                border: '0px solid black',
                position: 'absolute',
                width: videoWidth,
                height: '-webkit-fill-available',
                display: 'inline-block',
              }}
              constraint={true}
            >
              <div class="grid-image-fetch"></div>
            </RegionSelect>
          </div>
        )}
      </div>
    )
  );
};

export default PlaybackControlsView;
