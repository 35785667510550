import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField, TimePicker } from '@mui/x-date-pickers';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';

import moment from 'moment';
import dayjs from 'dayjs';

import { TextBlock } from '../../../components/common';
import { AppDefaults, constants, Utils } from '../../../helpers';

import { HiOutlineExclamationCircle } from 'react-icons/hi';

import './CloseIncident.scss';

const CloseIncident = ({
  incidentId,
  timezone,
  resolution,
  fetchTimezoneLocation,
  selectedIncidentDate,
  updateIncidentDate,
  updateIncidentDateTime,
  handleUpdateInputChange,
  handleUpdateIncidentDateChange,
  setUpdateIncidentDateTime,
  openModal,
  ...props
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [finalAnalysis, setFinalAnalysis] = useState('');
  const [notesNumCharacters, setNotesNumCharacters] = useState(0);
  const [maxNumCharsReached, setMaxNumCharsReached] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const updateIncidentHandler = async () => {
    // Do nothing
  };

  return (
    <>
      {!formSubmitted && (
        <form onSubmit={handleSubmit(updateIncidentHandler)}>
          <Row>
            <Col>
              <div className="mb-3 close-incident-modal-description">
                {constants.INCIDENTS_CLOSE_INCIDENT_MODAL_DESCRIPTION}
              </div>
            </Col>
          </Row>
          {/* Status */}
          <Row>
            <Col>
              <div className="mb-2 close-incident-label close-incident-status">
                {constants.INCIDENTS_DETAILS_STATUS_TITLE}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="close-incident-status-col">
              <div className="mb-4 close-incident-status-wrapper">
                <RadioGroup
                  row
                  aria-labelledby="close-incident-form-control-label-placement"
                  name="resolution"
                  defaultValue={''}
                  className="status-radio-group"
                  onChange={(e) => {
                    if (!e) return;
                    handleUpdateInputChange(e);
                  }}
                >
                  <TextBlock className="d-flex justify-content-between align-items-center status-radio-row">
                    {
                      constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_RESOLVED
                    }{' '}
                    <Radio
                      value="resolved"
                      defaultChecked={false}
                      className="status-radio"
                    />
                  </TextBlock>
                  <TextBlock className="d-flex justify-content-between align-items-center status-radio-row">
                    {
                      constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_UNRESOLVED
                    }{' '}
                    <Radio
                      value="unresolved"
                      defaultChecked={false}
                      className="status-radio"
                    />
                  </TextBlock>
                </RadioGroup>
              </div>
            </Col>
          </Row>
          {/* Close Date */}
          <Row>
            <Col>
              <div className="mb-3 close-incident-label close-incident-date">
                {constants.INCIDENTS_CLOSE_INCIDENT_MODAL_CLOSE_DATE_TITLE}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 close-incident-date-wrapper">
                <DatePicker
                  className="close-incident-date customized-datepicker"
                  containerClassName="close-incident-date-container"
                  format={Utils.getDateFormatStringByLocale(navigator.language)}
                  placeholder="MM/DD/YYYY"
                  minDate={selectedIncidentDate}
                  value={updateIncidentDate}
                  onChange={(newDate) => {
                    handleUpdateIncidentDateChange(
                      moment(
                        `${newDate.month.number}/${newDate.day}/${newDate.year}`
                      ).valueOf()
                    );
                  }}
                  PopperProps={{
                    placement: 'bottom-end',
                  }}
                  render={<InputIcon className="close-incident-cal-input" />}
                />
              </div>
            </Col>
          </Row>
          {/* Close Time */}
          <Row>
            <Col>
              <div className="mb-1 close-incident-label  close-incident-time">
                {constants.INCIDENTS_CLOSE_INCIDENT_MODAL_CLOSE_TIME_TITLE}
              </div>
              <div className="mb-3 close-incident-sublabel close-incident-timezone">
                {constants.INCIDENTS_CLOSE_INCIDENT_MODAL_CLOSE_TIMEZONE_TEXT}{' '}
                {fetchTimezoneLocation(timezone)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 text-center  close-incident-time-container">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    {...props}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                      },
                    }}
                    renderInput={(inputProps) => (
                      <TimeField
                        helperText="Enter Time"
                        fullWidth={true}
                        hiddenLabel={true}
                      />
                    )}
                    defaultValue={dayjs(moment().format('YYYY-MM-DDTHH:mm'))}
                    disableFuture={false}
                    timeSteps={{ hours: 1, minutes: 1 }}
                    closeOnSelect={false}
                    onChange={(newValue) => {
                      if (!newValue) return;

                      setUpdateIncidentDateTime &&
                        setUpdateIncidentDateTime(
                          moment(newValue.$d).valueOf()
                        );
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Col>
          </Row>
          {/* Final Analysis */}
          <Row>
            <Col>
              <div className="mb-3 close-incident-label  close-incident-final-analysis">
                {constants.INCIDENTS_CLOSE_INCIDENT_MODAL_FINAL_ANALYSIS_TITLE}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 close-incident-note-container">
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={6}
                  name="note"
                  value={finalAnalysis}
                  defaultValue=""
                  placeholder="Notes"
                  onChange={(e) => {
                    e?.preventDefault();
                    let noteText = e?.target?.value || '';

                    if (
                      noteText.length <=
                      AppDefaults.INCIDENT_FINAL_ANALYSIS_MAX_CHARACTERS
                    ) {
                      setMaxNumCharsReached(false);
                      setNotesNumCharacters(noteText.length);
                      setFinalAnalysis(noteText);
                      handleUpdateInputChange && handleUpdateInputChange(e);
                    } else {
                      setMaxNumCharsReached(true);
                    }
                  }}
                  fullWidth
                  required
                />
                <div className="d-flex justify-content-between align-items-center close-incident-note-info">
                  <div className="note-alert">
                    <HiOutlineExclamationCircle
                      className="exclamation-icon"
                      size={16}
                    />
                    <span className="small-text">
                      {Utils.replaceStringValues(
                        constants.INCIDENT_DETAILS_CLOSE_INCIDENT_NOTES_NOTE_INFO,
                        '$maxCharacters',
                        AppDefaults.INCIDENT_FINAL_ANALYSIS_MAX_CHARACTERS
                      )}
                    </span>
                  </div>
                  <span
                    className={`small-text${
                      maxNumCharsReached ? ' error' : ''
                    }`}
                  >
                    {notesNumCharacters}/
                    {AppDefaults.INCIDENT_FINAL_ANALYSIS_MAX_CHARACTERS}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      )}
      {/* Modals */}
    </>
  );
};

export default CloseIncident;
