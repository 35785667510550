import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { constants } from '../../helpers';
import { Tooltip, tooltipClasses } from '@mui/material';
import '../../assets/css/base.scss';

const UsersListTable = ({ columns, data, defaultSortByData = [],handleClickEditUser }) => {
   // function to sort columns in case sensitive manner
   const tableColumns = React.useMemo(() => columns.map(column => ({
    ...column,
    sortType: (rowA, rowB, columnId) => {
      const valueA = rowA.values[columnId].toString().toLowerCase();
      const valueB = rowB.values[columnId].toString().toLowerCase();
      return valueA.localeCompare(valueB);
    },
  })), [columns]);

  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns:tableColumns,
        data,
        initialState: {
          sortBy: defaultSortByData,
        },
      },
      useSortBy
    );

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className="table-responsive manage-cust-table">
      <table className="table table-borderless" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={columnIndex}
                  className={`${column.isSorted
                      ? column.isSortedDesc
                        ? 'columnheader-asc'
                        : 'columnheader-desc'
                      : 'columnheader-asc'
                    }`}
                  onClick={() => {
                    column.toggleSortBy(!column.isSortedDesc, false);
                  }}
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                >
                  {column.render('Header')}
                  {<span>
                      {column.isSortedDesc ? (
                        <VscChevronDown size={16} strokeWidth="1px" />
                      ) : (
                        <VscChevronUp size={16} strokeWidth="1px" />
                      )}
                    </span>
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr onClick={()=>{handleClickEditUser(row.original?.accountId)}} key={rowIndex} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <>
                      {(cell.column.id === "activeDate" && cell.value !== 0) || (cell.column.id !== "activeDate" && cell.value !== undefined)  ? (
                        <Tooltip
                          placement='top'
                          arrow
                          title={cell.render('Cell')}
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -25],
                                  },
                                },
                              ],
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  fontSize: 12,
                                  backgroundColor: `var(--overlay)`,
                                  padding: '10px'
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: `var(--overlay)`
                                },
                              }
                            },
                          }}
                        >
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        </Tooltip>
                      ) : (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )}
                    </>
                  );
                })}
              </tr>
            );
          })}
          {rows?.length === 0 && (
            <tr className="no-data-found">
              <td colSpan={columns?.length}>
                {constants.NO_RECORDS_FOUND_TITLE}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UsersListTable;
