import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  HiOutlineFaceSmile,
  HiOutlineUser,
  HiOutlineCreditCard,
} from 'react-icons/hi2';
import { HiOutlineVolumeUp } from 'react-icons/hi';
import { RiCloseFill } from 'react-icons/ri';
import { ReactComponent as VehicleIcon } from '../../assets/images/vehicle.svg';
import StickyBox from 'react-sticky-box';
import {
  DateTimePicker,
  Loader,
  PrimaryButton,
  SiteModal,
} from '../../components/common';
import InfiniteScroll from 'react-infinite-scroller';
import { AppDefaults, Utils, constants } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCDNInfo,
  getReloadAsFilterTriggered,
  setReloadAsFilterTriggered,
} from '../../store/reducers/StreamingReducer';
import { getDeviceInformation } from '../../store/reducers/AccountReducer';
import { filter, calender } from '../../assets/images';
import { grid } from '../../assets/images';
import { useForm } from 'react-hook-form';
import useEventsStore from '../../store/EventsStore';
import useFiltersStore from '../../store/FiltersStore';
import EventsWrapper from './components/EventsWrapper';
import './EventsList.scss';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
} from '../../store/reducers/EventsReducer';
import { ToggleInput } from '../../components/forms';
import { useRef } from 'react';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { MdOutlineCenterFocusWeak } from 'react-icons/md';

const EventsList = ({
  deviceId,
  listHeight,
  showFetchImageGrid,
  hideFetchImageGrid,
  showGridOnImage,
  digitalZoomScale,
  startDate,
  isLiveStream
}) => {
  const itemsPerPage = 20;
  const dispatch = useDispatch();
  const reloadAsFilterTriggered = useSelector(getReloadAsFilterTriggered);
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [eventsData, setEventsData] = useState([]);
  const [updateLiveEvents, setUpdateLiveEvents] = useState(true);
  const device = useSelector(getDeviceInformation);
  const cdnValue = useSelector(getCDNInfo);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [holdEventsData, setHoldEventsData] = useState([]);
  const [searchedTags, setSearchedTags] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateTimeData, setDateTimeData] = useState();
  const tagsListRef = useRef(null);
  const isInitialRender = useRef(true);
  const [showDateTimeModal, setShowDateTimeModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(
    new Date().setHours(0, 0, 0)
  );
  const [lastEventReceived, setLastEventReceived] = useState();
  const [apiCallCount, setAPICallCount] = useState(0);
  const { getSnapshotCoordinates } = useEventsStore();
  const initialTags = [
    {
      key: 'face',
      label: 'Search by Face',
      icon: <HiOutlineFaceSmile />,
      value: false,
      eventClasses: ['Face'],
    },
    {
      key: 'person',
      label: 'Search by Person',
      icon: <HiOutlineUser />,
      value: false,
      eventClasses: ['Person'],
    },
    {
      key: 'vehicle',
      label: 'Search by Vehicle',
      icon: <VehicleIcon height="16" width="16" />,
      value: false,
      eventClasses: ['Vehicle'],
    },
    {
      key: 'licensePlate',
      label: 'Search by License Plate',
      icon: <HiOutlineCreditCard />,
      value: false,
      eventClasses: ['LicensePlate'],
    },
    {
      key: 'sound',
      label: 'Search by Sound',
      icon: <HiOutlineVolumeUp />,
      value: false,
      eventClasses: ['scream', 'explosion', 'gun-shot', 'glass-breaking', 'audio-start'],
    },
    {
      key: 'Motion',
      label: 'Search by Motion',
      icon: <MdOutlineCenterFocusWeak style={{
        color: getComputedStyle(
          document.documentElement
        ).getPropertyValue('--primary_32')
      }} />,
      value: false,
      eventClasses: ['motion-start'],
    },
  ];
  const [allTags, setAllTags] = useState(initialTags);
  const [category, setCategory] = useState(['objectclass', 'soundrecognition', 'motion', 'audio']);
  const [showAppliedFilter, setShowAppliedFilter] = useState(false);
  const [allTimeMetaData, setAllTimeMetaData] = useState([]);

  const {
    removeEvents,
    setEventCategory,
    setSelectedEventStore,
    getSelectedEvent,
    getVideoFilteredDateObj,
  } = useEventsStore();
  const {
    getAppliedTags,
    getRemoveTagPayload,
    getTagList,
    setAppliedTags,
    setRemoveTagPayload,
    setTagList,
  } = useFiltersStore();
  const [filterDate, setFilterDate] = useState(new Date());
  const [timezone, setTimezone] = useState(null);
  const [isSearchByTag, setIsSearchByTag] = useState(false);
  const [isReload, setIsReload] = useState(false);

  const eventsLiveData = useSelector(getLatestEventsForDots);
  const deviceLiveMetaData = useSelector(getMetaDataForEvents);
  const videoFilteredDateObj = useSelector(getVideoFilteredDateObj);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { getSelectedLocation, setLocationSelection } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);
  let isRequestSent = false;
  const { handleSubmit, formState, reset } = useForm({
    defaultValues: { category: category, tags: allTags },
    mode: 'onChange',
  });

  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);

  const onSubmit = (data) => {
    setIsSearchByTag(false);
    storeAppliedTags(allTags);
    if (data) {
      setCategory(data?.category);
      setEventCategory(data?.category);
      setShowCategoryModal(false);
      dispatch(setReloadAsFilterTriggered(!reloadAsFilterTriggered));
    }
  };

  useEffect(() => {
    setLocationSelection(loggedInUserData?.accountId, AppDefaults.ALL_LOCATIONS_ID);
  }, [])

  useEffect(() => {
    const selectedEndTime = new Date().getTime();
    if (startDate) {
      axios.get(
        `timeline/device/${deviceId}/metadata?startTime=${startDate}&endTime=${selectedEndTime}`,
        Utils.requestHeader()
      ).then((res) => {
        const metaDataRes = res?.data;
        if (metaDataRes?.meta?.code === 200) {
          setAllTimeMetaData(metaDataRes?.data);
        }
      });
    }
  }, [startDate, getAppliedTags(), deviceId, JSON.stringify(videoFilteredDateObj), isLiveStream]);

  useEffect(() => {
    const filters = getAppliedTags();
    const updatedTags = allTags.map((tag) => {
      if (filters.includes(tag.key)) {
        return { ...tag, value: true };
      }
      return tag;
    });
    removeEvents();
    setEventsData([]);
    if(allTimeMetaData?.length > 0) {
      const timeSelected = moment(new Date(videoFilteredDateObj?.filterdate)).format('YYYY/MM/DD hh:mm:ss A')
      const endTime = timezone ? new Date(moment.tz(new Date(timeSelected), timezone)).getTime() :  new Date(videoFilteredDateObj?.filterdate).getTime();
      if(!isRequestSent) {
        fetchRecords(!isLiveStream || getAppliedTags() !== '' ? endTime : null);
      }
    }
    setAllTags(updatedTags);
    const length = updatedTags?.filter((tag) => tag.value).length;
    setShowAppliedFilter(length > 0);
  }, [
    JSON.stringify(allTimeMetaData),
    getAppliedTags(),
    device?.orgId,
    deviceId,
    JSON.stringify(getSnapshotCoordinates()),
  ]);

  useEffect(() => {
    if(isLiveStream && allTimeMetaData?.length > 0) {
      if(!isRequestSent) {
        fetchRecords();
      }
    }
  }, [isLiveStream])

  useEffect(() => {
    if (!isInitialRender.current) {
      if(allTimeMetaData?.length > 0) {
        if(!isRequestSent) {
          fetchRecords(allTimeMetaData[allTimeMetaData.length - 1].end, true);
        }
      }
    } else {
      isInitialRender.current = false;
    }
  }, [isReload]);

  useEffect(() => {
    if (Array.isArray(eventsLiveData)) {
      const output = eventsLiveData?.map((item) => ({
        eventMeta: item.eventMeta,
        deviceId: item.src?.srcId,
        eventTimestamp: Number(item.t),
      }));
      setHoldEventsData([...holdEventsData, ...output]);
    }
  }, [JSON.stringify(eventsLiveData)]);

  useEffect(() => {
    if (deviceLiveMetaData !== null && updateLiveEvents) {
      const newObj = {
        start: deviceLiveMetaData.start * 1000,
        end: deviceLiveMetaData.end * 1000,
      };
      const newArray = getAgesWithGreaterDifference(
        holdEventsData,
        newObj?.end
      );
      if(newArray.length > 0){
        const filteredData = holdEventsData.filter(
          (data) => !newArray.some(
            (arr) => arr.eventTimestamp === data.eventTimestamp || data.eventTimestamp <= newObj?.end
          )
        );
        setHoldEventsData(filteredData);
        if (newArray[0]?.deviceId) {
          setEventsData([...eventsData, ...newArray]);
        }
      }
    }
  }, [JSON.stringify(deviceLiveMetaData)]);

  const getAgesWithGreaterDifference = (arr, endTime) => {
    // Sort the array based on age in ascending order
    arr.sort((a, b) => b.eventTimestamp - a.eventTimestamp);
    // Initialize the result array with the first object
    const result = [];
    // Iterate over the remaining objects
    for (let i = 1; i < arr.length; i++) {
      if (result.length === 0 && arr[i].eventTimestamp <= endTime) {
        result.push(arr[i]);
      } else {
        const currentAge = arr[i].eventTimestamp;
        if (currentAge <= endTime) {
          result.push(arr[i]);
        }
      }
    }
    return result;
  };

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  useEffect(() => {
    handleDateChange(videoFilteredDateObj);
  }, [videoFilteredDateObj]);

  useEffect(() => {
    if(allTimeMetaData?.length > 0) {
      if(!isRequestSent) fetchRecords();
    }
  }, [selectedLocation, JSON.stringify(allTimeMetaData)]);

  const getTimeZoneFromLocation = () => {
    let timeZone = 'UTC0';
    if (selectedLocation?.locationId === AppDefaults.ALL_LOCATIONS_ID) {
      if (selectedLocation?.filteredLocationData?.length === 1) {
        timeZone = selectedLocation?.filteredLocationData[0]?.timezone;
      }
    } else {
      timeZone = selectedLocation?.filteredLocationData[0]?.timezone;
    }
    return timeZone;
  };

  const fetchRecords = async (endTime, isFreashCall = false) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    let filters = getAppliedTags();
    const filterList = filters ? filters.split(',') : [];
    const soundTags = `${constants.SEARCH_LABEL_GUN_SHOT}, ${constants.SEARCH_LABEL_SCREAM}, ${constants.SEARCH_LABEL_EXPLOSION}, ${constants.SEARCH_LABEL_GLASS_BREAK}, ${constants.SEARCH_LABEL_AUDIO_START}`;

    if (filters.includes(constants.SEARCH_LABEL_SOUND)) {
      filters = filters.replace(constants.SEARCH_LABEL_SOUND, soundTags);
    }
    if (filterList.length === 0) {
      setIsSearchByTag(false);
    }
    
    if (allTimeMetaData) {
      isRequestSent = true;
      const selectedEndTime = endTime ? endTime : allTimeMetaData?.[allTimeMetaData.length - 1]?.end;
      if (isSearchByTag && filterList.length > 0) {
        let response = await axios.post(
          `timeline/orgs/${device?.orgId}/events/searchByTag/v2`,
          {
            ...getRemoveTagPayload(),
            startTime: Utils.getUnixDate(new Date(moment(new Date()).subtract({ days: 90 })) * 1000),
            endTime: selectedEndTime,
            size: itemsPerPage
          },
          Utils.requestHeader()
        );
        const responseData = response?.data;
        if (responseData?.meta?.code === 200) {
          isRequestSent = false;
          const searchByTagAPIEvents = response?.data?.data?.events;
          const filteredEventsWithMeta = [];
          if (searchByTagAPIEvents?.length > 0) {
            searchByTagAPIEvents?.forEach((searchTagEvent) => {
              const findEventInRange = binarySearchForEvents(searchTagEvent?.eventTimestamp, allTimeMetaData);
              if (findEventInRange !== -1) {
                filteredEventsWithMeta.push(searchTagEvent);
              }
            });
          }
          if (
            response?.data?.data?.events?.[response?.data?.data?.events?.length - 1]?.eventTimestamp === lastEventReceived ||
            filteredEventsWithMeta?.[filteredEventsWithMeta?.length - 1]?.eventTimestamp === lastEventReceived || 
            response?.data?.data?.events?.length === 0
          ) { // this conditions stops infinite loop of load more function when there is no data received or events are there but no metadata available.
            let count = apiCallCount;
            setAPICallCount(count + 1);
          } else {
            if (apiCallCount !== 0) setAPICallCount(0);
          }
          setLastEventReceived(response?.data?.data?.events[response?.data?.data?.events.length - 1]?.eventTimestamp);
          setEventsData(
            endTime && !isFreashCall
              ? [...eventsData, ...filteredEventsWithMeta]
              : filteredEventsWithMeta
          );
        }
        isRequestSent = false;
        setIsLoading(false);
      } else {
        if (
          (filters && filterList.length > 0) ||
          Object.keys(getSnapshotCoordinates()).length > 0
        ) {
          const response = await axios.get(
            `timeline/orgs/${device?.orgId
            }/events/search?endTime=${selectedEndTime}&deviceIds=${deviceId}&size=${itemsPerPage}&timezone=${getTimeZoneFromLocation()}&searchText=${filters}&tags=&bottomLeftX=${getSnapshotCoordinates()?.bottomLeftX ?? ''
            }&bottomLeftY=${getSnapshotCoordinates()?.bottomLeftY ?? ''
            }&topRightX=${getSnapshotCoordinates()?.topRightX ?? ''}&topRightY=${getSnapshotCoordinates()?.topRightY ?? ''
            }`,
            Utils.requestHeader()
          );
          const responseData = response?.data;
          if (responseData?.meta?.code === 200) {
            isRequestSent = false;
            const searchAPIEvents = response?.data?.data?.events;
            const filteredEventsWithMeta = [];
            if (searchAPIEvents?.length > 0) {
              searchAPIEvents?.forEach((searchEvent) => {
                const findEventInRange = binarySearchForEvents(searchEvent?.eventTimestamp, allTimeMetaData);
                if (findEventInRange !== -1) {
                  filteredEventsWithMeta.push(searchEvent);
                }
              });
            }
            if (
              response?.data?.data?.events?.[response?.data?.data?.events?.length - 1]?.eventTimestamp === lastEventReceived ||
              filteredEventsWithMeta?.[filteredEventsWithMeta?.length - 1]?.eventTimestamp === lastEventReceived || 
              response?.data?.data?.events?.length === 0
            ) { // this conditions stops infinite loop of load more function when there is no data received or events are there but no metadata available.
              let count = apiCallCount;
              setAPICallCount(count + 1);
            } else {
              if (apiCallCount !== 0) setAPICallCount(0);
            }
            setLastEventReceived(response?.data?.data?.events[response?.data?.data?.events.length - 1]?.eventTimestamp);
            setEventsData(
              endTime && !isFreashCall
                ? [...eventsData, ...filteredEventsWithMeta]
                : filteredEventsWithMeta
            );
            const tagList = responseData?.data?.tagDetails || [];
            const result = filterList.reduce((accumulator, key) => {
              accumulator[key] = [];
              return accumulator;
            }, {});
            tagList?.forEach(({ category, text }) => {
              if (result[category]) {
                result[category].push(text);
              }
            });
            setTagList(tagList);
            setSearchedTags(result);
          }
          isRequestSent = false;
          setIsLoading(false);
        } else {
          setSearchedTags(null);
          const eventClasses = getEventClasses();
          if (device?.orgId && deviceId) {
            axios
              .get(
                `timeline/orgs/${device?.orgId}/events/all?endTime=${selectedEndTime}&size=${itemsPerPage}&deviceIds=${deviceId}${eventClasses}&ascOrder=false`,
                Utils.requestHeader()
              )
              .then((response) => {
                isRequestSent = false;
                if (response?.data?.data) {
                  const allAPIEvents = response?.data?.data?.events;
                  const filteredEventsWithMeta = [];
                  if (allAPIEvents?.length > 0) {
                    allAPIEvents?.forEach((allEvents) => {
                      const findEventInRange = binarySearchForEvents(allEvents?.eventTimestamp, allTimeMetaData);
                      if(findEventInRange !== -1) {
                        filteredEventsWithMeta.push(allEvents);
                      }
                    });
                  }
                  if (
                    response?.data?.data?.events?.[response?.data?.data?.events?.length - 1]?.eventTimestamp === lastEventReceived ||
                    filteredEventsWithMeta?.[filteredEventsWithMeta?.length - 1]?.eventTimestamp === lastEventReceived || 
                    response?.data?.data?.events?.length === 0
                  ) { // this conditions stops infinite loop of load more function when there is no data received or events are there but no metadata available.
                    let count = apiCallCount;
                    setAPICallCount(count + 1);
                  } else {
                    if (apiCallCount !== 0) setAPICallCount(0);
                  }
                  setLastEventReceived(response?.data?.data?.events[response?.data?.data?.events.length - 1]?.eventTimestamp);
                  setEventsData(
                    endTime && !isFreashCall
                      ? [...eventsData, ...filteredEventsWithMeta]
                      : filteredEventsWithMeta
                  );
                }
                setTagList([]);
                setIsLoading(false);
              }).catch((error) => {
                isRequestSent = false;
              });
          }
        }
      }
    }
  };

  const binarySearchForEvents = (timestamp, metaArray) => {
    let start = 0;
    let end = metaArray.length - 1;

    while (start <= end) {
      let middle = Math.floor((start + end) / 2);

      if (timestamp >= metaArray[middle].start && timestamp <= metaArray[middle].end) {
        return timestamp;
      } else if (metaArray[middle].end < timestamp) {
        start = middle + 1;
      } else {
        end = middle - 1;
      }
    }
    return -1;
  }

  const getEventClasses = (filters) => {
    const selectedTags =
      filters?.length > 0
        ? initialTags.filter((tag) => filters.includes(tag.key))
        : initialTags;
    const eventClassesString = selectedTags
      .map((item) =>
        item.eventClasses.map((eventClass) => `&eventClasses=${eventClass}`)
      )
      .flat()
      .join('');
    return eventClassesString;
  };

  const massagedData = (data) => {
    const finalArray = [];
    data.forEach((event) => {
      const time = getActualDate(event?.eventTimestamp);
      const index = finalArray.findIndex((e) => e.eventTimestamp === time);
      if (index > -1) {
        finalArray[index]?.events.push({ ...event });
      } else {
        const eventObject = {
          eventTimestamp: '',
          events: [],
        };
        eventObject.eventTimestamp = time;
        eventObject.events.push(event);
        finalArray.push(eventObject);
      }
    });
    return finalArray;
  };

  const formattedDate = (time) => {
    return time;
  };

  const handleFilterClick = () => {
    setShowCategoryModal(true);
  };

  const getIconAndLabel = (type) => {
    const icon = initialTags.find((tag) => tag.key === type)?.icon;
    return icon ? (
      <>
        <span className="tag-icon">{icon}</span>
        <span className="tag-label">{type}</span>
      </>
    ) : (
      ''
    );
  };

  const searchCategoryByName = (category, tagListText) => {
    return tagListText.includes(category) || false;
  };

  const getSelectedBody = (list) => {
    return (
      list?.filter((data) => data.isSelectedBody).map((data) => data.name) || []
    );
  };

  const getFilteredTagList = (item) => {
    let tagList = [...getTagList()];
    return tagList?.filter((tag) => tag.text !== item) || [];
  };

  const getCategoryStatus = (tagListText) => {
    const categoryStatus = {};
    const categoriesToSearch = [
      Utils.getCategoryName().PERSON,
      Utils.getCategoryName().FACE,
      Utils.getCategoryName().VEHICLE,
      Utils.getCategoryName().LICENSEPLATE,
      Utils.getCategoryName().SOUND,
      Utils.getCategoryText().YOUNG,
      Utils.getCategoryText().MIDDLE,
      Utils.getCategoryText().ADUlT,
      Utils.getCategoryText().SENIOR,
      Utils.getCategoryText().OPTICAL,
      Utils.getCategoryText().MASK,
      Utils.getCategoryText().MALE,
      Utils.getCategoryText().FEMALE,
      Utils.getCategoryText().HAT,
      Utils.getCategoryText().BAG,
      Utils.getCategoryText().CAR,
      Utils.getCategoryText().TRUCK,
      Utils.getCategoryText().MOTERCYCLE,
      Utils.getCategoryText().BICYCLE,
      Utils.getCategoryText().BUS,
      Utils.getCategoryText().GLASSBREAK,
      Utils.getCategoryText().GUNSHOT,
      Utils.getCategoryText().EXPLOSION,
      Utils.getCategoryText().SCREAM,
      Utils.getCategoryText().AUDIO_START
    ];

    for (const category of categoriesToSearch) {
      categoryStatus[category] = searchCategoryByName(category, tagListText);
    }

    return categoryStatus;
  };

  const getColorListStatus = (tagListText) => {
    return {
      topColorListStatus: Utils.getCommonColor(tagListText, 'top', 2),
      bottomColorListStatus: Utils.getCommonColor(tagListText, 'bottom', 1),
      vehicleColorListStatus: Utils.getCommonColor(
        tagListText,
        'vehicletype',
        3
      ),
    };
  };

  const setPersonTags = (
    tags,
    categoryStatus,
    topColorListStatus,
    bottomColorListStatus
  ) => {
    const person = {};
    const gender = [];
    const accessories = [];

    if (categoryStatus[Utils.getCategoryText().MALE]) {
      gender.push('male');
    }
    if (categoryStatus[Utils.getCategoryText().FEMALE]) {
      gender.push('female');
    }
    if (categoryStatus[Utils.getCategoryText().HAT]) {
      accessories.push('hat');
    }
    if (categoryStatus[Utils.getCategoryText().BAG]) {
      accessories.push('bag');
    }

    if (gender.length > 0) {
      person.genders = gender;
    }
    if (accessories.length > 0) {
      person.accessories = accessories;
    }
    if (getSelectedBody(bottomColorListStatus).length > 0) {
      person.pantsColors = getSelectedBody(bottomColorListStatus);
    }
    if (getSelectedBody(topColorListStatus).length > 0) {
      person.shirtsColors = getSelectedBody(topColorListStatus);
    }
    tags.person = person;
  };

  const setFaceTags = (tags, categoryStatus) => {
    const face = {};
    const accessories = [];
    const age = [];

    if (categoryStatus[Utils.getCategoryText().OPTICAL]) {
      accessories.push('glasses');
    }
    if (categoryStatus[Utils.getCategoryText().MASK]) {
      accessories.push('mask');
    }

    if (accessories.length > 0) {
      face.accessories = accessories;
    }
    if (categoryStatus[Utils.getCategoryText().YOUNG]) {
      age.push('young');
    }
    if (categoryStatus[Utils.getCategoryText().ADUlT]) {
      age.push('adult');
    }
    if (categoryStatus[Utils.getCategoryText().MIDDLE]) {
      age.push('middle');
    }
    if (categoryStatus[Utils.getCategoryText().SENIOR]) {
      age.push('senior');
    }

    if (age.length > 0) {
      face.ages = age;
    }
    tags.face = face;
  };

  const setVehicleTags = (tags, categoryStatus, vehicleColorListStatus) => {
    const vehicle = {};

    if (!categoryStatus[Utils.getCategoryName().LICENSEPLATE]) {
      const type = [];
      if (categoryStatus[Utils.getCategoryText().CAR]) {
        type.push('car');
      }
      if (categoryStatus[Utils.getCategoryText().BUS]) {
        type.push('bus');
      }
      if (categoryStatus[Utils.getCategoryText().TRUCK]) {
        type.push('truck');
      }
      if (categoryStatus[Utils.getCategoryText().BICYCLE]) {
        type.push('bicycle');
      }
      if (categoryStatus[Utils.getCategoryText().MOTERCYCLE]) {
        type.push('motorcycle');
      }
      if (getSelectedBody(vehicleColorListStatus).length > 0) {
        vehicle.extColors = getSelectedBody(vehicleColorListStatus);
      }

      if (type.length > 0) {
        vehicle.types = type;
      }
      tags.vehicle = vehicle;
    }
  };

  const setSoundsTags = (tags, categoryStatus) => {
    const types = [];

    if (categoryStatus[Utils.getCategoryText().EXPLOSION]) {
      types.push(Utils.getCategoryText().EXPLOSION);
    }
    if (categoryStatus[Utils.getCategoryText().GUNSHOT]) {
      types.push(Utils.getCategoryText().GUNSHOT);
    }
    if (categoryStatus[Utils.getCategoryText().SCREAM]) {
      types.push(Utils.getCategoryText().SCREAM);
    }
    if (categoryStatus[Utils.getCategoryText().GLASSBREAK]) {
      types.push(Utils.getCategoryText().GLASSBREAK);
    }
    if (categoryStatus[Utils.getCategoryText().AUDIO_START]) {
      types.push(Utils.getCategoryText().AUDIO_START);
    }

    tags.sound = {types};
  }

  const removeTag = (key, item) => {
    const tagList = getFilteredTagList(item);
    const tagListText = tagList.map((data) => data.text);
    const categoryStatus = getCategoryStatus(tagListText);
    const {
      topColorListStatus,
      bottomColorListStatus,
      vehicleColorListStatus,
    } = getColorListStatus(tagListText);

    const tags = {};
    if (
      categoryStatus[Utils.getCategoryName().PERSON] ||
      categoryStatus[Utils.getCategoryName().FACE]
    ) {
      if (!categoryStatus[Utils.getCategoryName().FACE]) {
        setPersonTags(
          tags,
          categoryStatus,
          topColorListStatus,
          bottomColorListStatus
        );
      } else {
        setFaceTags(tags, categoryStatus);
      }
    }

    if (categoryStatus[Utils.getCategoryName().VEHICLE]) {
      setVehicleTags(tags, categoryStatus, vehicleColorListStatus);
      tags.licensePlate = categoryStatus[Utils.getCategoryName().LICENSEPLATE];
    }

    if (
      categoryStatus[Utils.getCategoryName().SOUND] ||
      categoryStatus[Utils.getCategoryText().SCREAM] ||
      categoryStatus[Utils.getCategoryText().EXPLOSION] ||
      categoryStatus[Utils.getCategoryText().GLASSBREAK] ||
      categoryStatus[Utils.getCategoryText().GUNSHOT] ||
      categoryStatus[Utils.getCategoryText().AUDIO_START]
    ) {
      setSoundsTags(tags, categoryStatus);
    }

    setIsSearchByTag(true);
    setTagList(tagList);
    const updatedTags = allTags.map((tag) =>
      tag.key === key
        ? {
            ...tag,
            value: tagList.filter((tag) => tag.category === key).length > 0,
          }
        : tag
    );
    setAllTags(updatedTags);
    
    let filters = getAppliedTags();
    const filterList = filters ? filters.split(',') : [];
    let updatedSearchTags = filterList.reduce((accumulator, key) => {
      accumulator[key] = [];
      return accumulator;
    }, {});
    tagList?.forEach(({ category, text }) => {
      if (updatedSearchTags[category]) {
        updatedSearchTags[category].push(text);
      }
    });
    // remove tag if searched tag length is 0
    Object.keys(updatedSearchTags).forEach((key) => {
      if(key !== 'licensePlate' && updatedSearchTags[key].length <= 0) {
        delete updatedSearchTags[key];
      }
    });

    setSearchedTags(updatedSearchTags);

    const tagsParent = {
      deviceIds: [deviceId],
      tags: tags,
    };
    setRemoveTagPayload(tagsParent);
    storeAppliedTags(updatedTags);

    if (
      allTags.find((tag) => tag.key === key).value ===
      updatedTags.find((tag) => tag.key === key).value
    ) {
      setIsReload(!isReload);
    }
  };

  const handleRemoveTagClick = (key, tag) => {
    removeTag(key, tag);
  };

  const storeAppliedTags = async (tagList) => {
    const filters = tagList
      .filter((tag) => tag.value)
      .map((tag) => tag.key)
      .join(',');
    await setAppliedTags(filters);
    if (filters?.length === 0) {
      setSelectedDate(new Date(new Date().getTime()));
      setSelectedTime(allTimeMetaData?.[allTimeMetaData - 1]?.end ?? new Date());
    }
  };

  const handleCloseClick = (key) => {
    const updatedTags = allTags.map((tag) =>
      tag.key === key
        ? {
            ...tag,
            value: false,
          }
        : tag
    );
    const updateSearchTag = searchedTags;
    if(updateSearchTag[key])
      delete updateSearchTag[key];

    setSearchedTags(updateSearchTag);
    setAllTags(updatedTags);
    storeAppliedTags(updatedTags);
  };

  const showItems = (posts) => {
    const updatedData = massagedData(posts);
    if (
      updatedData.length &&
      updatedData[0].eventTimestamp !== 'Invalid date'
    ) {
      return (
        <div key={`items-${deviceId}`} className="events-list">
          {updatedData?.map((event, i) => (
            <div
              key={`${deviceId}-${i}-${category}-${event?.eventTimestamp}`}
              className="events-all-category-area"
            >
              <StickyBox className="sticky-area" offsetTop={0}>
                <div className="sticky-header">
                  <div className="header">
                    {event?.eventTimestamp !== 'Invalid date'
                      ? formattedDate(event?.eventTimestamp)
                      : ''}
                  </div>
                </div>
              </StickyBox>
              {searchedTags && Object.entries(searchedTags).length > 0 && (
                <div ref={tagsListRef} className="tag-list-container">
                  {Object.entries(searchedTags).map(([key, values]) => (
                    <div className="tag-details" key={key}>
                      {getIconAndLabel(key)}
                      {values.map((item) => (
                        <span key={item} className="tag-item">
                          {item}
                          <RiCloseFill
                            className="cross-icon"
                            onClick={() => handleRemoveTagClick(key, item)}
                          />
                        </span>
                      ))}
                      <RiCloseFill
                        className="cross-icon"
                        onClick={() => handleCloseClick(key)}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="events-all-category-wrapper">
                <EventsWrapper
                  data={event}
                  category={category}
                  deviceId={deviceId}
                  cdnValue={cdnValue}
                  timezone={timezone}
                  selectedEvent={getSelectedEvent()}
                  handleOnClick={(timestamp) => {
                    setSelectedEventStore(timestamp);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  const loadMore = () => {
    if (isLoading) {
      return;
    }
    if (
      eventsData[eventsData.length - 1]?.eventTimestamp <
      Utils.getUnixDate(moment(new Date()).subtract({ days: 30 })) * 1000
    ) {
      sethasMoreItems(false);
    } else {
      if (
        eventsData[eventsData.length - 1]?.eventTimestamp
      ) {
        if (allTimeMetaData?.length > 0) {
          const isMetaFound = [];
          allTimeMetaData?.forEach((meta) => {
            if (lastEventReceived && lastEventReceived >= meta.start && lastEventReceived <= meta.end) {
              isMetaFound.push(meta)
            }
          });
          let newTimestamp = '';
          if (isMetaFound.length === 0) {
            // if no metadata it will find from where the metadata is available and it should available for more than 20 seconds.
            const newMeta = allTimeMetaData.filter(
              (meta) =>
                meta.end < lastEventReceived &&
                meta.end - meta.start > 20
            );
            if (newMeta?.length > 0) {
              newTimestamp = newMeta[newMeta?.length - 1]?.end;
              setLastEventReceived(newTimestamp);
            }
          }
          if(apiCallCount !== 3 && !isRequestSent) {
            fetchRecords(newTimestamp !== '' ? newTimestamp : eventsData[eventsData.length - 1]?.eventTimestamp, false);
          }
        }
      }
    }
  };

  const getActualDate = (date) => {
    const time = Utils.getDate(date / 1000);
    const actualTime = moment
      .tz(moment(time), deviceTimezone)
      .format('MMM DD, YYYY');
    return actualTime;
  };

  const handleTagChange = (key, value) => {
    const updatedTags = allTags.map((tag) =>
      tag.key === key
        ? {
            ...tag,
            value: value,
          }
        : tag
    );
    setAllTags(updatedTags);
  };

  useEffect(() => {
    if (dateTimeData?.selectedDate && dateTimeData?.selectedTime) {
      setSelectedDate(dateTimeData?.selectedDate);
      setSelectedTime(dateTimeData?.selectedTime);
    }
  }, [JSON.stringify(dateTimeData)]);

  const handleDateChange = (data) => {
    setShowDateTimeModal(false);
    let date = data.filterdate;
    setDateTimeData(data);
    setFilterDate(date);
    if (
      moment(new Date(date)).format('yyyy-MM-dd') !==
      moment(new Date()).format('yyyy-MM-dd')
    ) {
      setUpdateLiveEvents(false);
    } else {
      setUpdateLiveEvents(true);
    }

    const timeSelected = moment(new Date(date)).format('YYYY/MM/DD hh:mm:ss A')
    const endTime = timezone ? new Date(moment.tz(new Date(timeSelected), timezone)).getTime() :  new Date(date).getTime();
    if (endTime) {
      setEventsData([]);
      if(allTimeMetaData?.length > 0) {
        fetchRecords(endTime, true);
      }
    }
  };

  const handleRefreshClick = () => {
    if(allTimeMetaData?.length > 0) {
      fetchRecords();
    }
  };

  const removeAllTags = () => {
    const updatedTags = allTags.map(tag => 
      ({
        ...tag,
        value: false
      })
    );
    setAllTags(updatedTags);
  }

  return (
    <div
      key={`events-list-${deviceId}`}
      className={`right-section ${
        Array.isArray(eventsData) && eventsData.length > 0 ? '' : 'no-data'
      }`}
      // style={{
      //   height: listHeight,
      //   maxHeight: minHeightForLoadingSpace,
      // }}
    >
      <div className={`fixed-icons-wrapper`}>
        <div className="label-nodata">
          {/* {Array.isArray(eventsData) && eventsData.length > 0 ? '' : 'Today'} */}
        </div>
        <div className="icons-container">
          <div
            className="grid-image"
            style={digitalZoomScale === 1 ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}
            onClick={() =>
              showGridOnImage ? hideFetchImageGrid() : showFetchImageGrid()
            }
          >
            {showGridOnImage &&
              Object.keys(getSnapshotCoordinates()).length > 0 && (
                <span className="red-circle"></span>
              )}
            <img src={grid} height="24" width="24" alt="" />
          </div>
          <div
            className="filterDate-image"
            onClick={() => setShowDateTimeModal(true)}
          >
            <img src={calender} height="24" width="24" alt="" />
          </div>
          <div className="filter-image" onClick={handleFilterClick}>
            {showAppliedFilter && <span className="red-circle"></span>}
            <img src={filter} alt="filter" height="24" width="24" />
          </div>
        </div>
      </div>
      {(!eventsData || eventsData.length === 0) &&
        searchedTags &&
        Object.entries(searchedTags).length > 0 && (
          <div ref={tagsListRef} className="tag-list-container">
            {Object.entries(searchedTags).map(([key, values]) => (
              <div className="tag-details" key={key}>
                {getIconAndLabel(key)}
                {values.map((item) => (
                  <span key={item} className="tag-item">
                    {item}
                    <RiCloseFill
                      className="cross-icon"
                      onClick={() => handleRemoveTagClick(key, item)}
                    />
                  </span>
                ))}
                <RiCloseFill
                  className="cross-icon"
                  onClick={() => handleCloseClick(key)}
                />
              </div>
            ))}
          </div>
        )}
      {isLoading && (!eventsData || eventsData.length === 0) ? (
        <Loader />
      ) : (
        <div
          className="scroll-container"
          // style={{ height: listHeight, maxHeight: updatedListHeight }}
        >
          {Array.isArray(eventsData) && eventsData.length > 0 ? (
            <InfiniteScroll
              key={`infinite-scroll-${deviceId}`}
              loadMore={loadMore}
              hasMore={hasMoreItems}
              loader={
                <div
                  key={`infinite-scroll-loader-${deviceId}`}
                  className="loader"
                ></div>
              }
              useWindow={false}
              threshold={50}
              style={{height: 'inherit'}}
            >
              {showItems(eventsData)}
            </InfiniteScroll>
          ) : (
            <div className="no-data-container">
              <div className="no-data-found">{constants.NO_EVENTS_TEXT}</div>
              {!showAppliedFilter && (
                <div className="refresh-button" onClick={handleRefreshClick}>
                  {constants.REFRESH_BUTTON_TEXT}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <SiteModal
        modalTitle={constants.FILTER_ALL_TAGS}
        showModal={showCategoryModal}
        hideModal={() => {
          setShowCategoryModal(false);
          reset({ category: category });
          !searchedTags && removeAllTags();
        }}
        classes="allTags-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tags-container">
            {allTags.map((tag) => (
              <div key={tag.key} className="tag-item">
                <div className="left-container">
                  <div className="icon">{tag.icon}</div>
                  <div className="container-text">{tag.label}</div>
                </div>
                <div className="right-container">
                  <ToggleInput
                    label={''}
                    name={tag.key}
                    value={tag.value}
                    labelColor={true}
                    changeHandler={(e) => {
                      handleTagChange(tag.key, e.target.checked);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div>
            <PrimaryButton
              className="btn btn-primary mt-0"
              type="submit"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              disabled={!formState.isValid}
            >
              {constants.FILTER_APPLY_BUTTON_TEXT}
            </PrimaryButton>
          </div>
        </form>
      </SiteModal>
      <SiteModal
        modalTitle="Go To Date"
        showModal={showDateTimeModal}
        hideModal={() => {
          setShowDateTimeModal(false);
        }}
        classes="date-time-picker-modal"
        size="sm"
      >
        <DateTimePicker
          date={selectedDate}
          time={selectedTime}
          onSubmit={handleDateChange}
          timeZone={deviceTimezone}
        />
      </SiteModal>
    </div>
  );
};

export default EventsList;
