import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Utils, constants } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { useDeviceSnapshots } from '../../store/DeviceSnapshotsStore';
import {
  mqttPublish,
  mqttSubscribe,
} from '../../utils/connection/mqttConnection';
import { getCDNInfo } from '../../store/reducers/StreamingReducer';
import { useSelector } from 'react-redux';
import { FiCameraOff } from 'react-icons/fi';
import { FiZoomIn } from 'react-icons/fi';
import FakeSnapshot from '../../assets/images/cameras/snapshots/fake-snapshot.png';
import './CameraWallSnapshot.scss';
import { DefaultDevice } from '../../assets/images';
import _ from 'lodash';

const CameraWallSnapshot = ({
  orgId,
  // cdnInfo,
  deviceId,
  deviceElemId,
  deviceName,
  locationAreaName = '',
  imageURL = FakeSnapshot,
  currentLayoutStatus = false,
  clickHandler = null,
  conStatus,
  hubId,
  isScrolled,
  displayDeviceStatus,
  ...props
}) => {
  const currentTime = Math.round(new Date().getTime() / 1000);
  const [appTopic, setAppTopic] = useState(`a/notify/${hubId}`);
  const [latestSnapshots, setLatestSnapshots] = useState({});

  const { getState, subscribe } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const deviceSnapshots = useDeviceSnapshots((state) => state.deviceSnapshots);
  const { setDeviceSnapshots, getDeviceSnapshots } = useDeviceSnapshots();
  const accountId = loggedInUserData.accountId;
  const [snapshotURL, setSnapshotURL] = useState(
    deviceSnapshots?.[deviceId] ? deviceSnapshots?.[deviceId] : imageURL
  );
  const [zoomLevel, setZoomLevel] = useState('1x');
  const [isRequested, setIsRequested] = useState(null);
  const rowElement = document.getElementById(deviceId);
  const cdnInfo = useSelector(getCDNInfo);

  useEffect(() => {
    if (Object.keys(deviceSnapshots).length && isRequested === null) {
      if (deviceSnapshots?.[deviceId]) {
        setIsRequested(false);
      } else {
        setIsRequested(true);
      }
    }
  }, [deviceSnapshots?.[deviceId]]);

  useEffect(() => {
    if (isRequested === null) {
      return;
    }

    if (state.getAccountId() !== accountId) {
      state.setAccountId(accountId);
    }

    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }

    const deviceSubscription = {
      topic: `d/notify/${accountId}/${state.getSessionId()}`,
      qos: 0,
    };

    // Subscribe to the app topic
    //mqttSubscribe(appSubscription);
    state.setIsPublisherSubscribed(true);

    // Subscribe  to the device topic
    // mqttSubscribe(deviceSubscription);
    state.setIsReceiverSubscribed(true);

    subscribe((latestSnapshots) => setLatestSnapshots(latestSnapshots));
    if (
      displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
      isRequested
    ) {
      publishSnapshotRequest();
    } else if (!isRequested) {
      const snapshotImage = new Image();
      // Assign the valid URL to the snapshot URL state
      // variable
      snapshotImage.onload = () => {
        setSnapshotURL(snapshotImage.src);
      };
      // Handle the image loading error (e.g. 404 or 403 error)
      snapshotImage.onerror = () => {
        setSnapshotURL(imageURL);
      };
      snapshotImage.src = deviceSnapshots?.[deviceId];
    }
    // Retrieve latest snapshot every 10 seconds
    // const interval = setInterval(() => {
    //   publishSnapshotRequest();
    // }, 10000);

    // return () => {
    //   clearInterval(interval);
    // };
  }, [isRequested]);

  useEffect(() => {
    const fetchLatestSnapshot = async (lastSnapshot) => {
      if (!lastSnapshot || !cdnInfo) {
        return;
      }

      let bucket = cdnInfo?.bucket?.replace('${deviceId}', deviceId);

      const snapshotImage = new Image();
      // Assign the valid URL to the snapshot URL state
      // variable
      snapshotImage.onload = () => {
        setSnapshotURL(snapshotImage.src);
        setDeviceSnapshots(deviceId, snapshotImage.src);
      };

      // Handle the image loading error (e.g. 404 or 403 error)
      snapshotImage.onerror = () => {
        setSnapshotURL(imageURL);
      };
      const date = Utils.fetchDateInUnix(lastSnapshot);
      snapshotImage.src = `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastSnapshot}.jpg`;
    };
    const lastSnapshot = state.latestSnapshots[deviceId]
      ? state.latestSnapshots[deviceId]
      : 0;
    const lastEvent = state.latestEvents[deviceId]
      ? state.latestEvents[deviceId]
      : 0;
    let deviceSnapshot;
    if (lastSnapshot && currentTime - lastEvent > 300) {
      deviceSnapshot = lastSnapshot;
    } else if (lastEvent) {
      deviceSnapshot = lastEvent;
    }
    if (deviceSnapshot && isRequested) {
      fetchLatestSnapshot(deviceSnapshot);
    }
  }, [latestSnapshots, cdnInfo]);

  const publishSnapshotRequest = useCallback(() => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${hubId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/camera/last-snap-timestamp`,
          publish: `d/notify/${accountId}/${sessionId}`,
        },
      }),
      qos: 0,
    };
    if (deviceId) {
      mqttPublish(context);
    }
  }, []);

  // const elementIsVisibleInViewport = () => {
  //   if (rowElement) {
  //     const { top, bottom } = rowElement?.getBoundingClientRect();
  //     const { innerHeight } = window;
  //     const isVisisble = top >= 200 && bottom <= innerHeight;
  //     return isVisisble;
  //   } else {
  //     return false;
  //   }
  // };

  // const requestOnScroll = (event) => {
  //   console.log(deviceName);
  //   publishSnapshotRequest();
  // };

  // useEffect(() => {
  //   if (isRequested) {
  //     requestOnScroll();
  //   }
  // }, [isRequested]);

  // useEffect(() => {
  //   if (
  //     elementIsVisibleInViewport() &&
  //     displayDeviceStatus === 'Online' &&
  //     !isRequested
  //   ) {
  //     setIsRequested(true);
  //   }
  // }, [isScrolled]);

  return (
    <div
      key={deviceId}
      id={deviceElemId}
      className={`cam cam-device-wall-${zoomLevel}`}
    >
      <div className="cam-content" onMouseLeave={() => setZoomLevel('1x')}>
        {conStatus !== 'offline' ||
        (conStatus === 'offline' && deviceSnapshots?.[deviceId]) ? (
          <div className={`cam-device-wall-snapshot-${zoomLevel}`}>
            <picture className="cam-snapshot-wrapper">
              <img
                src={snapshotURL}
                onError={(e) => (e.target.src = `${DefaultDevice}`)}
                className={`cam-device-wall-snapshot-image`}
                alt={`Snapshot of ${deviceName}`}
              />
            </picture>
            <div
              id={`${deviceElemId}Overlay`}
              className={`${
                conStatus === 'offline'
                  ? ' cam-offline'
                  : zoomLevel === '1x'
                  ? 'cam-overlay'
                  : ''
              }`}
            >
              {conStatus === 'offline' ? (
                <div className="cam-offline-content">
                  <div className="camera-offline-icon">
                    <FiCameraOff
                      stroke={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_56')}
                      size={22}
                    />
                  </div>
                </div>
              ) : (
                <div className="cam-overlay-content">
                  <div className="zomm-snapshot-wrapper">
                    <div className="zomm-button">
                      {zoomLevel === '1x' ? (
                        <FiZoomIn
                          size={20}
                          onClick={() => {
                            setZoomLevel('2x');
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={`cam-offline`}>
            <div className={'cam-offline-device-wall-content'}>
              <div className="camera-offline-icon">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={22}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CameraWallSnapshot;
