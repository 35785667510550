import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HiOutlineExclamation, HiOutlineCalendar } from 'react-icons/hi';
import { FaCircle } from 'react-icons/fa';
import { Button, Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import {
  BreadcrumbList,
  Header,
  SiteSpinner,
  SiteToast,
} from '../../components/common';
import { AddLicense, ListTable } from './';
import { constants, Utils, roles, AppDefaults } from '../../helpers/';
import useLicensesStore from '../../store/LicensesStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import './ManageLicenses.scss';
import { ReactComponent as LicensesIconNoRecord } from '../../assets/images/LicensesIconNoRecord.svg';

const gridFields = [
  {
    field: 'productDisplayName',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_NAME,
  },
  {
    field: 'licenseKey',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LICENSE_KEY,
  },
  {
    field: 'licenseStatus',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_STATUS,
  },
  {
    field: 'deviceName',
    displayName:
      constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_ASSIGNED_DEVICE,
  },
  {
    field: 'locationName',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LOCATION,
  },
  {
    field: 'activeDate',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_START_DATE,
  },
  {
    field: 'expiryDate',
    displayName: constants.LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_END_STATE,
  },
];

const getColDefs = () => {
  if (!Array.isArray(gridFields)) return;

  return gridFields.map((gridField, gridFieldIndex) => ({
    id: gridFieldIndex,
    field: gridField.field,
    displayName: gridField.displayName,
  }));
};

const DATE_FORMAT = 'MMM Do YYYY';

const ManageLicenses = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [columnDefs] = useState([...getColDefs()]);
  const [rowData, setRowData] = useState([]);
  const [licensesUpdated, setLicensesUpdated] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const orgId = searchParams.get('orgId');
  const orgName = searchParams.get('orgName') || 'Duclo';
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const breadList = [
    {
      url:loggedInUser?.orgType === AppDefaults.ORG_TYPE_INSTALLER ? '/customers/manage.html' : `/customers/dashboard.html?orgId=${orgId}&orgName=${orgName}`,
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}&orgName=${orgName}`,
      title: `${orgName}`,
    },
    { url: null, title: constants.LICENSES_PAGE_TITLE },
  ];

  let { getLicenses, setLicenses, licenses } = useLicensesStore();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (licensesUpdated === true) {
      fetchData();
    }
  }, [licensesUpdated]);

  const fetchData = async () => {
    try {
      let filteredPaidLicenses;

      setShowLoader(true);

      if (Array.isArray(licenses) && licenses.length > 0) {
        // Retrieve all licenses
        filteredPaidLicenses = getLicenses(false, true);
      } else {
        const response = await setLicenses(`/user/orgs/${orgId}/licenses`);

        if (response?.status?.toUpperCase() === 'SUCCESS') {
          filteredPaidLicenses = getLicenses(false, true);
        } else {
          throw new Error('Could not retrieve licenses');
        }
      }

      if (filteredPaidLicenses?.length > 0) {
        const tableRowData = Array.isArray(filteredPaidLicenses)
          ? getRowData(filteredPaidLicenses)
          : [];

        setRowData(tableRowData);
      }
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      setShowLoader(false);
    }
  };

  const statusMap = {
    UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    ASSIGNED_ACTIVATION_PENDING:
      constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING,
    ACTIVE: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE,
    ACTIVE_UNASSIGNED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE,
    EXPIRING_SOON: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON,
    EXPIRED: constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED,
  };

  const getRowData = (licenses) => {
    let licenseProps = {};
    const rowData = [];
    const licenseStatusMap = {
      UNASSIGNED: (
        <div className="status-field">
          <FaCircle className="status-indicator status-available" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE}
        </div>
      ),
      ASSIGNED_ACTIVATION_PENDING: (effectiveStartUnixTime) => (
        <div className="status-field">
          <FaCircle className="status-indicator status-inactive" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING +
            ' ' +
            moment(effectiveStartUnixTime).format(DATE_FORMAT)}
        </div>
      ),
      ACTIVE: (
        <div className="status-field">
          <FaCircle className="status-indicator status-active" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE}
        </div>
      ),
      ACTIVE_UNASSIGNED: (
        <div className="status-field">
          <FaCircle className="status-indicator status-available" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE}
        </div>
      ),
      EXPIRING_SOON: (
        <div className="status-field">
          <FaCircle className="status-indicator status-expiring-soon" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON}
        </div>
      ),
      EXPIRED: (
        <div className="status-field">
          <FaCircle className="status-indicator status-expired" />
          {constants.LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED}
        </div>
      ),
    };

    if (!Array.isArray(licenses)) return;

    licenses.forEach((license) => {
      columnDefs.forEach((def) => {
        switch (def?.field?.toUpperCase()) {
          case 'LICENSESTATUS':
            // Cannot rely on the EXPIRED and EXPIRING_SOON statuses
            // So the date comparison is best used when determining
            // if the license has expired or about to expire
            if (
              license?.expiryDate &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) <= 0
            ) {
              // Expired status
              licenseProps[def?.field] = licenseStatusMap['EXPIRED'];
            } else {
              if (license[def?.field] === 'ASSIGNED_ACTIVATION_PENDING') {
                // Activation Pending status
                licenseProps[def.field] = licenseStatusMap[
                  license[def.field].toUpperCase()
                ](license.activeDate);
              } else if (
                license[def?.field] !== 'UNASSIGNED' &&
                license.expiringSoonInDays &&
                Utils.getDateDifferenceInDays(
                  license.expiryDate,
                  moment().valueOf()
                ) <= license.expiringSoonInDays &&
                Utils.getDateDifferenceInDays(
                  license.expiryDate,
                  moment().valueOf()
                ) >= 0
              ) {
                // Expiring Soon status
                licenseProps[def?.field] = licenseStatusMap['EXPIRING_SOON'];
              } else {
                // All other statuses
                // TODO: delete later - for testing purposes
                //   licenseStatusMap['ASSIGNED_ACTIVATION_PENDING'];
                //   licenseStatusMap['ACTIVE'];
                //   licenseStatusMap['ACTIVE_UNASSIGNED'];
                //   licenseStatusMap['EXPIRED'];
                //   licenseStatusMap['EXPIRING_SOON'];
                licenseProps[def?.field] =
                  licenseStatusMap[license[def?.field].toUpperCase()];
              }
            }
            break;

          case 'DEVICENAME':
          case 'LOCATIONNAME':
            if (def?.field in license) {
              licenseProps[def.field] = license[def.field];
            } else {
              licenseProps[def.field] =
                constants.LICENSES_PAGE_GRID_ASSIGNED_DEVICE_LOCATION_FIELD_DEFAULT;
            }
            break;

          case 'ACTIVEDATE':
            licenseProps[def?.field] = (
              <div className="date-field">
                <HiOutlineCalendar className="calendar-icon" />
                {def?.field in license
                  ? moment(license?.activeDate).format(DATE_FORMAT)
                  : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
              </div>
            );
            break;

          case 'EXPIRYDATE':
            if (
              license?.expiryDate &&
              license.licenseStatus &&
              license.licenseStatus !== 'EXPIRED' &&
              Utils.getDateDifferenceInDays(
                license?.expiryDate,
                moment().valueOf()
              ) <= license.expiringSoonInDays
            ) {
              licenseProps[def?.field] = (
                <div className="date-field">
                  <HiOutlineExclamation className="exclamation-icon" />
                  <span className="warning-date">
                    {moment(license.expiryDate).format(DATE_FORMAT)}
                  </span>
                </div>
              );
            } else {
              licenseProps[def?.field] = (
                <div className="date-field">
                  <HiOutlineCalendar className="calendar-icon" />
                  {def?.field in license
                    ? moment(license.expiryDate).format(DATE_FORMAT)
                    : constants.LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT}
                </div>
              );
            }
            break;

          default:
            if (def?.field in license) {
              licenseProps[def?.field] = license[def?.field];
            }
        }
      });

      rowData.push(licenseProps);
      licenseProps = {};
    });

    return rowData;
  };

  return (
    <div className="App manage-licenses">
      <Header />
      <Container className="container-top-offset mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className="mw-100">
              {showLoader ? (
                <SiteSpinner height="100px" width="100px" />
              ) : (
                <div className="page-header mt-4 mb-5">
                  <Row xs={1} md={2} className="g-4">
                    <Col md={6} lg={6} xl={6} xs={12}>
                      <BreadcrumbList list={breadList} />
                    </Col>
                    {Utils.getModuleStatus()?.PRODUCT_LISTING && (
                      <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                        <div className="licenses-button-wrapper">
                          <Button
                            size="md"
                            className="order-history-button"
                            onClick={() => {
                              navigate(
                                `/orders/history.html?orgId=${orgId}&orgName=${orgName}`
                              );
                            }}
                          >
                            <span className="order-history-button-label">
                              {
                                constants.LICENSES_PAGE_ORDER_HISTORY_BUTTON_LABEL
                              }
                            </span>
                          </Button>
                          <Button
                            variant="primary"
                            size="md"
                            className="purchase-button"
                            onClick={() => {
                              navigate(
                                `/products/listing.html?orgId=${orgId}&orgName=${orgName}`
                              );
                            }}
                          >
                            <span className="purchase-button-label">
                              {constants.PURCHASE_SERVICES_BUTTON_LABEL}
                            </span>
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md={6} lg={6} xl={6} xs={12}>
                      <div className="toast-wrapper">
                        <SiteToast
                          customCss="licenses-list-toast"
                          position="top-end"
                          show={showToast}
                          title="Uh-oh!"
                          body={userMsg}
                          delay={5000}
                        />
                        <span className="page-title">
                          {constants.LICENSES_PAGE_TITLE}
                        </span>
                      </div>
                    </Col>
                    <Col md={6} lg={6} xl={6} xs={12} className="end-align">
                      {process.env.REACT_APP_PROJECT ===
                        AppDefaults.PROJECT_MEGATRON && (
                        <AddLicense
                          reloadData={() => {
                            setLicensesUpdated(true);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="licenses-table-container">
                        {rowData.length > 0 ? (
                          <ListTable
                            colsDef={columnDefs}
                            colSorting={true}
                            rowData={rowData}
                            statusMap={statusMap}
                            cssClasses="licenses-table"
                          />
                        ) : (
                          <div className="no-devices-container">
                            <div className="image-wrapper">
                              <div className="license-icon">
                                <LicensesIconNoRecord></LicensesIconNoRecord>
                              </div>
                            </div>
                            <div>{constants.NO_LICENSE_TITLE}</div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ManageLicenses;
