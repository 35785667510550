import { useEffect, useLayoutEffect, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Box from '@mui/material/Box';

import { TbMoodEmpty } from 'react-icons/tb';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';

import { constants } from '../../helpers/';

import './ListTable.scss';

const ListTable = ({
  colsDef = [],
  colSorting,
  rowData = [],
  cssClasses,
  rowClassName,
  rowClickDisabled = false,
  rowClickHandler,
  specialDataField,
  specialColumnType,
  sortableField = null,
  noDataContent,
}) => {
  const [columns, setColumns] = useState([...colsDef]);
  const [rows, setRows] = useState([...rowData]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortField, setSortField] = useState(null);

  const defaultNoDataContent = (
    <Box className="no-data-container">
      <TbMoodEmpty size={32} className="no-data-icon" />
      <p className="no-data-alert">{constants.NO_MATCHING_DATA_FOUND_TEXT}</p>
    </Box>
  );

  useEffect(() => {
    setColumns([...colsDef]);
    setRows([...rowData]);
  }, [rowData, colsDef]);

  // When the browser window is resized, we rehydrate the columns and rows states
  // so that the table is re-rendered to determine the list of popovers to enable
  // dynamically.
  useLayoutEffect(() => {
    const rehydrate = () => {
      setColumns([...colsDef]);
      setRows([...rowData]);
    };

    window.addEventListener('resize', rehydrate);

    return () => window.removeEventListener('resize', rehydrate);
  }, [colsDef, rowData]);

  const handleSort = (field, columnType) => {
    let fieldToSort;

    if (!field) return;

    fieldToSort = sortableField ? `sortable_${field}` : field;

    setSortField(field);

    const compare = (a, b) => {
      if (sortOrder === 'asc') {
        if (columnType === specialColumnType) {
          if (a[field]?.props?.children[1] < b[field]?.props?.children[1]) {
            return -1;
          }
          if (a[field]?.props?.children[1] > b[field]?.props?.children[1]) {
            return 1;
          }
        } else {
          if (
            typeof a[fieldToSort] === 'string' &&
            typeof b[fieldToSort] === 'string'
          ) {
            return a[fieldToSort].localeCompare(b[fieldToSort], undefined, {
              sensitivity: 'base',
            });
          } else {
            if (a[fieldToSort] < b[fieldToSort]) {
              return -1;
            }
            if (a[fieldToSort] > b[fieldToSort]) {
              return 1;
            }
          }
        }
      } else {
        if (columnType === specialColumnType) {
          if (a[field]?.props?.children[1] > b[field]?.props?.children[1]) {
            return -1;
          }
          if (a[field]?.props?.children[1] < b[field]?.props?.children[1]) {
            return 1;
          }
        } else {
          if (
            typeof a[fieldToSort] === 'string' &&
            typeof b[fieldToSort] === 'string'
          ) {
            return b[fieldToSort].localeCompare(a[fieldToSort], undefined, {
              sensitivity: 'base',
            });
          } else {
            if (a[fieldToSort] > b[fieldToSort]) {
              return -1;
            }
            if (a[fieldToSort] < b[fieldToSort]) {
              return 1;
            }
          }
        }
      }

      return 0;
    };

    if (Array.isArray(rows)) {
      rows.sort(compare);
    }

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const isGrandchildOverflown = (id, section) => {
    const grandChildEl =
      section === 'headerCell'
        ? document.querySelector(`#${id}`)?.lastChild?.firstChild
        : document.querySelector(`#${id}`);

    return (
      grandChildEl?.scrollHeight !== grandChildEl?.clientHeight ||
      grandChildEl?.scrollWidth !== grandChildEl?.clientWidth
    );
  };

  const renderHeader = (columns) => {
    const headerContent = (columns) => {
      if (!Array.isArray(columns)) {
        return <th></th>;
      }

      return columns.map((col) => {
        let thContent =
          colSorting === true ? (
            <th
              id={`header${col?.field}`}
              key={`header${col?.field}`}
              style={{
                width: col?.cellMaxWidth,
                maxWidth: col?.cellMaxWidth,
                textAlign:
                  col?.field?.toUpperCase() === 'ACTION' ? 'center' : 'left',
              }}
            >
              <div
                key={`headerDiv${col?.field}`}
                className="header-sort"
                onClick={() => {
                  if (col?.field?.toUpperCase() === specialDataField) {
                    handleSort(col?.field, specialColumnType);
                  } else if (col?.field?.toUpperCase() !== 'ACTION') {
                    handleSort(col?.field, null);
                  }
                }}
                style={{
                  justifyContent:
                    col?.field?.toUpperCase() !== 'ACTION'
                      ? 'flex-start'
                      : 'space-around',
                }}
              >
                {isGrandchildOverflown(`header${col?.field}`, 'headerCell') &&
                col?.field?.toUpperCase() !== 'ACTION' ? (
                  <OverlayTrigger
                    key={`triggerHeader${col?.field}`}
                    placement="top"
                    overlay={
                      <Tooltip key={`tooltipHeader${col?.field}`}>
                        {col?.displayName}
                      </Tooltip>
                    }
                  >
                    <div key={`columnDiv${col?.field}`} className="column-name">
                      {col?.displayName}
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div
                    key={`columnDiv${col?.field}`}
                    className="column-name"
                    style={{
                      textAlign:
                        col?.field?.toUpperCase() !== 'ACTION'
                          ? 'left'
                          : 'center',
                    }}
                    onClick={col?.clickHandler}
                  >
                    {col?.displayName}
                  </div>
                )}
                {col?.field?.toUpperCase() !== 'ACTION' &&
                  (sortField === col?.field && sortOrder === 'desc' ? (
                    <VscChevronUp
                      key={`sortIcon${col?.field}`}
                      className="sort-icon"
                    />
                  ) : (
                    <VscChevronDown
                      key={`sortIcon${col?.field}`}
                      className="sort-icon"
                    />
                  ))}
              </div>
            </th>
          ) : (
            <th
              key={`header${col?.field}`}
              style={{
                width: col?.cellMaxWidth,
                maxWidth: col?.cellMaxWidth,
                textAlign:
                  col?.field?.toUpperCase() === 'ACTION' ? 'center' : 'left',
              }}
            >
              <div key={`columnDiv${col?.field}`} className="column-name">
                {col?.displayName}
              </div>
            </th>
          );

        return thContent;
      });
    };

    return <tr className="table-columns">{headerContent(columns)}</tr>;
  };

  const rowContent = (row, rowIdx) => {
    let content = [];

    if (!Array.isArray(columns)) return <></>;

    columns.forEach((col, colIdx) => {
      let cellId = 'cell' + rowIdx + colIdx;
      let colContent;

      if (
        isGrandchildOverflown(cellId, 'rowCell') &&
        col?.field?.toUpperCase() !== 'ACTION'
      ) {
        colContent = (
          <td
            id={cellId}
            key={cellId}
            style={{
              width: col?.cellMaxWidth,
              maxWidth: col?.cellMaxWidth,
            }}
          >
            <OverlayTrigger
              key={`triggerCell${rowIdx}${col?.field}`}
              placement="top"
              overlay={
                <Tooltip key={`tooltipCell${rowIdx}${col?.field}`}>
                  {row[col?.field]}
                </Tooltip>
              }
            >
              <div
                key={`divCell${rowIdx}${col?.field}`}
                style={{
                  maxWidth: col?.cellMaxWidth,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {row[col?.field]}
              </div>
            </OverlayTrigger>
          </td>
        );
      } else {
        colContent = (
          <td
            id={cellId}
            key={cellId}
            style={{
              width: col?.cellMaxWidth,
              maxWidth: col?.cellMaxWidth,
              textAlign:
                col?.field?.toUpperCase() === 'ACTION' ? 'center' : 'left',
            }}
          >
            {row[col?.field]}
          </td>
        );
      }

      content.push(colContent);
    });

    return content;
  };

  return (
    <Table className={`sortable-list-table ${cssClasses}`}>
      <thead>{renderHeader(columns)}</thead>
      <tbody>
        {Array.isArray(rows) && rows.length > 0 ? (
          rows.map((row, rowIndex) => (
            <tr
              key={`license${rowIndex}`}
              className={`row-item ${rowClassName ? rowClassName : ''}`}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();

                if (!rowClickDisabled && rowClickHandler) {
                  rowClickHandler(row.rowId);
                }
              }}
            >
              {rowContent(row, rowIndex)}
            </tr>
          ))
        ) : (
          <tr className="row-item row-item-disabled">
            <td colspan={columns.length}>
              {noDataContent || defaultNoDataContent}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ListTable;
