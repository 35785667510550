import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  Tabs,
  TextBlock,
} from '../../../components/common';
import {
  constants,
  EntitlementsTypeEnum,
  Utils,
  roles,
} from '../../../helpers';
import PageWrapper from '../../PageWrapper';
import {
  AudioSettingsBlock,
  DeviceInfoBlock,
  DeviceNetworkBlock,
  DeviceSettingsBlock,
  LicensesBlock,
  AnalyticsSettings,
  FocusSettingBlock,
} from './blocks';
import { useDispatch, useSelector } from 'react-redux';
import {
  // getAllDevicesData,
  getDeviceInformation,
  // getSelectedOrganization,
  setDeviceInformation,
  // setAllDevicesData,
  getAllMqttDataFromResponse,
  resetAllMqttDataFromResponse,
  getAccountId,
} from '../../../store/reducers/AccountReducer';
import Live from '../../../components/streaming/live';
import Playback from '../../../components/streaming/playback';
import {
  setLiveStream,
  setRemoteStream,
  setStartDate,
  getPlatformInfo,
  setIsLiveViewEntitlementExist,
  setIsLiveViewEntitlementExpired,
  getMQTTConnection,
} from '../../../store/reducers/StreamingReducer';
import {
  HiOutlineInformationCircle,
  HiOutlineVideoCamera,
} from 'react-icons/hi';
import {
  TbDeviceAnalytics,
  TbFileCertificate,
  TbHeadphones,
  TbNetwork,
  TbFocusCentered,
} from 'react-icons/tb';
import { ReactComponent as NoLicenseIcon } from '../../../assets/images/icons/Union.svg';
import axios from 'axios';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import {
  getCurrentEventResource,
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../../utils/connection/mqttConnection';
import './DeviceDashboard.scss';
import useLicensesStore from '../../../store/LicensesStore';
import { HiExclamationCircle } from 'react-icons/hi2';
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/Info-circle.svg';
import { RxCross1 } from 'react-icons/rx';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
// import IdleTimer from 'react-idle-timer';
import {
  getAllDevicesData,
  // getAllMqttDataFromResponse,
  // getSelectedOrganization,
  // resetAllMqttDataFromResponse,
  setAllDevicesData,
} from '../../../store/AccountStoreIDB';
// import { observerInstance } from '../../../store/indexDB/observer';
import { usePoliciesStore } from '../../../store/policiesStore';
// import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { getOrgInfo } from '../../../store/reducers/OrganizationsReducer';
import { FiCameraOff } from 'react-icons/fi';

const DeviceDashboard = () => {
  const navigate = useNavigate();
  const orgInfo = useSelector(getOrgInfo)
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const accountId = useSelector(getAccountId);
  let [searchParams] = useSearchParams();
  const deviceId = searchParams.get('deviceId');
  // const [orgDetails, setOrgDetails] = useState({});

  // const loadSelectedOrgData = useCallback(async () => {
  //   const org = await getSelectedOrganization();
  //   setOrgDetails(org || {});
  // }, []);

  const deviceDetails = useSelector(getDeviceInformation);
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  const [lisenceStatus, setlisenceStatus] = useState(false);
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);
  const [showDetachLicenseModal, setShowDetachLicenseModal] = useState(false);
  const [videoBitRate, setVideoBitRate] = useState();
  const [deviceEntitlments, setDeviceEntitlments] = useState();
  const [videoResolution, setVideoResolution] = useState();
  const [videoFPS, setVideoFPS] = useState();
  const [activeSection, setActiveSection] = useState(0);
  const [saveDeviceSettingsFlag, setSaveDeviceSettingsFlag] = useState(false);
  const [saveTabSettingsDataFlag, setSaveTabSettingsDataFlag] = useState(false);
  const { getCustomerOrgPolicies } = usePoliciesStore();
  const userPolicies = getCustomerOrgPolicies();
  const [canAttachLicense, setCanAttachLicense] = useState(
    deviceDetails?.claimed &&
      deviceDetails?.apps?.vms &&
      userPolicies?.assign_license
  );
  const [hasLiveStreamEntitlement, setHasLiveStreamEntitlement] =
    useState(false);
  const [moveLocationData, setMoveLocationData] = useState({});
  const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const platformDetails = useSelector(getPlatformInfo);
  const { setLicenses } = useLicensesStore();
  const publishTopic = platformDetails?.mqtt?.topic_details?.publish?.settings;
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const [parent, setParent] = useState({});
  const deviceState = deviceDetails?.deviceStatus;
  const connectionState = deviceDetails?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const [sectionBlockData, setSectionBlockData] = useState({});
  const [sectionTabData, setSectionTabData] = useState([]);
  const [sectionButtonData, setSectionButtonData] = useState({});
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [sectionTabs, setSectionTabs] = useState([]);

  // const getLoggedInUserUUID = useLoggedInUserData(
  //   (state) => state.getLoggedInUserUUID
  // );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  // const setPingApiCallTime = useLoggedInUserData(
  //   (state) => state.setPingApiCallTime
  // );
  const MQTTConnectionStatus = useSelector(getMQTTConnection);

  const devicePublishId = Utils.replaceStringValues(
    publishTopic?.[0],
    '${deviceId}',
    `${deviceDetails?.gatewayId}`
  );
  const breadList = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgInfo?.orgId}`,
      title: `${orgInfo?.orgName}`,
      disable : userPolicies?.view_cust_org_dashboard ? false : true,
    },
    {
      url: `/devices/listing.html?orgId=${orgInfo?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
    {
      url: '/devices/dashboard.html?deviceId',
      title: `${deviceDetails?.deviceName}`,
    },
  ];

  useEffect(() => {
    const result = devicesDetails.filter(
      (device) => device.deviceId === deviceId
    );
    if (result.length > 0) {
      dispatch(setDeviceInformation(result[0]));
    }
  }, [!deviceDetails]);

  // const loadAllMqttData = useCallback(async () => {
  //   const mqttResp = await getAllMqttDataFromResponse();
  //   setAllMqttData(mqttResp || []);
  // }, []);

  // const debouncedLoadSelectedOrgData = useDebouncedCallback(
  //   loadSelectedOrgData,
  //   1000
  // );

  // useEffect(() => {
  //   const handleUpdate = async (data) => {
  //     if (data.key === 'selectedOrganization') {
  //       await debouncedLoadSelectedOrgData();
  //     }
  //     // if (data.key === 'allMqttDataFromResponse') {
  //     //   await loadAllMqttData();
  //     // }
  //   };
  //   observerInstance.addObserver(handleUpdate);
  //   debouncedLoadSelectedOrgData();
  //   // loadAllMqttData();

  //   return () => {
  //     observerInstance.removeObserver(handleUpdate);
  //   };
  // }, [debouncedLoadSelectedOrgData]);

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };
    fetchDevices();
    // loadSelectedOrgData();
  }, []);

  useEffect(() => {
    if (orgInfo?.orgId) {
      getLicensesCall();
    }
  }, [orgInfo?.orgId]);

  useEffect(() => {
    if(deviceId && orgInfo?.orgId){
      fetchDeviceData();
    }
  }, [deviceId, orgInfo?.orgId]);

  const fetchDeviceData = async () => {
    axios
      .get(
        `device/orgs/${orgInfo?.orgId}/devices/${deviceId}/v2`,
        Utils.requestHeader()
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            onResponseV2(responseData);
          } else {
            if (deviceDetails?.deviceId === deviceId) {
              onResponseV2(deviceDetails);
            }
          }
        } else {
          if (deviceDetails?.deviceId === deviceId) {
            onResponseV2(deviceDetails);
          }
        }
      });
  };

  const onResponseV2 = (responseData) => {
    let openDeviceDetails = JSON.parse(JSON.stringify(responseData));
    openDeviceDetails['displayDeviceStatus'] = Utils.getDeviceStatus(
      responseData.deviceStatus,
      responseData.connectionStatus
    );
    getCapabiltiesForDevice(openDeviceDetails);
  }

  const getCapabiltiesForDevice = async (deviceData) => {
    if (deviceData?.capability) {
      if (deviceDetails?.capDetails) {
        deviceData.capDetails = deviceDetails.capDetails;
        setDeviceInfo(deviceData);
        if (deviceDetails?.deviceId === deviceId || !deviceDetails) {
          dispatch(setDeviceInformation(deviceData));
        }
      } else {
        try {
          let response = await fetch(deviceData?.capability?.url);
          if (response.status === 200) {
            let responseJson = await response.json();
            deviceData.capDetails = responseJson;
            setDeviceInfo(deviceData);
            dispatch(setDeviceInformation(deviceData));
          } else {
            dispatch(setDeviceInformation(deviceData));
          }
        } catch (error) {
          dispatch(setDeviceInformation(deviceData));
        }
      }
    } else {
      JSON.parse(JSON.stringify(deviceData?.properties));
      dispatch(setDeviceInformation(deviceData));
    }
  };

  useEffect(() => {
    if (
      MQTTConnectionStatus &&
      Array.isArray(subscribeTopic) &&
      subscribeTopic.length > 0 &&
      deviceDetails?.gatewayId
    ) {
      const deviceSubscription = {
        topic: Utils.replaceStringValues(
          subscribeTopic[0],
          '${deviceId}',
          `${deviceDetails?.gatewayId}`
        ),
        qos: 0,
      };
      mqttSubscribe(deviceSubscription);

      return () => {
        mqttUnsubscribe(deviceSubscription);
      };
    }
  }, [deviceDetails, MQTTConnectionStatus]);

  const getLicensesCall = async () => {
    await setLicenses(`/user/orgs/${orgInfo?.orgId}/licenses`);
    let fetchResult = await setLicenses(`/user/orgs/${orgInfo?.orgId}/licenses`);
    if (fetchResult?.status === 'success') {
      setlisenceStatus(true)
    }
    else if(fetchResult?.status === 'failure'){
      setlisenceStatus(true)
    }
  };

  useEffect(() => {
    const responseDeviceId = allMqttData?.msg?.resource
      ?.toString()
      ?.split('/')?.[1];
    const resource = allMqttData?.msg?.resource
      ?.toString()
      ?.split(`${deviceId}/`)?.[1];
    if (
      getCurrentEventResource(resource) &&
      !resource?.includes('system/device-status') &&
      deviceId === responseDeviceId &&
      allMqttData?.msg?.properties
    ) {
      updateDeviceStore(
        allMqttData?.msg?.properties,
        true,
        allMqttData?.msg?.action !== 'error'
      );
    } else if (
      getCurrentEventResource(allMqttData?.msg?.resource) &&
      !resource?.includes('system/device-status') &&
      allMqttData?.msg?.properties
    ) {
      updateDeviceStore(
        allMqttData?.msg?.properties,
        true,
        allMqttData?.msg?.action !== 'error'
      );
    }
  }, [allMqttData]);

  const updateDeviceStore = async (properties, isFromMqtt, mqttStatus) => {
    if (deviceDetails) {
      let openDeviceDetails = JSON.parse(JSON.stringify(deviceDetails));
      let deficeInfoProperty = openDeviceDetails?.properties;
      mqttStatus &&
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify({ ...deficeInfoProperty })
        );
      let localStoreProperty = JSON.parse(
        localStorage.getItem('localStoreProperty')
      );
      deficeInfoProperty = mqttStatus
        ? { ...deficeInfoProperty, ...properties }
        : localStoreProperty && {
            ...deficeInfoProperty,
            ...localStoreProperty,
          };
      openDeviceDetails = {
        ...openDeviceDetails,
        properties: deficeInfoProperty,
      };
      if (
        deviceInfo?.deviceId === deviceId &&
        deviceInfo?.capDetails &&
        !openDeviceDetails?.capDetails
      ) {
        openDeviceDetails = {
          ...openDeviceDetails,
          capDetails: deviceInfo?.capDetails,
        };
      }
      mqttStatus &&
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify({ ...deficeInfoProperty })
        );
      let updatedList = devicesDetails?.map((alldevice, index) => {
        if (alldevice.deviceId === openDeviceDetails?.deviceId) {
          return {
            ...openDeviceDetails,
          };
        } else {
          return { ...alldevice };
        }
      });
      let dList = await Promise.all(updatedList);
      await setAllDevicesData(dList);
      dispatch(setDeviceInformation(openDeviceDetails));
      isFromMqtt && (await resetAllMqttDataFromResponse());
    }
  };

  useEffect(() => {
    if (deviceDetails) {
      setCanAttachLicense(
        deviceDetails?.claimed &&
          deviceDetails?.apps?.vms &&
          userPolicies?.assign_license
      );
      setShow('LIVE');
    }
    dispatch(setRemoteStream(null));
    dispatch(setLiveStream(null));
  }, [deviceDetails]);

  useEffect(() => {
    if (deviceDetails && devicesDetails?.length) {
      const url = deviceDetails?.entitlement?.url;
      if (url) {
        fetchData(url);
      }
      const parent = devicesDetails?.find(
        (device) => device.deviceId === deviceDetails?.gatewayId
      );
      setParent(parent);
    }
  }, [
    deviceDetails?.entitlement?.url,
    deviceDetails?.gatewayId,
    devicesDetails,
  ]);

  const fetchData = async (url) => {
    try {
      fetch(url, {
        withCredentials: true,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const expiryDate = data?.term?.expiry;
            const durationInDays = data?.term?.data?.durationInDays;
            const startDate =
              expiryDate - Utils.daysToMilliseconds(durationInDays);
            dispatch(setStartDate(startDate));
            const liveView = data.entitlements?.find(
              (item) => item.type === EntitlementsTypeEnum.LIVE_VIEW
            );
            const entitlements =  data.entitlements
            const videoBitRate = data.entitlements?.find(
              (item) => item.type === EntitlementsTypeEnum.BITRATE
            );
            const videoResolution = data.entitlements?.find(
              (item) => item.type === EntitlementsTypeEnum.RESOLUTION
            );
            const videoFPS = data.entitlements?.find(
              (item) => item.type === EntitlementsTypeEnum.FPS
            );

            setVideoBitRate(videoBitRate);
            setDeviceEntitlments(entitlements);
            setVideoFPS(videoFPS);
            setVideoResolution(videoResolution);

            if (liveView) {
              setHasLiveStreamEntitlement(true);
              dispatch(setIsLiveViewEntitlementExist(true));
              dispatch(
                setIsLiveViewEntitlementExpired(
                  liveView.expiry < new Date().getTime()
                )
              );
            }
          }
        })
        .catch(function (error) {});
    } catch (error) {}
  };

  const handleClick = (detail) => {
    // Todo: Delete later
    Utils.vmsLogger().log('handle click', detail);
    if (detail === constants.DEVICES_SWITCH_TIMELINE_TITLE) {
      setShow(constants.DEVICES_SWITCH_TIMELINE_TITLE);
    } else if (detail === constants.DEVICES_SWITCH_LIVE_TITLE) {
      setShow(constants.DEVICES_SWITCH_LIVE_TITLE);
    }
  };

  const setSectionBlock = (index) => {
    setSaveTabSettingsDataFlag(false);
    setActiveSection(index);
  };

  const addLicenseButtonHandler = () => {
    setShowAddLicenseModal(true);
  };

  const saveDeviceInfoSettingsButtonHandler = () => {
    setSaveTabSettingsDataFlag(true);
  };
  const saveDeviceMoveLocationAreaData = (locationData) => {
    setMoveLocationData(locationData);
  };
  const updateStoreDeviceSetting = (properties) => {
    updateDeviceStore(properties, false, true);
  };
  const updateStoreDeviceSettingHalfwayDegree = (
    properties,
    isFromMqtt,
    status
  ) => {
    updateDeviceStore(properties, isFromMqtt, status);
  };
  const moveDeviceLocation = async () => {
    try {
      const orgID = deviceDetails?.orgId;
      const locationId = moveLocationData?.oldLocationId
        ? moveLocationData?.oldLocationId
        : deviceDetails?.locationId;
      const areaId = moveLocationData?.oldAreaId
        ? moveLocationData?.oldAreaId
        : deviceDetails?.areaId;
      const reqBody = {
        newAreaId: moveLocationData?.newAreaId
          ? moveLocationData?.newAreaId
          : deviceDetails?.areaId,
        deviceName: moveLocationData?.deviceName
          ? moveLocationData?.deviceName
          : deviceDetails?.deviceName,
      };
      const res = await axios.put(
        `/device/orgs/${orgID}/locations/${locationId}/areas/${areaId}/devices/${deviceId} `,
        reqBody,
        Utils.requestHeader()
      );
      if (res?.data?.meta?.code === 200) {
        await axios
          .get(`device/orgs/${orgID}/devices`, Utils.requestHeader())
          .then(async (response) => {
            let resultData = response.data;
            if (resultData) {
              let responseMeta = resultData.meta;
              let responseData = resultData.data;
              if (parseInt(responseMeta?.code) === 200) {
                // getCapabiltiesFromDeviceList(responseData);
                const currentDeviceDetail = responseData?.find(
                  (device) => device.deviceId === deviceId
                );
                getCapabiltiesForDevice(currentDeviceDetail);
                await setAllDevicesData(responseData);
              }
            }
          });
      } else {
        Utils.vmsLogger().error('ERROR: ', res?.data);
      }
    } catch (error) {
      Utils.vmsLogger().error('ERROR: ', error);
    }
  };

  const getCapabiltiesFromDeviceList = async (deviceData) => {
    let deviceList = [...deviceData];
    let newDeviceList = deviceList?.map(async (device, index) => {
      const deviceInfo = devicesDetails?.find(
        (deviceStore) => deviceStore?.deviceId === device?.deviceId
      );
      let isCapabilityCall =
        devicesDetails?.length === 0 ||
        deviceInfo?.capDetails === undefined ||
        deviceInfo?.capability?.version !== device?.capability?.version;
      if (device?.capability && isCapabilityCall) {
        let response = await fetch(device.capability.url);
        if (response.status === 200) {
          let responseJson = await response.json();
          return { ...device, capDetails: responseJson };
        } else {
          return { ...device };
        }
      } else if (
        device?.capability &&
        deviceInfo?.capability?.version === device?.capability?.version
      ) {
        return { ...device, capDetails: deviceInfo?.capDetails };
      } else {
        return { ...device };
      }
    });
    let deviceCapability = await Promise.all(newDeviceList);
    if (deviceCapability?.length) {
      const currentDeviceDetail = deviceCapability?.find(
        (device) => device.deviceId === deviceId
      );
      dispatch(setDeviceInformation(currentDeviceDetail));
    }
    await setAllDevicesData(deviceCapability);
  };

  useEffect(() => {
    setSectionTabs(sectionTabData);
  }, [sectionTabData?.length]);

  useEffect(() => {
    const sectionStatus = [];
    sectionCapabilities?.forEach((sectionCapability) => {
      let sectionFlag = false;
      sectionCapability?.forEach((capability) => {
        if (capability?.resource) {
          const CapDetails = deviceDetails?.capDetails?.capabilities?.find(
            (c) => c?.resource === capability?.resource
          );
          if (CapDetails) {
            capability?.properties?.forEach((property) => {
              if (
                Object.prototype.hasOwnProperty.call(
                  CapDetails?.properties,
                  property
                )
              ) {
                sectionFlag = true;
              }
            });
          }
        } else {
          sectionFlag = true;
        }
      });
      sectionStatus.push(sectionFlag);
    });
    const rawBlock = {};
    const rowTab = [];
    const rowButtons = [];
    let rawIndex = 0;
    sectionStatus?.forEach((section, index) => {
      if (section) {
        rawBlock[rawIndex] = sectionBlock[index];
        if (buttonGroup[index]) {
          rowButtons[rawIndex] = buttonGroup[index];
        }
        rowTab.push(tabs[index]);
        rawIndex = rawIndex + 1;
      }
    });
    setSectionBlockData(rawBlock);
    setSectionTabData(rowTab);
    setSectionButtonData(rowButtons);
  }, [
    deviceId,
    deviceDetails,
    devicesDetails,
    orgInfo?.orgId,
    sessionId,
    accountId,
    devicePublishId,
    curDeviceStatus,
    connectionState,
    deviceState,
    parent,
    allMqttData,
    canAttachLicense,
    moveLocationData,
    saveTabSettingsDataFlag,
    saveDeviceSettingsFlag,
    showAddLicenseModal,
    showDetachLicenseModal,
    userPolicies,
    videoResolution,
    videoFPS,
    videoBitRate,
    deviceEntitlments,
    hasLiveStreamEntitlement
  ]);

  const sectionCapabilities = [
    [
      {
        resource: '',
        properties: [],
      },
    ],
    [
      {
        resource: 'camera/image/rotate',
        properties: ['rotation-angle', 'flip-enable', 'mirror-enable'],
      },
      {
        resource: 'camera/image/wdr',
        properties: ['wdr-enable'],
      },
      {
        resource: 'camera/media/video-profile',
        properties: [
          'resolution',
          'dynamic-gov-enable',
          'dynamic-fps-enable',
          'encoding-type',
          'bit-rate',
          'frame-rate',
          'minimum-dynamic-fps',
          'mp-resolution',
        ],
      },
      {
        resource: 'camera/media/wisestream',
        properties: ['wisestream-enable'],
      },
      {
        resource: 'camera/image/ir-mode',
        properties: ['ir-mode-enable'],
      },
      {
        resource: 'camera/image/image-enhancement',
        properties: ['contrast-level', 'sharpness-level', 'brightness-level'],
      },
    ],
    [
      {
        resource: 'camera/image/focus',
        properties: [
          'focus-control',
          'zoom-control',
          'simple-focus-enable',
          'focus-enable',
        ],
      },
    ],
    [
      {
        resource: 'camera/media/audio-input',
        properties: [
          'audio-in-enable',
          'audio-in-source',
          'audio-in-noise-reduction',
          'audio-in-gain',
          'audio-in-sensitivity',
        ],
      },
      {
        resource: 'camera/media/audio-output',
        properties: ['audio-out-enable'],
      },
    ],
    [
      {
        resource: '',
        properties: [],
      },
    ],
    [
      {
        resource: '',
        properties: [],
      },
    ],
    [
      {
        resource: 'camera/event/object-detection',
        properties: ['vehicle-detection', 'person-detection'],
      },
      {
        resource: 'camera/event/motion-detection',
        properties: ['md-enable'],
      },
      {
        resource: 'camera/event/shock-detection',
        properties: ['skd-enable'],
      },
      {
        resource: 'camera/event/tamper-detection',
        properties: ['td-enable'],
      },
      {
        resource: 'camera/event/defocus-detection',
        properties: ['dfd-enable'],
      },
      {
        resource: 'camera/event/audio-detection',
        properties: ['audio-detection'],
      },
      {
        resource: 'camera/event/virtual-line',
        properties: ['virtual-line-enable'],
      },
      {
        resource: 'camera/event/include-area',
        properties: ['include-area-enable'],
      },
      {
        resource: 'camera/event/queue-mgt',
        properties: ['queue-enable'],
      },
      {
        resource: 'camera/event/exclude-area',
        properties: ['exclude-area-enable'],
      },
      {
        resource: 'camera/event/sound-class',
        properties: ['sc-enabled'],
      },
    ],
    [
      {
        resource: 'camera/event/sound-class',
        properties: [
          'sc-enabled',
          'sc-scream',
          'sc-gunshot',
          'sc-explosion',
          'sc-glassbreak',
          'sc-level',
        ],
      },
    ],
  ];
  // sectionBlock and buttonGroup must have the same number of keys.
  // Ensure entries in buttonGroup relate to keys in sectionBlock
  const sectionBlock = {
    0: (
      <DeviceInfoBlock
        orgId={orgInfo?.orgId}
        orgName={orgInfo?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        saveDeviceSettingsFlag={saveTabSettingsDataFlag}
        accountId={accountId}
        uuid={state?.getSessionId()}
        devicePublishId={devicePublishId}
        saveDeviceSettingsCallback={(value) => {
          saveDeviceInfoSettingsButtonHandler(value);
        }}
        moveDeviceDetailCallback={(locationData) => {
          saveDeviceMoveLocationAreaData(locationData);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        updateFirmware={() => {
          dispatch(setDeviceInformation(parent));
          navigate(
            `/devices/channel-details.html?deviceId=${deviceDetails?.gatewayId}`,
            {
              state: {
                isRedirectToNetwork: true,
              },
            }
          );
        }}
        hasManagePermission= {userPolicies?.manage_device_settings}
      />
    ),
    1: (
      <DeviceSettingsBlock
        orgId={orgInfo?.orgId}
        orgName={orgInfo?.orgName}
        deviceId={deviceId}
        accountId={accountId}
        saveDeviceSettingsFlag={saveDeviceSettingsFlag}
        deviceInfos={deviceDetails}
        allDeviceData={devicesDetails}
        devicePublishId={devicePublishId}
        uuid={state?.getSessionId()}
        saveDeviceSettingsCallback={(value) => {
          setSaveDeviceSettingsFlag(value);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        storeUpdateFromHalfway={(publishData, isFromMqtt, Status) => {
          updateStoreDeviceSettingHalfwayDegree(
            publishData,
            isFromMqtt,
            Status
          );
        }}
        hasManagePermission= {userPolicies?.manage_device_settings}
        deviceBitrate={videoBitRate}
        deviceFPS={videoFPS}
        parent={parent}
        deviceRes={videoResolution}
        sessionId={sessionId}
        entitlements = {deviceEntitlments}
      />
    ),
    2: (
      <FocusSettingBlock
        orgId={orgInfo?.orgId}
        orgName={orgInfo?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        saveAudioSettingsFlag={saveTabSettingsDataFlag}
        saveAudioSettingsCallback={(value) => {
          setSaveTabSettingsDataFlag(value);
        }}
        devicePublishId={devicePublishId}
        uuid={state?.getSessionId()}
        accountId={accountId}
        saveDeviceSettingsCallback={(value) => {
          setSaveDeviceSettingsFlag(value);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        hasManagePermission= {userPolicies?.manage_device_settings}
      />
    ),
    3: (
      <AudioSettingsBlock
        orgId={orgInfo?.orgId}
        orgName={orgInfo?.orgName}
        deviceId={deviceId}
        accountId={accountId}
        deviceInfos={deviceDetails}
        saveAudioSettingsFlag={saveTabSettingsDataFlag}
        devicePublishId={devicePublishId}
        uuid={state?.getSessionId()}
        saveAudioSettingsCallback={(value) => {
          setSaveTabSettingsDataFlag(value);
        }}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        hasManagePermission= {userPolicies?.manage_device_settings}
      />
    ),
    4: (
      <LicensesBlock
        orgId={orgInfo?.orgId}
        orgName={orgInfo?.orgName}
        deviceId={deviceId}
        deviceName={deviceDetails?.deviceName}
        deviceTimeZone={deviceDetails?.properties?.timezone}
        showAddModal={showAddLicenseModal}
        hideAddModalCallback={setShowAddLicenseModal}
        showRemoveModal={showDetachLicenseModal}
        hideRemoveModalCallback={setShowDetachLicenseModal}
        deviceInfos={deviceDetails}
        hasManagePermission= {userPolicies?.detach_license}
      />
    ),
    5: (
      <DeviceNetworkBlock
        orgId={orgInfo?.orgId}
        orgName={orgInfo?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        accountId={accountId}
        sessionId={sessionId}
        devicePublishId={devicePublishId}
        parent={parent}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        hasManagePermission= {userPolicies?.manage_device_settings}
        deviceBitrate={videoBitRate}
      />
    ),
    6: (
      <AnalyticsSettings
        accountId={accountId}
        orgId={orgInfo?.orgId}
        orgName={orgInfo?.orgName}
        deviceId={deviceId}
        deviceInfos={deviceDetails}
        devicePublishId={devicePublishId}
        uuid={state?.getSessionId()}
        updateStore={(publishData) => {
          updateStoreDeviceSetting(publishData);
        }}
        saveAudioSettingsFlag={saveTabSettingsDataFlag}
        hasManagePermission= {userPolicies?.manage_device_settings}
      />
    ),
  };

  const deleteDeviceCall = async () => {
    try {
      const res = await axios.delete(
        `/device/orgs/${orgInfo?.orgId}/devices/${deviceId}/v2`,
        Utils.requestHeader()
      );
      if (parseInt(res?.status) === 200) {
        hideDeviceRemoveModel();
        navigate(`/devices/listing.html?orgId=${orgInfo?.orgId}`);
      } else {
        setUserMsg(res?.meta?.userMsg || res?.data?.meta?.userMsg);
      }
    } catch (error) {
      // TODO : to print the error will update this later
      Utils.vmsLogger().error(error);
    }
  };

  const onDeleteDevice = () => {
    setShowDeviceRemoveModal(true);
  };

  const hideDeviceRemoveModel = () => {
    setShowDeviceRemoveModal(false);
    setUserMsg('');
  };

  const checkForAppClaim = (obj) => {
    return Object.keys(obj).some(key => key !== 'vms' && obj[key] === true);
  }

  const buttonGroup = {
    0: (
      <>
        {parent?.deviceType?.toLowerCase() === 'nvr' && (
          <PrimaryButton
            type="button"
            width="128px"
            fontSize="14px"
            height="44px"
            lineHeight="16px"
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_64')}
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_64')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--error_64'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--error_64')}
            backgroundColor="transparent"
            disabled={
              checkForAppClaim(deviceDetails.apps) ||
              !userPolicies?.delete_device
            }
            onClick={onDeleteDevice}
          >
            {constants.DEVICES_REMOVE_DEVICE}
          </PrimaryButton>
        )}
        <PrimaryButton
          type="button"
          width="150px"
          className="device-btn-space"
          onClick={() => moveDeviceLocation()}
          disabled={
            connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS ||
            deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS ||
            !userPolicies?.manage_device_settings
          }
        >
          {constants.DEVICES_SAVE_CHANGES_BUTTON}
        </PrimaryButton>
      </>
    ),
    4: canAttachLicense ? (
      <PrimaryButton
        type="button"
        width="165px"
        className="device-btn-space"
        onClick={() => addLicenseButtonHandler()}
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
      </PrimaryButton>
    ) : (
      <Button
        variant="primary"
        size="sm"
        className="attach-license-btn disabled"
      >
        {constants.DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL}
      </Button>
    ),
  };

  const tabs = [
    {
      icon: <HiOutlineInformationCircle size={22} />,
      label: constants.DEVICES_TAB_DEVICE_INFO,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <HiOutlineVideoCamera size={22} />,
      label: constants.DEVICES_TAB_VIDEO_SETTINGS,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <TbFocusCentered size={22} />,
      label: constants.DEVICES_TAB_FOCUS,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <TbHeadphones size={22} />,
      label: constants.DEVICES_TAB_AUDIO_SETTINGS,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <TbFileCertificate size={22} />,
      label: constants.DEVICES_TAB_LICENSES,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <TbNetwork size={22} />,
      label: constants.DEVICES_TAB_NETWORK,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <TbDeviceAnalytics size={22} />,
      label: constants.DEVICES_TAB_ANALYTICS_SETTINGS,
      clickHandler: (index) => setSectionBlock(index),
    },
  ];
  return (
    <div className="App device-dashboard">
      <Header currentDeviceId={deviceId} />
      <PageWrapper className="mw-100">
        <div className="page-header mb-5">
          <Container className="container-top-offset mw-100">
            <Row className="g-4 mb-3">
              <Col className="text-start">
                <BreadcrumbList list={breadList} />
              </Col>
            </Row>
            <Row className="g-4">
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {deviceDetails?.deviceName}

                <p className="page-title-note">{orgInfo?.orgName}</p>
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="d-flex align-items-md-center justify-content-center justify-content-md-end"
              >
                {sectionButtonData?.[0]
                  ? sectionButtonData?.[activeSection]
                  : null}
              </Col>
            </Row>
            <Row className="device-tabs">
              <Col>
                {sectionTabs?.length ? (
                  <Tabs
                    defaultActive={activeSection}
                    tabCollection={sectionTabs}
                  />
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className={`${
                  show === constants.DEVICES_SWITCH_LIVE_TITLE ? 'mt-2' : ''
                }`}
              >
                {show === constants.DEVICES_SWITCH_TIMELINE_TITLE && (
                  <Playback handleClick={handleClick} />
                )}
                {show === constants.DEVICES_SWITCH_LIVE_TITLE &&
                hasLiveStreamEntitlement === true && deviceDetails?.connectionStatus !=
                constants.DEVICES_OFFLINE_CONNECTION_STATUS && lisenceStatus ? (
                  <Live
                    page="settings"
                    handleClick={handleClick}
                    isStopStreamClick={true}
                    deviceId={deviceId}
                  />
                ) : deviceDetails?.connectionStatus ==
                  constants.DEVICES_OFFLINE_CONNECTION_STATUS ? (
                  <div className="live-view-no-license-container">
                    <div className="no-license-notice">
                      <FiCameraOff
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--greyscale_56')}
                        size={60}
                      />
                      <div className="no-license-text">
                        {constants.DEVICE_STATE_CAMERA_OFFLINE}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="live-view-no-license-container">
                    <div className="no-license-notice">
                      {/* <NoLicenseIcon className="no-license-icon" /> */}
                      {/* <div className="no-license-text">
                        {constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED}
                      </div> */}
                      <SiteSpinner height="50px" width="50px"/>
                    </div>
                  </div>
                )}
              </Col>
              <Col md={6} lg={6} xl={6} xs={12}>
                <div
                  className={`details-section-container${
                    activeSection !== 0 && activeSection !== 5
                      ? ' inner-view'
                      : ''
                  }`}
                >
                  {sectionBlockData[activeSection]}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Want to remove device - modal  */}
        <SiteModal
          modalTitle={
            constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE
          }
          showModal={showDeviceRemoveModal}
          hideModal={hideDeviceRemoveModel}
          classes="device-settings-restart-required"
        >
          {userMsg && userMsg.length > 0 && (
            <div className="error-container">
              <div className="info-icon-container">
                <InfoCircleIcon />
              </div>
              <div className="error-body">
                <div className="error-content">{userMsg}</div>
                <div className="cross-icon-container">
                  <RxCross1 onClick={() => setUserMsg('')} />
                </div>
              </div>
            </div>
          )}
          <TextBlock
            className="mb-3"
            fontWeight="400"
            fontSize="1rem"
            lineHeight="24px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
          >
            {
              constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT
            }
          </TextBlock>

          <>
            <div className="text-center">
              <HiExclamationCircle
                className="mb-4 icon-warning-color"
                size={96}
              />
            </div>
            <PrimaryButton
              className="btn btn-primary-outline mb-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              backgroundColor="transparent"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              onClick={hideDeviceRemoveModel}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
            <PrimaryButton
              className="btn btn-primary"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                deleteDeviceCall();
              }}
            >
              {constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE}
            </PrimaryButton>
          </>
        </SiteModal>
      </PageWrapper>
    </div>
  );
};

export default DeviceDashboard;
