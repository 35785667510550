import StepIndicator from '../StepIndicator';
import { AppDefaults, Utils, constants, roles } from '../../../../helpers';
import '../stepsnotification.scss';
import { useState, useRef, useEffect, useCallback } from 'react';
import { PrimaryButton } from '../../../../components/common';
import { useNotificationStore } from '../../../../store/CreateNotificationStore';
import { AiOutlineSearch } from 'react-icons/ai';
import axios from 'axios';
import { useOrganizations } from '../../../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../../../store/LoggedInAccountStore';
import { useSelector } from 'react-redux';
import { getCustomerOrgData } from '../../../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';

const Five = (props) => {
  const scollToRef = useRef();
  const setStepData = useNotificationStore((state) => state.setStepsData);
  const getStepsData = useNotificationStore((state) => state.getStepsData);
  const [searchInput, setSearchInput] = useState('');
  const [userList, setUserList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // let custOrg = getCustomerOrgData();
  // const custOrgData = custOrg[0];
  const [custOrgData, setCustOrgData] = useState();
  const [subscribers, setSubcribeList] = useState([]);

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setCustOrgData(orgs?.[0] || {});
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    1000
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
    };
    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setTimeout(() => {
      scollToRef?.current?.scrollIntoView();
    }, 100);
    if(custOrgData?.orgId){
      fetchUser(signal);
    }
    return () => {
      controller.abort();
    };
  }, [custOrgData?.orgId]);

  useEffect(() => {
    const subcriberData = [];
    searchList?.forEach((data, index) => {
      if (data?.isChecked) {
        subcriberData.push(data?.accountId);
      }
    });
    subcriberData.push(loggedInUserData?.accountId);
    setSubcribeList(subcriberData);
  }, [searchList]);

  const moveToNextStep = (isSkip) => {
    const subcribersData = isSkip ? [loggedInUserData?.accountId] : subscribers;
    const nextStepData = {};
    if (props?.isEdit) {
      nextStepData['triggers'] = getStepsData()?.triggers;
      nextStepData['selectedEvents'] = getStepsData()?.selectedEvents;
      nextStepData['editItem'] = getStepsData()?.editItem;
      nextStepData['devices'] = getStepsData()?.devices;
      nextStepData['actions'] = getStepsData()?.actions;
      nextStepData['subscribers'] = subcribersData;
      if (getStepsData()?.conditions?.length > 0) {
        nextStepData['conditions'] = getStepsData()?.conditions;
      }
    } else {
      nextStepData['triggers'] = props?.stepData?.triggers;
      nextStepData['selectedEvents'] = props?.stepData?.selectedEvents;
      nextStepData['devices'] = props?.stepData?.devices;
      nextStepData['subscribers'] = subcribersData;
      if (props?.stepData?.conditions?.length > 0) {
        nextStepData['conditions'] = props?.stepData?.conditions;
      }
    }
    setStepData(nextStepData);
    props.nextStep(nextStepData);
  };

  const onHandleChange = (e) => {
    if (e?.target?.value === '') {
      setUserList(searchList);
    }
    if (e != undefined) {
      setSearchInput(e?.target?.value);
      if (searchList?.length > 0) {
        const results = searchList?.filter((user) => {
          if (e.target.value === '') return searchList;
          return user?.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
        setUserList(results);
      }
    }
  };

  const onHandleChangeCheck = async (item) => {
    let position = -1;
    const list = searchList?.map((listItem, index) => {
      if (item?.accountId === listItem?.accountId) {
        if (listItem?.isChecked) {
          position = index;
          return { ...listItem, isChecked: false };
        } else {
          position = index;
          return { ...listItem, isChecked: true };
        }
      } else {
        return { ...listItem };
      }
    });
    let sheduledItems = await Promise.all(list);
    setUserList(sheduledItems);
    setSearchList(sheduledItems);
    setSearchInput('');
  };

  const fetchUser = async (signal) => {
    try {
      await axios
        .get(`partner/orgs/${custOrgData?.orgId}/accounts`, {signal: signal})
        .then((response) => {
          let resultData = response?.data?.data;
          const fillterByRole = resultData?.filter(
            (data) =>
              data?.accountId !== loggedInUserData?.accountId &&
              data?.accountStatus === AppDefaults.ACTIVE_USER &&
              data?.role !== roles.ROLE1VMS
          );

          if (
            Object.keys(getStepsData()).length !== 0 &&
            getStepsData()?.subscribers?.length > 0
          ) {
            updateList(fillterByRole);
          } else {
            setUserList(fillterByRole);
            setSearchList(fillterByRole);
          }
        });
    } catch (error) {
      // TODO : to print the error will update this later
      Utils.vmsLogger().error(error);
    }
  };

  const updateList = async (filterByRole) => {
    if (!filterByRole) return;
    const updatedList = filterByRole?.map((user) => {
      const found = getStepsData()?.subscribers.find(
        (accountId) => accountId === user?.accountId
      );
      if (found) {
        return { ...user, isChecked: true };
      } else {
        return { ...user };
      }
    });
    let userList = await Promise.all(updatedList);
    setUserList(userList);
    setSearchList(userList);
  };

  return (
    <div ref={scollToRef} className="create-notification">
      <StepIndicator {...props} />
      <div className="users-step-five">
        <div className="step-title">
          {constants.NOTIFICATION_CREATE_USER_TEXT}
        </div>
        <div className="user-selection-row">
          <div className="step-title-desc">
            {constants.NOTIFICATION_CREATE_USER_MESSAGE}
          </div>
          <div className="count-layout">
            <div className="count-text">{subscribers?.length - 1}</div>
            <div className="selected-text">
              {constants.NOTIFICATION_CREATE_NOTIFICATION_SELECTED}
            </div>
          </div>
        </div>
        <div className="serach-box">
          <AiOutlineSearch
            size={25}
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_40'
            )}
          />
          <div className="textbox">
            <input
              value={searchInput}
              onChange={(e) => onHandleChange(e)}
              style={{ border: 'none', width: '100%' }}
              className={`form-control require shadow-none ${
                searchInput ? 'has-value' : ''
              }`}
              placeholder={constants.TOP_HEADER_SEARCH_NAV_TITLE}
              autoComplete="off"
              beforeinputicon=""
              afterinputicon=""
            />
          </div>
        </div>
        {userList &&
          userList?.length > 0 &&
          userList?.map((data, index) => {
            return (
              <div key={data?.accountId} className="user-conatiner">
                <div className="event-title">
                  {' '}
                  {data?.name} - {data?.email}
                </div>
                <input
                  checked={data?.isChecked}
                  name={data?.name}
                  className="input-check-events"
                  type="checkbox"
                  onChange={(e) => onHandleChangeCheck(data)}
                />
              </div>
            );
          })}
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={subscribers?.length < 2}
          onClick={() => moveToNextStep(false)}
        >
          {constants.DEVICES_CONTINUE_TEXT}
        </PrimaryButton>
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor="#fff"
          height="44px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          type="submit"
          onClick={() => moveToNextStep(true)}
        >
          {constants.NOTIFICATION_CREATE_SKIP_BTN}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Five;
